import { colors } from '@material-ui/core'

export const styles = theme => ({
  container: {
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1)
  },
  list: {},
  item: {
    // display: 'flex',
    // paddingTop: 0,
    // paddingBottom: 0
  },
  itemImage: {
    height: 48,
    width: 48,
    borderRadius: 24
  },
  itemText: {},
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: 'transparent'
  }
})
