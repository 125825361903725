export const styles = theme => ({
  button: {
    textTransform: 'none',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: '#e6e6e6'
    },
    '&.MuiButton-containedPrimary': {
      backgroundColor: '#e6e6e6',

      '& .MuiBox-root': {
        color: '#555555'
      }
    },
    '&.MuiButton-outlinedPrimary&:hover': {
      borderColor: '#dce0e6 !important'
    }
  },
  buttonsGrid: {
    display: 'grid',
    gridTemplateColumns: '49% 49%',
    gridGap: '5px 20px',
    gridAutoRows: '1fr'
  },
  buttonLabel: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  checkbox: {
    transform: 'scale(1.2)'
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '49%'
  },
  colWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
    // flexGrow: 1
  },

  gap: {
    width: '48px'
  },

  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0, 0, 1, 0)
  },

  label: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: "Poppins, 'Helvetica Neue', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#555555'
  },

  modalContent: {
    padding: theme.spacing(0, 3, 3),
    paddingLeft: theme.spacing(3) + 19
  },
  modalTitle: {
    //
  },
  modalText: {
    display: 'list-item',
    listStyle: 'disc'
  },

  navButtons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(2)
  },
  navButton: {
    margin: theme.spacing(1)
  },

  outlinedPrimary: {
    color: 'black'
  },

  row: {
    display: 'flex',
    width: '100%'
  },

  serviceCard: {
    background: '#ffffff',
    border: '1px solid #dce0e6',
    boxShadow: '0px 2px 2px 0px rgb(36 42 48 / 15%)',
    transition: 'all 0.2s ease-in-out',
    marginBottom: theme.spacing(2),
    maxWidth: 500
  },

  title: {
    width: '100%',
    textAlign: 'center'
  },

  valetTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily: "Poppins, 'Helvetica Neue', sans-serif",
    fontSize: '15px',
    fontWeight: '400',
    color: '#333333',
    '& .MuiSvgIcon-root': {
      margin: '6px 9px 8px -4px'
    },
    marginLeft: 3
  },
  valetTitleServiceName: {
    textAlign: 'left'
  },
  valetTitleServicePrice: {
    marginLeft: 8
  },
  valetSubTitle: {
    fontSize: '11px',
    fontWeight: '600'
  },
  valetDescription: {
    fontFamily: "Poppins, 'Helvetica Neue', sans-serif",
    fontSize: '12px',
    fontWeight: '400',
    textAlign: 'left',
    color: '#555555'
  },

  wrapper: {
    background: '#f9fafb',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    padding: '10px',
    '@media (min-width: 960px)': {
      padding: '20px',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  }
})
