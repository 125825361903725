import { colors } from '@material-ui/core'

export const styles = theme => ({
  button: {
    display: 'inline-flex',
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(1)
  },
  menuIcon: {
    color: '#6c6c6c'
  },
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(9)
  },
  loginItem: {
    fontFamily: "Poppins, 'Helvetica Neue', sans-serif",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
    color: '#2B2B2B',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    transition: 'text-shadow 0.3s',
    padding: '10px 20px 10px 20px',
    border: '1px solid #E4E4E4',
    borderRadius: '5px 0 0 5px',
    transitionDuration: '0.5s',
    transitionProperty: 'color, background-color, border',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#37B45A',
      textShadow: 'none',
      border: '1px solid #37B45A'
    },
    '&:last-child': {
      color: '#fff',
      border: '1px solid #14a0d1',
      borderLeft: 'none',
      borderRadius: '0 5px 5px 0',
      backgroundColor: '#14a0d1',
      '&:hover': {
        backgroundColor: '#37B45A',
        textShadow: 'none',
        border: '1px solid #37B45A',
        borderLeft: 'none'
      }
    }
  }
})
