import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { BookWash } from '../../components/book-wash'

import { ServicesBricks } from './services-bricks'
import { ServicesPlans } from './services-plans'
import { BookValet } from './book-valet'
import { ServicesExtra } from './services-extra'

export const ServicesPage = React.memo(() => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="home">
      <Helmet title="Services &amp; Pricing" />

      <ServicesBricks />

      <ServicesPlans />

      <BookValet />

      <ServicesExtra />

      <BookWash services />
    </div>
  )
})
