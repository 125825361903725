import React, { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { debounce } from 'lodash'

import { image } from '../../lib/image'

import { PHONE_NUMBER } from '../../store/constants/contacts'
import { actions, AnalyticsService, categories } from '../../services/analytics'

export const BookWash = () => {
  const services = false
  const history = useHistory()

  const isServicePage = Boolean(useRouteMatch('/services'))

  const debounced = debounce(() => {
    const bookStepsSection = document.getElementById('book-steps-section')
    bookStepsSection.scrollIntoView({ behavior: 'smooth' })
  }, 100)

  const handleBookClick = useCallback(
    e => {
      e.preventDefault()

      AnalyticsService.trackEvent(isServicePage ? categories.services : categories.landing, actions.bookNow)

      if (isServicePage) history.push('/booking')

      debounced()
    },
    [debounced, history, isServicePage]
  )

  const handleChatClick = useCallback(e => {
    e.preventDefault()
    window?.Intercom('show')
  }, [])

  return (
    <section className="book-wash">
      <h1 className="book-wash-title">{services ? 'Book your car wash' : 'What are you waiting for?'}</h1>
      <div className="book-wash-content">
        <div className="book-wash-card">
          <img className="book-wash-card-img" src={image('./book_wash_1.svg')} alt="service" style={{ position: 'relative', top: -10, left: 6 }} />
          <h2 className="book-wash-card-title" style={{ position: 'relative', top: '-13px' }}>
            Book A Service
          </h2>
          <div onClick={handleBookClick} className="book-wash-card-text-link">
            <p className="book-wash-card-text" style={{ position: 'relative', top: -13 }}>
              Book an order <a>online</a> now
            </p>
          </div>
        </div>
        <div className="book-wash-card">
          <img className="book-wash-card-img" src={image('./book_wash_2.svg')} alt="service" />
          <h2 className="book-wash-card-title">Online Chat</h2>
          <p className="book-wash-card-text">
            <a onClick={handleChatClick}>Chat with our customer care</a> team right from this page
          </p>
        </div>
        <div className="book-wash-card">
          <img className="book-wash-card-img" src={image('./book_wash_3.svg')} alt="service" />
          <h2 className="book-wash-card-title">Call Us</h2>
          <p className="book-wash-card-text">
            Call{' '}
            <a className="book-wash-card-text-link" href={`tel:${PHONE_NUMBER}`}>
              {PHONE_NUMBER}
            </a>{' '}
            to speak with our customer care team
          </p>
        </div>
      </div>
      <a className="book-wash-link" onClick={handleBookClick}>
        book your wash now
      </a>
    </section>
  )
}
