import React, { useContext, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, List, ListItem, Button } from '@material-ui/core'
import {
  AccountCircle,
  PersonRounded,
  PeopleRounded,
  ExitToAppRounded,
  LockOpenRounded,
  LockRounded,
  DialpadRounded,
  LocalCarWashRounded
} from '@material-ui/icons'

import { context, useSelectors, userSignOut } from '../../../../store'
import { CustomRouterLink } from '../../../../components/custom-router-link'
import { styles } from './styles'

export const UserMenu = React.memo(() => {
  const classes = makeStyles(styles)()
  const { dispatch } = useContext(context)
  const { isUserSignedIn, userName } = useSelectors()

  const handleSignOut = useCallback(() => dispatch(userSignOut()), [dispatch])

  const renderMenuItem = useCallback(
    (name, icon, href, onClick) => (
      <ListItem className={classes.item} disableGutters>
        <Button className={classes.button} component={CustomRouterLink} to={href} onClick={onClick}>
          <div className={classes.icon}>{icon}</div>
          {name}
        </Button>
      </ListItem>
    ),
    [classes]
  )

  return (
    <Paper className={classes.container}>
      <List className={classes.list}>
        {isUserSignedIn && renderMenuItem(userName, <AccountCircle />, '/user')}
        {isUserSignedIn && renderMenuItem('Book a wash', <LocalCarWashRounded />, '/')}
        {isUserSignedIn && renderMenuItem('Enter promo code', <DialpadRounded />, '/user/promo_code')}
        {isUserSignedIn && renderMenuItem('Change password', <LockRounded />, '/user/reset_password')}
        {isUserSignedIn && renderMenuItem('Sign out', <ExitToAppRounded />, '/', handleSignOut)}
        {!isUserSignedIn && renderMenuItem('Sign in', <PersonRounded />, '/user/sign_in')}
        {!isUserSignedIn && renderMenuItem('Sign up', <PeopleRounded />, '/user/sign_up')}
        {!isUserSignedIn && renderMenuItem('Forgot password', <LockOpenRounded />, '/user/restore_password')}
      </List>
    </Paper>
  )
})
