import React, { useContext, useCallback } from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withScriptjs } from 'react-google-maps'
import { compose, withProps } from 'recompose'

import { image } from '../../../../lib/image'
import { context, useSelectors, setAddress, setGeoLocation, setIsLocationValid } from '../../../../store'
import { settings } from '../../../../store/constants'

import { Map } from './map'

import { styles } from './styles'

const LocationFormComponent = React.memo(() => {
  const classes = makeStyles(styles)()
  // const { dispatch } = useContext(context)
  const { geoLocation, zoom } = useSelectors()

  // const dragHandler = useCallback(
  //   ({ lat, lng }) => {
  //     const latLng = { lat: lat(), lng: lng() }
  //     dispatch(setGeoLocation(latLng))

  //     const geocoder = new window.google.maps.Geocoder()
  //     geocoder.geocode({ location: latLng }, (results, status) => {
  //       if (status !== window.google.maps.GeocoderStatus.OK) return status

  //       const { formatted_address } = results[0]

  //       if (formatted_address) {
  //         dispatch(setAddress(formatted_address))
  //       }
  //     })
  //   },
  //   [dispatch]
  // )

  return (
    <div className={classes.container}>
      <div className={classes.locationMapContainer}>
        <Map
          center={geoLocation}
          zoom={zoom}
          // dragHandler={dragHandler}
          containerElement={<div className={classes.containerElement} />}
          mapElement={<div className={classes.mapElement} />}
        />
        <img className={classes.marker} src={image('./marker.png')} alt="car placement marker" />
      </div>
    </div>
  )
})

const LoadingElement = React.memo(() => {
  const classes = makeStyles(styles)()

  return (
    <Box className={classes.loadingElement}>
      <Typography>Loading Google Maps...</Typography>
    </Box>
  )
})

export const LocationForm = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=${settings.googleMapsApiKey}`,
    loadingElement: <LoadingElement />
  }),
  withScriptjs
)(LocationFormComponent)
