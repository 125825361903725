// import { SET_IS_FOOTER_VISIBLE } from '../types/settings'
import { SET_MESSAGE } from '../types/settings'

// export const setIsFooterVisible = isFooterVisible => ({ type: SET_IS_FOOTER_VISIBLE, isFooterVisible })
export const setMessage = (message, messageTimeout = null, messagePosition = null, messageColor = null) => ({
  type: SET_MESSAGE,
  message,
  messageTimeout,
  messagePosition,
  messageColor
})
