import { useCallback, useMemo } from 'react'
import { browserHistory } from '../../services/history'
import { useSelectors } from './selectors/use-selectors'
import { usePromoCodesApi } from './use-promo-codes-api'

export const useSearchParams = () => {
  const { isUserSignedIn } = useSelectors()
  const { apiPromoCodeInfo, apiApplyPromoCode } = usePromoCodesApi()
  const search = useMemo(() => new URLSearchParams(browserHistory.location.search), [])

  const applyPromoCodeParam = useCallback(() => {
    const promoCode = search.get('promo_code')

    if (!promoCode) return

    if (isUserSignedIn) apiApplyPromoCode(promoCode)
    else apiPromoCodeInfo(promoCode)
  }, [search, isUserSignedIn, apiPromoCodeInfo, apiApplyPromoCode])

  return {
    search,
    applyPromoCodeParam
  }
}
