import React, { useCallback, useState } from 'react'
import { Avatar, Button, Container, TextField, Typography } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useUsersApi } from '../../../../store'

import { styles } from './styles'

const resultText = {
  success: 'Reset password instructions have been sent.\nPlease check your inbox.',
  not_found: 'User with specified email not found.',
  error: 'Network error. Please try again later.'
}

export const RestorePassword = React.memo(() => {
  const classes = makeStyles(styles)()
  const [email, setEmail] = useState('')
  const [result, setResult] = useState('')
  const { apiUserForgotPassword } = useUsersApi()

  const handleChange = useCallback(event => {
    console.log({ email: event.target.value })
    event.target.value && setEmail(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      if (!email) return

      const result = await apiUserForgotPassword(email)
      setResult(result)
    },
    [apiUserForgotPassword, email]
  )

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Restore Password
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleChange}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Restore password
        </Button>
      </form>
      {result && <Typography>{resultText[result]}</Typography>}
    </Container>
  )
})
