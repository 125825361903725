import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

import { browserHistory } from '../../../../services/history'
import { useSelectors } from '../../../../store'

import { STEPS, nextStep, prevStep, BOOKING_ROUTES, ANALYTICS_ACTIONS, ANALYTICS_STEPS } from '../steps'
import { AnalyticsService, categories } from '../../../../services/analytics'

export const NavButtons = React.memo(props => {
  const { isFormValid } = props
  const step = useRouteMatch('/booking/:step')?.params?.step || STEPS.ADDRESS
  const { isCardComplete, isProcessingStripe, isTermsAccepted, isUserSignedIn } = useSelectors()
  const isAddressStep = step === STEPS.ADDRESS
  const isCheckoutStep = step === STEPS.CHECKOUT
  const isNextStepDisabledAddress = isAddressStep && !isFormValid
  const isNextStepDisabledCheckout = isCheckoutStep && (!isTermsAccepted || !isCardComplete || isProcessingStripe)
  const isNextStepDisabled = isNextStepDisabledAddress || isNextStepDisabledCheckout
  const prev = prevStep[step]
  const next = nextStep[step] === STEPS.SIGN_IN && isUserSignedIn ? STEPS.CHECKOUT : nextStep[step]

  const handlePrev = e => {
    e.preventDefault()
    if (isAddressStep) return

    AnalyticsService.trackEvent(categories.booking, ANALYTICS_ACTIONS[prev])
    browserHistory.goBack() // browserHistory.push(BOOKING_ROUTES[prev])
  }

  const handleNext = () => {
    if (isNextStepDisabled) return

    AnalyticsService.trackEvent(categories.booking, ANALYTICS_ACTIONS[next])
    AnalyticsService.trackCheckoutStep(ANALYTICS_STEPS[step])
    nextStep[step] === STEPS.SIGN_IN && isUserSignedIn && AnalyticsService.trackCheckoutStep(ANALYTICS_STEPS[STEPS.SIGN_IN])
  }

  return (
    <div className="book-steps-navigation">
      {!isAddressStep && (
        <Button className="book-steps-navigation-prev" to={BOOKING_ROUTES[prev]} onClick={handlePrev}>
          Previous
        </Button>
      )}
      {![STEPS.SIGN_IN].includes(step) && (
        <Button type="submit" className={clsx('book-steps-navigation-next', isNextStepDisabled && 'disabled')} to={BOOKING_ROUTES[next]} onClick={handleNext}>
          {isCheckoutStep ? 'Place order' : 'Next'}
          {isProcessingStripe && <CircularProgress size={24} className="book-steps-navigation-next-progress" />}
        </Button>
      )}
    </div>
  )
})
