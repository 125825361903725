import { colors } from '@material-ui/core'

export const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  list: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    display: 'inline-flex',
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  userName: {
    color: colors.blueGrey[800],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.875rem'
  }
})
