export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  box: {
    padding: '0 2px',
    '@media (min-width: 960px)': {
      minWidth: '480px'
    }
  },
  label: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bolder',
    marginBottom: theme.spacing(2)
  },
  button: {
    textTransform: 'none',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
    borderColor: '#37b45a',
    color: '#37b45a'
  },
  buttonPressed: {
    '&:hover': {
      backgroundColor: '#37b45a'
    },
    backgroundColor: '#37b45a',
    color: '#fff'
  },
  confirm: {
    padding: '0px 20px 10px 0px'
  },
  text: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 2
  }
})
