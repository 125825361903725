import { useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

import { browserHistory } from '../../services/history'
import { AnalyticsService, categories, actions as analyticsActions } from '../../services/analytics'

import { context } from '../context'
import { actions$ } from '../middleware'
import * as TYPES from '../types'
import * as actions from '../actions'

import { useOrdersApi } from './use-orders-api'
import { usePromoCodesApi } from './use-promo-codes-api'
import { useSearchParams } from './use-search-params'
import { useServicesApi } from './use-services-api'
import { useUsersApi } from './use-users-api'
import { useConfigApi } from './use-config-api'

const takeAllStartup = {
  userStatus: false,
  services: false,
  done: false
}

export const useSideEffects = () => {
  const { dispatch } = useContext(context)
  const bookingForm = useFormContext()
  const { apiGetConfig } = useConfigApi()
  const { apiOrderFetchAll } = useOrdersApi()
  const { applyPromoCodeParam } = useSearchParams()
  const { applyPromoCodeOnSignIn } = usePromoCodesApi()
  const { apiServicesFetchAll } = useServicesApi()
  const { apiUserStatus } = useUsersApi()
  const step = useRouteMatch('/booking/:step')?.params?.step

  useEffect(() => {
    const sideEffects = actions$.subscribe(action => {
      const { type } = action

      switch (type) {
        case TYPES.STARTUP_START:
          if (step) browserHistory.replace('/')
          apiGetConfig()
          apiUserStatus()
          apiServicesFetchAll()
          break
        case TYPES.SET_USER:
          const { user: { id: user_id, email, name, mobile: phone, created_at } = {} } = action

          typeof Intercom !== 'undefined' && Intercom('update', { user_id, email, name, created_at, phone })
          break
        case TYPES.SIGN_OUT:
          window.localStorage.removeItem('token')
          typeof Intercom !== 'undefined' && Intercom('shutdown')
          break
        case TYPES.API_USERS_SIGN_IN_SUCCESS:
        case TYPES.API_USERS_SIGN_UP_SUCCESS:
          apiOrderFetchAll()
          applyPromoCodeOnSignIn()
          if (/^\/user/.test(browserHistory.location.pathname)) browserHistory.goBack()
          break
        case TYPES.API_ORDERS_CREATE_SUCCESS:
          dispatch(actions.resetBookingForm())
          bookingForm.reset()
          browserHistory.push('/booking/finish')
          dispatch(actions.setMessage('Your order has been successfully processed', null, null, '#37b45a'))
          break
        case TYPES.API_USERS_STATUS_SUCCESS:
          apiOrderFetchAll()
          takeAllStartup.userStatus = true
          break
        case TYPES.API_USERS_STATUS_FAILURE:
          takeAllStartup.userStatus = true
          break
        case TYPES.API_SERVICES_FETCH_ALL_SUCCESS:
          takeAllStartup.services = true
          break
        case TYPES.API_SERVICES_FETCH_ALL_FAILURE:
          takeAllStartup.services = true
          break
        case TYPES.STARTUP_FINISH:
          applyPromoCodeParam()
          break
        case TYPES.SET_ADDRESS:
          AnalyticsService.trackEvent(categories.booking, analyticsActions.setlectLocation)
          break
        case TYPES.SET_DATE:
          AnalyticsService.trackEvent(categories.booking, analyticsActions.setlectDate)
          break
        case TYPES.SET_TIME_SLOT:
          AnalyticsService.trackEvent(categories.booking, analyticsActions.setlectTime)
          break
        case TYPES.API_PROMO_CODES_APPLY_SUCCESS:
          if (/^\/user\/promo_code/.test(browserHistory.location.pathname)) browserHistory.push('/booking')
          break
        case TYPES.API_USERS_SIGN_IN_FAILURE:
          dispatch(actions.setMessage('Login failure. Please check email & password', null, null, '#f44336'))
          break
        case TYPES.API_USERS_SIGN_UP_FAILURE:
          dispatch(actions.setMessage('Sign up failure. Please verify email', null, null, '#f44336'))
          break
      }

      if (!takeAllStartup.done) {
        const allDone = Object.values(takeAllStartup).filter(it => !it)

        if (allDone.length === 1) {
          takeAllStartup.done = true
          dispatch(actions.startupFinish())
        }
      }
    })

    return () => sideEffects.unsubscribe()
  }, [apiGetConfig, apiOrderFetchAll, apiServicesFetchAll, apiUserStatus, applyPromoCodeOnSignIn, applyPromoCodeParam, bookingForm, dispatch, step])

  // useEffect(() => {
  //   FB.getLoginStatus(function(response) {
  //     console.log('FB.getLoginStatus response:', response)
  //     if (response.status === 'unknown') {
  //       FB.login(
  //         function(response) {
  //           console.log(response)
  //         },
  //         { scope: 'email' }
  //       )
  //     } else if (response.status === 'not_authorized') {
  //     } else if (response.status === 'connected') {
  //       console.log('Welcome!  Fetching your information.... ')
  //       FB.api('/me', { fields: 'first_name, last_name, email' }, function(response) {
  //         console.log('Successful login for ', response)
  //       })
  //     }
  //   })
  // }, [])
}
