import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { HomePage } from './pages'
import { ServicesPage } from './pages/services'
import { FAQsPage } from './pages/faqs'
// import { UserPage } from './pages/user'
import { SignInPage } from './pages/user/sign-in'
import { SignUpPage } from './pages/user/sign-up'
import { ResetPasswordPage } from './pages/user/reset-password'
import { RestorePasswordPage } from './pages/user/restore-password'
import { PromoCodePage } from './pages/user/promo-code'
import { BookingPage } from './pages/booking'
import { CheckoutSuccess } from './pages/booking/components/checkout-success'
import { CheckoutCancel } from './pages/booking/components/checkout-cancel'
import { TermsAndConditionsPage } from './pages/terms-and-conditions'
import { PrivacyPolicyPage } from './pages/privacy-policy'
import { GiftVouchers } from './pages/gift-vouchers'

export const Routes = React.memo(() => {
  const location = useLocation()

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          <Route exact path="/services" component={ServicesPage} />
          <Route exact path="/pricing" component={ServicesPage} />
          <Route exact path="/faqs" component={FAQsPage} />
          <Route exact path="/terms_and_conditions" component={TermsAndConditionsPage} />
          <Route exact path="/privacy_policy" component={PrivacyPolicyPage} />
          <Route exact path="/checkout/success/:sessionId" component={CheckoutSuccess} />
          <Route exact path="/checkout/cancel" component={CheckoutCancel} />
          <Route path="/booking" component={BookingPage} />

          {/* <Route exact path="/user" component={UserPage} /> */}
          <Route exact path="/user/sign_in" component={SignInPage} />
          <Route exact path="/user/sign_up" component={SignUpPage} />
          <Route exact path="/user/reset_password" component={ResetPasswordPage} />
          <Route exact path="/user/restore_password" component={RestorePasswordPage} />
          <Route exact path="/user/promo_code" component={PromoCodePage} />
          <Route exact path="/giftvouchers" component={GiftVouchers} />
          <Route component={HomePage} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
})
