import React, { useRef, useState } from 'react'
import { Dialog } from '@material-ui/core'

import { settings } from '../../../store/constants'
import { actions, AnalyticsService, categories } from '../../../services/analytics'

export const PostCode = () => {
  const postcodeRef = useRef()
  const [openModal, setOpenModal] = useState(false)
  const [isAvailable, setAvailability] = useState(false)

  const checkPostcode = () => {
    AnalyticsService.trackEvent(categories.landing, actions.isAvailableInMyArea)
    const lookupUrl = `https://ws.postcoder.com/pcw`
    const encodedQuery = encodeURIComponent(postcodeRef.current.value.trim())
    if (encodedQuery.length < 3) {
      setAvailability(false)
      setOpenModal(true)

      return
    }
    const url = `${lookupUrl}/${settings.postcoderApiKey}/address/UK/${encodedQuery}?format=json&lines=2&addtags=latitude,longitude`

    fetch(url)
      .then(res => {
        return res.json()
      })
      .then(addresses => {
        console.log('Checked postcode:', addresses)
        const londonCount = addresses.reduce((acc, val) => {
          if (val.posttown && val.posttown == 'London') {
            acc++
          } else {
            acc--
          }

          return acc
        }, 0)

        if (londonCount > 0) {
          setAvailability(true)
        } else {
          setAvailability(false)
        }

        setOpenModal(true)
      })
      .catch(e => {
        console.log('checkPostcodeError: ', e)
      })
  }

  return (
    <section className="post-code">
      <h1 className="post-code-title title-full">Is GoWashMyCar available in my area?</h1>
      <h1 className="post-code-title title-short">Available in my area?</h1>
      {/* <p className='post-code-text'>
                Enter your zip code, and we’ll let you know what services are available in your area.
            </p> */}
      <form
        className="post-code-form"
        onSubmit={e => {
          e.preventDefault()
        }}>
        <input ref={postcodeRef} className="post-code-input" type="text" placeholder="Enter postcode" />
        <a className="post-code-link" onClick={checkPostcode}>
          Check it
        </a>
      </form>
      <Dialog onClose={() => setOpenModal(false)} open={openModal}>
        <div className="post-code-book">
          <a
            className="post-code-link"
            onClick={() => {
              setOpenModal(false)
              if (isAvailable) {
                let bookStepsSection = document.getElementById('book-steps-section')
                bookStepsSection.scrollIntoView({ behavior: 'smooth' })
              }
            }}
            color="primary">
            {isAvailable ? 'Good to go!' : 'Sorry, not yet'}
          </a>
        </div>
      </Dialog>
    </section>
  )
}
