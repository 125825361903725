import React from 'react'
import { Helmet } from 'react-helmet'
import { Paper } from '@material-ui/core'

import { RestorePassword } from '../components/restore-password'

export const RestorePasswordPage = React.memo(() => {
  return (
    <Paper>
      <Helmet title="Restore Password" />
      <RestorePassword />
    </Paper>
  )
})
