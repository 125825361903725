import { createMuiTheme } from '@material-ui/core/styles'
// import { green, grey, red } from '@material-ui/core/colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#14a0d1'
      main: '#37b45a',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: '#fff'
    },
    secondary: {
      main: '#14a0d1',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: "Poppins, 'Helvetica Neue', sans-serif"
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff'
        }
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#fff'
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        '@media (min-width: 600px)': {
          paddingLeft: 0,
          paddingRight: 0
        },
        '@media (min-width: 960px)': {
          paddingLeft: 0,
          paddingRight: 0
        },
        '@media (min-width: 1280px)': {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },
    MuiStepIcon: {
      root: {
        color: '#e8ebef',
        '&$active': {
          // color: '#70e788'
          color: '#37b45a'
        },
        '&$completed': {
          color: '#37b45a'
        }
      },
      active: {},
      completed: {}
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#37b45a',
        '&$checked': {
          color: '#37b45a'
        }
      },
      colorSecondary: {
        color: '#37b45a',
        '&$checked': {
          color: '#37b45a'
        }
      }
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          margin: '0 0 16 0'
        }
      }
    }
  }
})
