import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import { Controller } from 'react-hook-form'
import { debounce } from 'lodash'

import { settings } from '../../store/constants'
import { context, setGeoLocation, setMapZoom } from '../../store'

const findUrl = 'https://ws.postcoder.com/pcw/autocomplete/find'
const retrieveUrl = `https://ws.postcoder.com/pcw/autocomplete/retrieve`

const cache = {}

export const BookAddress = React.memo(props => {
  const { form, name, label, placeholder, required = false, updateMap, onFocus, onBlur, onKeyDown } = props
  const { dispatch } = useContext(context)
  const suggestionsRef = useRef()
  const query = useRef('')
  const [suggestions, setSuggestions] = useState([])

  const handleChange = value => {
    const changedAddress = value.trim()

    if (changedAddress.length < 3) return

    query.current = changedAddress
    const url = `${findUrl}?query=${encodeURIComponent(query.current)}&pathfilter=UK@STATE&country=UK&apikey=${settings.postcoderApiKey}`

    if (cache[url]) {
      setSuggestions(cache[url])
    } else {
      fetch(url)
        .then(res => res.json())
        .then(result => {
          cache[url] = result
          setSuggestions(cache[url])
        })
        .catch(error => {
          console.log({ error })
          setSuggestions([])
        })
    }
  }

  const debounceFindAddress = useCallback(debounce(handleChange, 500), [])

  const expandAddresses = ({ id, type }) => {
    if (type === 'ADD') {
      // found
      const url = `${retrieveUrl}?id=${id}&query=${encodeURIComponent(query.current)}&apikey=${
        settings.postcoderApiKey
      }&country=UK&lines=2&addtags=latitude,longitude`

      fetch(url)
        .then(res => res.json())
        .then((result = []) => {
          const [address] = result
          const { summaryline, latitude, longitude } = address || {}
          summaryline && form.setValue(name, summaryline)
          updateMap && latitude && longitude && dispatch(setGeoLocation({ lat: +latitude, lng: +longitude }))
          updateMap && latitude && longitude && dispatch(setMapZoom(16))
          setSuggestions([])
        })
        .catch(error => {
          console.log({ error })
          setSuggestions([])
        })
    } else {
      // search more
      const url = `${findUrl}?query=${encodeURIComponent(query.current)}&pathfilter=${encodeURIComponent(id)}&apikey=${settings.postcoderApiKey}&country=UK`

      if (cache[url]) {
        setSuggestions(cache[url])
      } else {
        fetch(url)
          .then(res => res.json())
          .then(result => {
            cache[url] = result
            setSuggestions(cache[url])
          })
          .catch(error => {
            console.log({ error })
            setSuggestions([])
          })
      }
    }
  }

  useEffect(() => {
    const listener = event => {
      if (!suggestionsRef.current.contains(event.target)) {
        setSuggestions([])
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [])

  return (
    <>
      <Controller
        name={name}
        control={form.control}
        rules={{ required: required && `${label.replace(' *', '')} is required` }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={label}
            placeholder={placeholder}
            error={!!error}
            helperText={error ? error.message : null}
            onChange={ev => {
              debounceFindAddress(ev.target.value)

              return field.onChange(ev)
            }}
            InputProps={{
              autoComplete: 'off',
              autoCapitalize: 'off',
              autoCorrect: 'off',
              spellCheck: 'false',
              type: 'search',
              endAdornment: (
                <InputAdornment position="end">
                  <SearchRounded className="book-now-control-icon" />
                </InputAdornment>
              ),
              disableUnderline: true,
              onFocus,
              onBlur,
              onKeyDown
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
      />
      <div ref={suggestionsRef} className="book-now-form-suggestions">
        <ul className="book-now-suggestions-items">
          {suggestions.map(({ id, type, locationsummary, summaryline, count }) => {
            const summaryParts = summaryline.split(',')
            const summaryFirstPart = summaryParts[0]
            const summaryRest = summaryParts.slice(1).join(',')

            return (
              <li key={id} className="book-now-suggestions-item" onClick={() => expandAddresses({ id, type })}>
                <b>{summaryFirstPart}, </b> {summaryRest} &nbsp; {locationsummary} &nbsp; <small>count: {count}</small>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
})
