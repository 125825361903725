import {
  SET_PROMO_CODE,
  API_PROMO_CODES_APPLY_START,
  API_PROMO_CODES_APPLY_SUCCESS,
  API_PROMO_CODES_APPLY_FAILURE,
  API_PROMO_CODES_INFO_START,
  API_PROMO_CODES_INFO_SUCCESS,
  API_PROMO_CODES_INFO_FAILURE
} from '../types/promocodes'

export const setPromoCode = promoCode => ({ type: SET_PROMO_CODE, promoCode })
export const apiPromoCodeApplyStart = () => ({ type: API_PROMO_CODES_APPLY_START })
export const apiPromoCodeApplySuccess = credit => ({ type: API_PROMO_CODES_APPLY_SUCCESS, credit })
export const apiPromoCodeApplyFailure = error => ({ type: API_PROMO_CODES_APPLY_FAILURE, error })
export const apiPromoCodeInfoStart = () => ({ type: API_PROMO_CODES_INFO_START })
export const apiPromoCodeInfoSuccess = promoCodeInfo => ({ type: API_PROMO_CODES_INFO_SUCCESS, promoCodeInfo })
export const apiPromoCodeInfoFailure = error => ({ type: API_PROMO_CODES_INFO_FAILURE, error })
