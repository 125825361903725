export const transformOrderKeys = orders =>
  orders.map(order => {
    const {
      id,
      date,
      time_bucket: timeBucket,
      address,
      home_address: homeAddress,
      car_registration: carRegistration,
      car_model: carModel,
      car_color: carColor,
      lat,
      lng,
      image,
      price,
      status
    } = order || {}

    return {
      id,
      date,
      timeBucket,
      address,
      homeAddress,
      carRegistration,
      carModel,
      carColor,
      lat,
      lng,
      image,
      price,
      status
    }
  })
