import React, { useState, useCallback, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { FacebookLoginButton } from '../facebook-login-button'
import { GoogleLoginButton } from '../google-login-button'
import { image } from '../../../../lib/image'

import { useUsersApi } from '../../../../store'

import { styles } from './styles'

const SignUpOverflow = React.memo(props => {
  const classes = makeStyles(styles)()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')
  const { apiUserSignUp } = useUsersApi()

  const handleFirstNameChange = useCallback(event => setFirstName(event.target.value), [setFirstName])
  const handleLastNameChange = useCallback(event => setLastName(event.target.value), [setLastName])
  const handleEmailChange = useCallback(event => setEmail(`${event.target.value}`.toLowerCase()), [setEmail])
  const handleMobileChange = useCallback(event => setMobile(event.target.value), [setMobile])
  const handlePasswordChange = useCallback(event => setPassword(event.target.value), [setPassword])
  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      apiUserSignUp({ firstName, lastName, email, mobile, password })
    },
    [apiUserSignUp, firstName, lastName, email, mobile, password]
  )

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <div className="signup-background">
      <div className="signup-container">
        <div className="signup-content">
          <NavLink to="/">
            <img width="180px" height="41px" src={image('./logo.png')} alt="Gowashmycar logo" />
          </NavLink>

          <h2 className="signup-title">A car wash. Delivered to your door</h2>

          <form noValidate className="signup-form" onSubmit={handleSubmit}>
            <GoogleLoginButton title="Continue with Google" />
            <FacebookLoginButton title="Continue with Facebook" />

            <div className="signup-form-horizontal-row">or</div>

            <div className="signup-form-names">
              <input className="signup-form-firstname" id="fName" name="fname" autoFocus placeholder="First Name" onChange={handleFirstNameChange} />
              <input className="signup-form-lastname" id="lName" name="lName" placeholder="Last Name" onChange={handleLastNameChange} />
            </div>
            <input className="signup-form-phone" id="phone" name="phone" autoComplete="tel" placeholder="Mobile phone" onChange={handleMobileChange} />
            <input className="signup-form-email" id="email" name="email" autoComplete="email" placeholder="Email address" onChange={handleEmailChange} />
            <input
              className="signup-form-password"
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              onChange={handlePasswordChange}
            />
            <input className="signup-form-submit" type="submit" value="Continue" />
          </form>

          <div className="signup-extra">
            <input name="allowExtraEmails" id="allowExtraEmails" type="checkbox" value="true" />
            <label htmlFor="allowExtraEmails">I want to receive inspiration, marketing promotions and updates via email.</label>
          </div>

          <p className="signup-form-question">Already have an account?</p>
          <Link to="/user/sign_in" className="signup-form-create">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  )
})

export default SignUpOverflow
