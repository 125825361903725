import { servicesAdapter } from '../adapters'

const apiServices = [
  {
    group: 'base',
    id: 182,
    name: 'Mini Valet',
    description:
      'Exterior wash. Wheels cleaned \u0026 tyres dressed. Door shuts, door cards, centre console \u0026 dash wiped. Seats, carpet, mats \u0026 boot light vacuumed.',
    customer_price: '£49',
    customer_price_cents: 4900,
    gwmc_price: '22.00',
    includes: [],
    excludes: [181, 183, 171, 172, 170]
  },
  {
    group: 'base',
    id: 181,
    name: 'Exterior Wash \u0026 Wax',
    description: 'Exterior Wash. Wheels cleaned \u0026 tyres dressed. Basic body wax.',
    customer_price: '£49',
    customer_price_cents: 4900,
    gwmc_price: '23.00',
    includes: [185],
    excludes: [182, 183, 171, 172, 170]
  },
  {
    group: 'base',
    id: 183,
    name: 'Mini Valet PLUS',
    description:
      'Exterior wash. Wheels cleaned \u0026 tyres dressed. Basic body wax. Door shuts, door cards, centre console \u0026 dash wiped. Seats, carpet, mats \u0026 boot intense vacuumed. Screenwash refill, oil \u0026 fluid check. Boot \u0026 bonnet gutters cleaned. Interior windows cleaned.',
    customer_price: '£89',
    customer_price_cents: 8900,
    gwmc_price: '40.00',
    includes: [185, 184, 164, 178],
    excludes: [182, 181, 171, 172, 170]
  },
  {
    group: 'base',
    id: 170,
    name: 'Interior Only Valet',
    description:
      'Seats, carpet, mats \u0026 boot vacuumed. Interior windows cleaned. Seats shampooed or leather cleaned \u0026 conditioned. Carpet \u0026 mats shampooed. Spare wheel area vacuumed.',
    customer_price: '£115',
    customer_price_cents: 11500,
    gwmc_price: '60.00',
    includes: [178],
    excludes: [182, 181, 183, 171, 172]
  },
  {
    group: 'base',
    id: 171,
    name: 'Dog Owners Valet',
    description:
      'Exterior wash. Wheels cleaned \u0026 tyres dressed. Door shuts, door cards, centre console \u0026 dash wiped. Seats, carpet, mats \u0026 boot vacuumed. Interior windows cleaned. Seats shampooed or leather cleaned \u0026 conditioned. Carpet \u0026 mats shampooed. Pet hair removal.',
    customer_price: '£215',
    customer_price_cents: 21500,
    gwmc_price: '107.00',
    includes: [184, 164, 178, 187],
    excludes: [182, 181, 183, 172, 170]
  },
  {
    group: 'base',
    id: 172,
    name: 'Full Valet',
    description:
      'Exterior wash. Wheels cleaned \u0026 tyres dressed. Door shuts, door cards, centre console \u0026 dash wiped. Seats, carpet, mats \u0026 boot vacuumed. Screenwash refill, oil \u0026 fluid check. Boot \u0026 bonnet gutters cleaned. Interior windows cleaned. Seats shampooed or leather cleaned \u0026 conditioned. Carpet \u0026 mats shampooed. Spare wheel area vacuumed. Engine bay cleaned. HD Carnauba Wax Coating or Protective Paint Sealant applied (choice).',
    customer_price: '£199',
    customer_price_cents: 19900,
    gwmc_price: '99.00',
    includes: [184, 164, 178, 165, 156],
    excludes: [182, 181, 183, 171, 170]
  },
  {
    group: 'popular',
    id: 184,
    name: 'Screenwash Refill, Oil \u0026 Fluid Check',
    description: "Don't run out of windscreen washer fluid. Let us fill it up for you",
    customer_price: '£7',
    customer_price_cents: 700,
    gwmc_price: '4.20',
    includes: [],
    excludes: []
  },
  {
    group: 'popular',
    id: 178,
    name: 'Interior Windows cleaned',
    description: 'Create a crystal clear vision',
    customer_price: '£15',
    customer_price_cents: 1500,
    gwmc_price: '9.50',
    includes: [],
    excludes: []
  },
  {
    group: 'popular',
    id: 185,
    name: 'Basic Body Wax',
    description: 'Commonly used between washes as a top-up to your existing protection and leaves a deep shine.',
    customer_price: '£20',
    customer_price_cents: 2000,
    gwmc_price: '13.50',
    includes: [],
    excludes: [156]
  },
  {
    group: 'popular',
    id: 188,
    name: 'Tree Sap \u0026 Bird Droppings Removal',
    description:
      'Tree sap residue and bird droppings can damage your paintwork if not removed quickly. We apply a specialised chemical to removed hardened residue',
    customer_price: '£30',
    customer_price_cents: 3000,
    gwmc_price: '16.00',
    includes: [],
    excludes: []
  },
  {
    group: 'popular',
    id: 154,
    name: 'Sanitisation',
    description:
      'Our sanitisation service uses O3 gas, which destroys airborne and surface contaminants such as bacteria, viruses, and odors. The sterilisation process breaks down the molecular structure of these contaminants, neutralising and rendering them harmless and leaving a fresh, clean smell',
    customer_price: '£40',
    customer_price_cents: 4000,
    gwmc_price: '20.00',
    includes: [],
    excludes: []
  },
  {
    group: 'popular',
    id: 155,
    name: 'Alloy Wheels Sealant - 4 wheels',
    description:
      'Helps protect wheels from corrosion, brake dust and grime, keeping them clean for longer. They remain clean for longer and makes future cleaning easier.',
    customer_price: '£40',
    customer_price_cents: 4000,
    gwmc_price: '16.00',
    includes: [],
    excludes: []
  },
  {
    group: 'popular',
    id: 156,
    name: 'HD Carnauba Wax Coating',
    description:
      'High Definition Wax contains a complex blend of polymers and microcrystalline waxes, lending resistance to environmental contaminants including salt, detergents, UV light, acid rain and industrial fallout. Recommended re-application every three months.',
    customer_price: '£50',
    customer_price_cents: 5000,
    gwmc_price: '22.00',
    includes: [],
    excludes: [185]
  },
  {
    group: 'popular',
    id: 157,
    name: 'Soft Roof Deep Clean \u0026 Reproofing',
    description:
      'Deep extraction-clean of the soft-roof to remove any green growth, after which reproof protection is applied to protect the hood from environmental damage. Recommended re-proofing Spring \u0026 Autumn.',
    customer_price: '£170',
    customer_price_cents: 17000,
    gwmc_price: '70.00',
    includes: [],
    excludes: []
  },
  {
    group: 'popular',
    id: 156,
    name: 'Tyre Safety Check',
    description:
      'Includes correct inflation, tread wear check and outer wall damage.',
    customer_price: '£25',
    customer_price_cents: 2500,
    gwmc_price: '10.00',
    includes: [],
    excludes: []
  },
  {
    group: 'popular',
    id: 156,
    name: 'Windscreen exterior deep clean and glass sealant',
    description:
      'Noticeable improvement in wiper efficiency and visibility in the wet.',
    customer_price: '£35',
    customer_price_cents: 3500,
    gwmc_price: '10.00',
    includes: [],
    excludes: []
  },
  {
    group: 'extras',
    id: 151,
    name: 'Deep clean 1 seat',
    description: 'Fabric upholstery shampoo or leather clean \u0026 condition',
    customer_price: '£16',
    customer_price_cents: 1600,
    gwmc_price: '6.00',
    includes: [],
    excludes: [162]
  },
  {
    group: 'extras',
    id: 162,
    name: 'Deep clean 2 seats',
    description: 'Fabric upholstery shampoo or leather clean \u0026 condition of two seats.',
    customer_price: '£40',
    customer_price_cents: 4000,
    gwmc_price: '16.00',
    includes: [],
    excludes: [151]
  },
  {
    group: 'extras',
    id: 1251,
    name: 'Deep Clean 1 Child Car Seat',
    description: 'Thorough vacuum including frame. Fabric deep cleaned and sanitized.',
    customer_price: '£35',
    customer_price_cents: 3500,
    gwmc_price: '8.75',
    includes: [],
    excludes: [1262]
  },
  {
    group: 'extras',
    id: 1262,
    name: 'Deep Clean 2 Child Car Seats',
    description: 'Thorough vacuum including frame. Fabric deep cleaned and sanitized.',
    customer_price: '£70',
    customer_price_cents: 7000,
    gwmc_price: '17.5',
    includes: [],
    excludes: [1251]
  },

  {
    group: 'extras',
    id: 1351,
    name: 'Single Push Chair Deep Cleaning',
    description: 'Frame, wheels and seat fabric deep cleaned and sanitized. Axles and wheels lubricated.',
    customer_price: '£65',
    customer_price_cents: 6500,
    gwmc_price: '16.25',
    includes: [],
    excludes: [1362]
  },
  {
    group: 'extras',
    id: 1362,
    name: 'Double Push Chair Deep Cleaning',
    description: 'Frame, wheels and seat fabric deep cleaned and sanitized. Axles and wheels lubricated.',
    customer_price: '£85',
    customer_price_cents: 8500,
    gwmc_price: '32.5',
    includes: [],
    excludes: [1351]
  },



  {
    group: 'extras',
    id: 187,
    name: 'Pet Hair Removal',
    description: 'Will we remove every hair? Absolutely not. However we will remove the overwhelming majority using a specialty brush and a vacuum cleaner.',
    customer_price: '£30',
    customer_price_cents: 3000,
    gwmc_price: '15.00',
    includes: [],
    excludes: []
  },
  {
    group: 'extras',
    id: 164,
    name: 'Boot \u0026 bonnet gutters cleaned',
    description: 'A popular touch to ehance the finish of our standard Mini Valet. Includes a free screenwash refill!',
    customer_price: '£20',
    customer_price_cents: 2000,
    gwmc_price: '8.00',
    includes: [],
    excludes: []
  },
  {
    group: 'extras',
    id: 165,
    name: 'Engine bay cleaned',
    description: "Never had an engine bay cleaned? You're missing out! Try out this extra service to enhance the overall look of your car.",
    customer_price: '£30',
    customer_price_cents: 3000,
    gwmc_price: '10.00',
    includes: [],
    excludes: []
  },
  {
    group: 'extras',
    id: 163,
    name: 'Protective Paint Sealant',
    description: 'An enhanced protection that increases the depth of shine while adding a long term protection for up to 3 months',
    customer_price: '£59',
    customer_price_cents: 5900,
    gwmc_price: '31.00',
    includes: [],
    excludes: []
  },
  {
    group: 'extras',
    id: 153,
    name: 'Odour Removal',
    description:
      'Removal of odour: smoke, pets, urine or vomit. Commonly used as part of an Interior Valet or Deep Seat clean. We use the latest 03 gas generators to destroy the odour once the contamination has been successfully removed from the offending area.',
    customer_price: '£75',
    customer_price_cents: 7500,
    gwmc_price: '27.00',
    includes: [],
    excludes: []
  },
  {
    group: 'extras',
    id: 166,
    name: 'High pressure interior steam clean',
    description:
      "Unbelievable' is the common observation our customers describe having had this service. Not only does the steam destroy bacteria and pathogens, but the high pressure nature of the steam removes dirt from difficult-to-reach areas of your car, including the air conditioning system. The use of chemicals are restricted during this process.",
    customer_price: '£75',
    customer_price_cents: 7500,
    gwmc_price: '27.00',
    includes: [],
    excludes: []
  },
  {
    group: 'extras',
    id: 166,
    name: 'Alloy wheel and tyre deep clean',
    description:
      "Finished with wheel sealant and tyre dressing.",
    customer_price: '£95',
    customer_price_cents: 9500,
    gwmc_price: '25.00',
    includes: [],
    excludes: []
  },
  {
    group: 'detailing',
    id: 158,
    name: 'Clay Bar \u0026 Machine Polish',
    description:
      "Clay is applied to remove surface contamination for a silky smooth surface. Single-stage machine polishing enhances the depth of shine while smoothing out scratches and imperfections in your car's paintwork",
    customer_price: '£225',
    customer_price_cents: 22500,
    gwmc_price: '95.00',
    includes: [],
    excludes: []
  },
  {
    group: 'detailing',
    id: 159,
    name: 'Paint Protection',
    description:
      'Protect the paintwork of your car with premium paint protection. Service includes: light interior vacuum, interior protection pack, exterior ceramic-based paint sealant',
    customer_price: '£399',
    customer_price_cents: 39900,
    gwmc_price: '149.00',
    includes: [],
    excludes: []
  },
  {
    group: 'surcharge',
    id: 186,
    name: 'Extra for large car',
    description: '5 doors or more (SUV, Minivan, Large Trucks)',
    customer_price: '£16',
    customer_price_cents: 1600,
    gwmc_price: '12.50',
    includes: [],
    excludes: []
  },
  {
    group: 'surcharge',
    id: 142,
    name: 'Congestion zone premium',
    description: 'Yes, my car is located inside the London Congestion Charge Zone.',
    customer_price: '£15',
    customer_price_cents: 1500,
    gwmc_price: '0.00',
    includes: [],
    excludes: []
  },
  {
    group: 'surcharge',
    id: 180,
    name: 'Extra for same day',
    description: 'One of our operators will call you to schedule a suitable time today',
    customer_price: '£25',
    customer_price_cents: 2500,
    gwmc_price: '14.50',
    includes: [],
    excludes: []
  }
]

const services = servicesAdapter(apiServices)

export const LARGE_CAR_SERVICE = services.find(service => service.name === 'Extra for large car')

export const SAME_DAY_SERVICE = services.find(service => service.name === 'Extra for same day')

export const CONGESTION_ZONE_SERVICE = services.find(service => service.name === 'Congestion zone premium')
