import React, { useState, useCallback, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Avatar, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { FacebookLoginButton } from '../facebook-login-button'
import { GoogleLoginButton } from '../google-login-button'
import { image } from '../../../../lib/image'

import { useUsersApi } from '../../../../store'

import { styles } from './styles'

const SignInOverflow = React.memo(() => {
  const classes = makeStyles(styles)()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { apiUserSignIn } = useUsersApi()

  const handleEmailChange = useCallback(event => setEmail(`${event.target.value}`.toLowerCase()), [setEmail])
  const handlePasswordChange = useCallback(event => setPassword(event.target.value), [setPassword])
  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      apiUserSignIn({ email, password })
    },
    [email, password, apiUserSignIn]
  )

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <div className="signin-background">
      <div className="signin-container">
        <div className="signin-content">
          <NavLink to="/">
            <img width="180px" height="41px" src={image('./logo.png')} alt="Gowashmycar logo" />
          </NavLink>

          <h2 className="signin-title">A car wash. Delivered to your door</h2>

          <form noValidate className="signin-form" onSubmit={handleSubmit}>
            <GoogleLoginButton title="Continue with Google" />
            <FacebookLoginButton title="Continue with Facebook" />

            <div className="signin-form-horizontal-row">or</div>

            <input
              className="signin-form-email"
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder="Email address"
              onChange={handleEmailChange}
            />
            <input
              className="signin-form-password"
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              onChange={handlePasswordChange}
            />

            <input className="signin-form-submit" type="submit" value="Continue" />
          </form>

          <p className="signin-form-question">{`Don't have an account?`}</p>
          <Link to="/user/sign_up" className="signin-form-create">
            Create account
          </Link>
          <p className="signin-form-question">Forgot your password?</p>
          <NavLink to="/user/restore_password" className="signin-form-create">
            <span>Restore password</span>
          </NavLink>
        </div>
      </div>
    </div>
  )
})

export default SignInOverflow
