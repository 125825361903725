import React, { useCallback, useEffect, useReducer } from 'react'
import { Router as RouterProvider } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'

import { IntercomService } from './services/intercom'
import { browserHistory } from './services/history'
import { context as Context, reducer, initialState, actionsSubject, stateSubject } from './store'
import { theme } from './theme'
import { App } from './app'
import { fields } from './store/constants'

export const AppProvider = React.memo(() => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const bookingForm = useForm({
    defaultValues: {
      [fields.address]: '',
      [fields.homeAddress]: '',
      [fields.date]: null,
      [fields.timeSlot]: '',
      [fields.carModel]: '',
      [fields.carColor]: '',
      [fields.carRegistration]: '',
      [fields.carSize]: null,
      [fields.notes]: '',
      [fields.agentTip]: false,
      [fields.congestionZone]: false
    }
  })

  const dispatchOverride = useCallback(
    (...args) => {
      dispatch(...args)
      actionsSubject.next(...args)
    },
    [dispatch]
  )

  useEffect(() => {
    IntercomService.start()

    return () => {
      IntercomService.stop()
    }
  }, [])

  useEffect(() => {
    stateSubject.next(state)
  }, [state])

  return (
    <Context.Provider value={{ state, dispatch: dispatchOverride }}>
      <MuiThemeProvider theme={theme}>
        <RouterProvider history={browserHistory}>
          <FormProvider {...bookingForm}>
            <App />
          </FormProvider>
        </RouterProvider>
      </MuiThemeProvider>
    </Context.Provider>
  )
})
