import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

import { image } from '../../lib/image'

import './style'

export const Footer = React.memo(() => {
  const isBookingFinishPage = !!useRouteMatch('/booking/finish')
  const isBookingPage = !!useRouteMatch('/booking/:step') && !isBookingFinishPage

  if (isBookingPage) return null

  const currentYear = new Date().getFullYear()
  const copyright = `© GoWashMyCar Ltd. ${currentYear}`

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <NavLink to="/">
            <img className="footer-logo-img" src={image('./logo.png')} alt="Gowashmycar logo" />
          </NavLink>
        </div>
        <div className="footer-services-container">
          <div className="footer-services-left">
            <div className="footer-services-links">
              <NavLink className="footer-services-item" to="/services">
                Services &amp; pricing
              </NavLink>
              <NavLink className="footer-services-item" to="/faqs">
                FAQs
              </NavLink>
              <NavLink className="footer-services-item" to="/giftvouchers">
                Gift Vouchers
              </NavLink>
              <a className="footer-services-item volvo" href="https://www.gowashmycar.com/volvocars">
                Volvo partnership
              </a>
            </div>
            <div className="footer-services-links">
              <a className="footer-services-item" href="https://gowashmycar.typeform.com/to/zMq9Ya">
                Become an Operator
              </a>
              <a className="footer-services-item" href="https://gowashmycar.typeform.com/to/oPLq2u">
                Corporate
              </a>
              <NavLink className="footer-services-item" to="/terms_and_conditions">
                Terms &amp; conditions
              </NavLink>
              <NavLink className="footer-services-item" to="/privacy_policy">
                Privacy
              </NavLink>
            </div>
            <div className="footer-contacts-container">
              <div className="footer-contacts-email">
                <div className="footer-contacts-email-title">Contact</div>
                <a className="footer-services-item" href="mailto: support@gowashmycar.com">
                  support@gowashmycar.com
                </a>
              </div>
              <div className="footer-contacts-social">
                <a href="https://www.instagram.com/gowashmycar/?hl=en" target="_blank" rel="noopener noreferrer">
                  <img src={image('./footer_instagram.svg')} title="Instagram" alt="Instagram" />
                </a>
                {/* <a href="https://www.facebook.com/gowashmycar?ref=hl">
                  <img src={image('./footer_twitter.svg')} title="Twitter" alt="Twitter" />
                </a> */}
                <a href="https://www.facebook.com/gowashmycar?ref=hl" target="_blank" rel="noopener noreferrer">
                  <img src={image('./footer_facebook.svg')} title="Facebook" alt="Facebook" />
                </a>
              </div>
            </div>
          </div>
          {/* <div className='footer-services-right'>
                    <h4 className='footer-locations-title'>
                        Sign up to get email offers
                    </h4>
                    <form className='footer-services-form' onSubmit={(e) => { e.preventDefault() }}>
                        <input className="footer-services-input" type="text" placeholder="Your email" />
                        <a className="footer-services-link" >
                            Subscribe
                        </a>
                    </form>
                </div> */}
        </div>
        <div className="footer-locations-content">
          <div className="footer-locations">
            <h4 className="footer-locations-title">Locations</h4>
            <p className="footer-locations-text">GoWashMyCar is now servicing the entire Greater London area. Please contact us with any questions.</p>
          </div>
          <div className="footer-cookies">
            <h4 className="footer-locations-title">Cookies</h4>
            <p className="footer-locations-text">
              We use cookies to provide the best experience to our users. If the software that you are using to view this website (your browser) is set to
              accept cookies, and you continue to use the site, we will take that to mean that you accept our use of cookies.
            </p>
          </div>
        </div>
        <div className="footer-copyright">{copyright}</div>
      </div>
      <div className="footer-apps">
        <div className="footer-apps-content">
          <img className="footer-apps-phones-img" src={image('./phones@2x.png')} alt="background" />
          <div className="footer-app-market">
            <a className="footer-app-market-item" href="https://itunes.apple.com/app/id1277616121">
              <img className="footer-apps-market-img" src={image('./app_store@2x.png')} alt="AppStore" />
            </a>
            <a className="footer-app-market-item" href="https://play.google.com/store/apps/details?id=com.gowashmycar.customer">
              <img className="footer-apps-market-img" src={image('./google_play@2x.png')} alt="PlayMarket" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
})
