export const slots = {
  today: 'Anytime Today',
  allday: 'Anytime 8am-6pm',
  // morning: 'Morning 8am-2pm',
  // afternoon: 'Afternoon 2pm-8pm',
  // slot08: '08:00-10:00',
  // slot10: '10:00-12:00',
  // slot12: '12:00-14:00',
  // slot14: '14:00-16:00',
  // slot16: '16:00-18:00',
  // slot18: '18:00-20:00',
  slot1: '8am-12pm',
  slot2: '10am-2pm',
  slot3: '2pm-6pm'
}
