import { getState, prices, selectedServices } from '../store'

export const categories = {
  booking: 'booking',
  landing: 'landing',
  services: 'services'
}

export const actions = {
  // booking
  setlectLocation: 'Select location',
  setlectDate: 'Select date',
  setlectTime: 'Select time',
  setlectBaseService: 'Select base service',
  setlectExtraService: 'Select extra service',
  desetlectExtraService: 'Deselect extra service',
  bookingStep1: 'Navigate to booking step 1',
  bookingStep2: 'Navigate to booking step 2',
  bookingStep3: 'Navigate to booking step 3',
  bookingStepLogin: 'Navigate to booking login',
  bookingStep4: 'Navigate to booking step 4',
  applyPromoCode: 'Apply promo code',
  clickPlaceOrder: 'Click Place order',
  orderPlaced: 'Order placed',
  // landing
  isAvailableInMyArea: 'Is GoWashMyCar available in my area?',
  // services
  bookNow: 'Book now'
}

const steps = {
  1: 'Fill order details',
  2: 'Select base service',
  3: 'Select extra services',
  4: 'Login',
  5: 'Fill payment details',
  6: 'Order placed'
}

export class AnalyticsService {
  static trackEvent = (category, action, label, value) => {
    const event = {
      event: 'ga-event',
      gaEventCategory: category.toString(),
      gaEventAction: action.toString(),
      ...(label && { gaEventLabel: label.toString() }),
      ...(value && { gaEventValue: value.toString() }),
      gaEventNonInteraction: category === categories.booking ? 'False' : 'True'
    }
    window.dataLayer?.push(event)
    // console.log('[GTM] ga-event', event)
  }

  static trackImpressions = step => {
    const state = getState()
    const allProducts = state.prices.ids.map(serviceId => productAdapter(state.prices.byId[serviceId]))
    const baseProducts = allProducts.filter(product => product.category === 'base')
    const extraProducts = allProducts.filter(product => ['popular', 'extras', 'detailing'].includes(product.category))
    const surchargeProducts = allProducts.filter(product => product.category === 'surcharge')
    const products = {
      1: surchargeProducts,
      2: baseProducts,
      3: extraProducts
    }

    const ecommerce = {
      currencyCode: 'GBP',
      impressions: products[step]
    }
    window.dataLayer?.push({
      ecommerce,
      event: 'ecommerce-event',
      ecommerceEventCategory: 'Enhanced Ecommerce',
      ecommerceEventAction: 'Product Impressions',
      ecommerceEventNonInteraction: 'True'
    })
    // console.log('[GTM] ecommerce', ecommerce)
  }

  static trackAddToCart = product => {
    const ecommerce = {
      currencyCode: 'GBP',
      add: {
        products: [productAdapter(product)]
      }
    }
    window.dataLayer?.push({
      ecommerce,
      event: 'ecommerce-event',
      ecommerceEventCategory: 'Enhanced Ecommerce',
      ecommerceEventAction: 'Adding a Product to a Shopping Cart',
      ecommerceEventNonInteraction: 'False'
    })
    // console.log('[GTM] ecommerce', ecommerce)
  }

  static trackRemoveFromCart = product => {
    const ecommerce = {
      currencyCode: 'GBP',
      remove: {
        products: [productAdapter(product)]
      }
    }
    window.dataLayer?.push({
      ecommerce,
      event: 'ecommerce-event',
      ecommerceEventCategory: 'Enhanced Ecommerce',
      ecommerceEventAction: 'Removing a Product from a Shopping Cart',
      ecommerceEventNonInteraction: 'False'
    })
    // console.log('[GTM] ecommerce', ecommerce)
  }

  static trackCheckoutStep = step => {
    const state = getState()
    const services = selectedServices(state).filter(service => !(step === 1 && service?.group === 'base'))
    const products = services.map(service => productAdapter(service))
    const ecommerce = {
      ...(products.length > 0 && { currencyCode: 'GBP' }),
      checkout: {
        actionField: {
          step
        },
        ...(products.length > 0 && { products })
      }
    }

    window.dataLayer.push({
      ecommerce,
      event: 'ecommerce-event',
      ecommerceEventCategory: 'Enhanced Ecommerce',
      ecommerceEventAction: steps[step] || `Checkout Step ${step}`,
      ecommerceEventNonInteraction: 'False'
    })
    // console.log('[GTM] ecommerce', ecommerce)
  }

  static trackPurchase = (data, products, coupon) => {
    const { id = 0, net_gwmc_fee = 0 } = data || {}
    const orderId = id.toString()
    const revenue = net_gwmc_fee.toString()
    const tax = (Math.round(16.6667 * net_gwmc_fee) / 100).toString()
    const ecommerce = {
      currencyCode: 'GBP',
      purchase: {
        actionField: {
          id: orderId,
          revenue,
          tax,
          ...(coupon && { coupon })
        },
        products: products.map(product => productAdapter(product))
      }
    }

    window.dataLayer.push({
      ecommerce,
      event: 'ecommerce-event',
      ecommerceEventCategory: 'Enhanced Ecommerce',
      ecommerceEventAction: 'Purchase',
      ecommerceEventNonInteraction: 'False'
    })
    // console.log('[GTM] ecommerce', ecommerce)
  }
}

const productAdapter = ({ name, id, gwmcPrice, group }) => {
  return {
    ...(name && { name }),
    ...(id && { id: id.toString() }),
    ...(gwmcPrice && { price: gwmcPrice }),
    ...(group && { category: group }),
    quantity: 1
  }
}
