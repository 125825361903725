import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Hidden } from '@material-ui/core'
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@material-ui/icons'
import clsx from 'clsx'

import { image } from '../../../lib/image'

const items = [
  { title: 'Anywhere access', description: 'Our fully equipped vans can access almost any street in London', image: './gallery-image-01.jpg' },
  {
    title: 'Best in class soft top care',
    description: 'Remove old mould and weather stains with our specialist soft top reproof',
    image: './gallery-image-02.jpg'
  },
  {
    title: 'Fully trained operatives',
    description: 'All our operators undergo rigorous training and meet the highest cleaning standards',
    image: './gallery-image-03.jpg'
  },
  {
    title: 'Protective body wax',
    description: 'Explore our range of body wax services to protect your car against all weather elements',
    image: './gallery-image-04.jpg'
  },
  { title: 'Exterior foam wash', description: 'Every exterior service gets soaked in our Eco-friendly foam wash', image: './gallery-image-05.jpg' },
  {
    title: 'Stain removal',
    description: 'All our operatives are trained in effective stain removal. No matter what the stain',
    image: './gallery-image-06.jpg'
  },
  { title: 'Jet power wash', description: 'State of the art equipment which will leave your car looking like new', image: './gallery-image-07.jpg' },
  { title: 'Alloys & tyres', description: 'We get rid of that grubby look your tyres get from road and air dirt', image: './gallery-image-08.jpg' },
  { title: 'Engine bay clean', description: 'We cover a wide range of extra services to cater for your car’s every need', image: './gallery-image-09.jpg' },
  { title: 'Micro-fibre towels', description: 'To ensure your car is never left scratched we only use the best equipment', image: './gallery-image-10.jpg' }
]

export const HappyCarsSlider = () => {
  return (
    <section className={clsx('happy-cars-slider')}>
      <div className="happy-cars-top-space"></div>
      <div className="happy-cars-slider-top-triangle">
        <svg preserveAspectRatio="none" viewBox="0 0 100 100">
          <polygon points="100,100 100,0 0,100" opacity="1"></polygon>
        </svg>
      </div>
      <Hidden smDown>
        <h1 className="happy-cars-slider-title">Join our growing list of happy customers</h1>
      </Hidden>
      <Hidden mdUp>
        <h1 className="happy-cars-slider-title">Experience GoWashMyCar</h1>
      </Hidden>
      <Slider
        {...{
          dots: true,
          arrows: true,
          infinite: true,
          centerMode: true,
          variableWidth: true,
          // autoplay: true,
          // autoplaySpeed: 5000,
          initialSlide: 4,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />
        }}>
        {items.map(item => (
          <div key={item.image} className="happy-cars-slide-wrapper">
            <div className="happy-cars-slide">
              <img className="happy-cars-slide-img" src={image(item.image)} alt="car" />
              <div className="happy-cars-slide-title">{item.title}</div>
              <div className="happy-cars-slide-description">{item.description}</div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

const PrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <KeyboardArrowLeftRounded className="happy-cars-slider-arrow" />
  </div>
)

const NextArrow = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <KeyboardArrowRightRounded className="happy-cars-slider-arrow" />
  </div>
)
