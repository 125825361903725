import { GBP } from '../../lib/gbp'

export const servicesAdapter = array =>
  array.map(item => {
    const {
      // eslint-disable-next-line prettier/prettier
      group,
      id,
      name,
      description,
      customer_price: customerPrice,
      customer_price_cents: customerPriceCents,
      gwmc_price: gwmcPrice,
      includes,
      excludes
    } = item || {}

    return {
      group,
      id,
      name,
      description,
      customerPrice,
      customerPriceCents,
      gwmcPrice,
      price: GBP(customerPriceCents / 100),
      includes,
      excludes
    }
  })
