import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import clsx from 'clsx'
import { Box, FormControlLabel, makeStyles, TextField, Typography, useMediaQuery } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

import { NavButtons } from '../nav-buttons'
import { IOSSwitch } from '../../../../components/ios-switch'
import { BookAddress } from '../../../../components/book-address'
import { BookDate } from '../../../../components/book-date'
import { TimeSlots } from '../../../../components/time-slots'
import { LocationForm } from '../location'
import {
  context,
  setAddress,
  setHomeAddress,
  setDate,
  setTimeSlot,
  setCarModel,
  setCarRegistration,
  setCarColor,
  setLargeCar,
  setNotes,
  useSelectors,
  setCongestionZone
} from '../../../../store'
import { CONGESTION_ZONE_SERVICE, fields } from '../../../../store/constants'
import { AnalyticsService } from '../../../../services/analytics'

import { styles } from './styles'
import { browserHistory } from '../../../../services/history'
import { BOOKING_ROUTES, STEPS } from '../steps'

const items = {
  address: 'address',
  date: 'date',
  timeSlot: 'timeSlot',
  homeAddress: 'home_address',
  carModel: 'carModel',
  carRegistration: 'carRegistration',
  carColor: 'carColor',
  notes: 'notes',
  none: 'none'
}

export const StepAddress = () => {
  const classes = makeStyles(styles)()
  const { dispatch } = useContext(context)
  const history = useHistory()
  const query = parse(history.location.search)
  const bookingForm = useFormContext()
  const { handleSubmit, formState } = bookingForm
  const { errors, isValid } = formState
  const values = bookingForm.getValues()

  const { prices } = useSelectors()
  const [focused, setFocused] = useState(items.none)
  const isMobile = useMediaQuery('(max-width:959px)')
  const isDesktop = useMediaQuery('(min-width:960px)')

  const onKeyDown = useCallback(
    item => event => {
      if (item === items.notes) return

      if (event.keyCode === 13) {
        const form = event.target.form
        const index = Array.prototype.indexOf.call(form, event.target)

        form.elements[index + 1]?.focus()
        event.preventDefault()
      }
    },
    []
  )

  useEffect(() => {
    prices.ids.length > 0 && setTimeout(() => AnalyticsService.trackImpressions(1), 17)
  }, [prices.ids.length])

  const submitHandler = (data, ev) => {
    ev.preventDefault()
    dispatch(setAddress(data[fields.address]))
    dispatch(setHomeAddress(data[fields.homeAddress]))
    dispatch(setDate(data[fields.date]))
    dispatch(setTimeSlot(data[fields.timeSlot]))
    dispatch(setCarModel(data[fields.carModel]))
    dispatch(setCarColor(data[fields.carColor]))
    dispatch(setCarRegistration(data[fields.carRegistration]))
    dispatch(setNotes(data[fields.notes]))
    dispatch(setLargeCar(data[fields.carSize] === 'large'))
    dispatch(setCongestionZone(data[fields.congestionZone]))

    browserHistory.push(BOOKING_ROUTES[STEPS.SERVICE])
  }

  return (
    <div>
      <form autoComplete="on" onSubmit={handleSubmit(submitHandler)}>
        <div className="book-steps-form">
          <div className="book-steps-form-left">
            <div
              className={clsx(
                'book-steps-car-location',
                focused !== items.address && !errors[fields.address] && values[fields.address] && 'valid',
                focused !== items.address && errors[fields.address] && 'invalid',
                focused === items.address && 'focused'
              )}>
              <BookAddress
                form={bookingForm}
                name={fields.address}
                label="Car location *"
                placeholder="Enter address or postcode"
                required
                updateMap
                onFocus={() => {
                  setFocused(items.address)
                }}
                onBlur={() => {
                  setFocused(items.none)
                }}
                onKeyDown={onKeyDown(items.address)}
              />
            </div>
            {isMobile && !query?.showmap ? null : (
              <div>
                <LocationForm />
              </div>
            )}

            <Box className={classes.congestion}>
              <FormControlLabel
                name={fields.congestionZone}
                classes={{
                  root: classes.congestionRoot
                }}
                label={
                  <Typography className={classes.congestionLabel}>
                    Yes, my car is located inside the London Congestion Charge Zone{isDesktop ? `(extra ${CONGESTION_ZONE_SERVICE?.customerPrice})` : ''}
                  </Typography>
                }
                control={
                  <Controller
                    control={bookingForm.control}
                    name={fields.congestionZone}
                    render={({ field: { value, ...field } }) => <IOSSwitch {...field} checked={!!value} />}
                  />
                }
              />
            </Box>

            <div className="book-steps-date-time">
              <div
                className={clsx(
                  'book-steps-date',
                  focused !== items.date && !errors[fields.date] && values[fields.date] && 'valid',
                  focused !== items.date && errors[fields.date] && 'invalid',
                  focused === items.date && 'focused'
                )}>
                <BookDate form={bookingForm} required />
              </div>
              <div
                className={clsx(
                  'book-steps-time',
                  focused !== items.timeSlot && !errors[fields.timeSlot] && values[fields.timeSlot] && 'valid',
                  focused !== items.timeSlot && errors[fields.timeSlot] && 'invalid',
                  focused === items.timeSlot && 'focused'
                )}>
                <TimeSlots form={bookingForm} required />
              </div>
            </div>
          </div>
          <div className="book-steps-form-right">
            <div
              className={clsx(
                'book-steps-keys-location',
                focused !== items.homeAddress && !errors[fields.homeAddress] && values[fields.homeAddress] && 'valid',
                focused !== items.homeAddress && errors[fields.homeAddress] && 'invalid',
                focused === items.homeAddress && 'focused'
              )}>
              <BookAddress
                form={bookingForm}
                name={fields.homeAddress}
                label="Collect keys from this address"
                placeholder="Home address"
                onFocus={() => {
                  setFocused(items.homeAddress)
                }}
                onBlur={() => {
                  setFocused(items.none)
                }}
                onKeyDown={onKeyDown(items.homeAddress)}
              />
            </div>
            <div
              className={clsx(
                'book-steps-model',
                focused !== items.carModel && !errors[fields.carModel] && values[fields.carModel] && 'valid',
                focused !== items.carModel && errors[fields.carModel] && 'invalid',
                focused === items.carModel && 'focused'
              )}>
              <Controller
                name={fields.carModel}
                control={bookingForm.control}
                rules={{ required: 'Car model is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Make and model *"
                    placeholder="Volkswagen Touareg..."
                    InputProps={{
                      disableUnderline: true,
                      onFocus: () => {
                        setFocused(items.model)
                      },
                      onBlur: () => {
                        setFocused(items.none)
                      },
                      onKeyDown: onKeyDown(items.model)
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </div>
            <div className="book-steps-car-details">
              <div
                className={clsx(
                  'book-steps-registration',
                  focused !== items.carRegistration && !errors[fields.carRegistration] && values[fields.carRegistration] && 'valid',
                  focused !== items.carRegistration && errors[fields.carRegistration] && 'invalid',
                  focused === items.carRegistration && 'focused'
                )}>
                <Controller
                  name={fields.carRegistration}
                  control={bookingForm.control}
                  rules={{ required: 'Car registration is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Car registration *"
                      placeholder="W12 3WK..."
                      InputProps={{
                        disableUnderline: true,
                        onFocus: () => {
                          setFocused(items.registration)
                        },
                        onBlur: () => {
                          setFocused(items.none)
                        },
                        onKeyDown: onKeyDown(items.registration)
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </div>
              <div
                className={clsx(
                  'book-steps-colour',
                  focused !== items.carColor && !errors[fields.carColor] && values[fields.carColor] && 'valid',
                  focused !== items.carColor && errors[fields.carColor] && 'invalid',
                  focused === items.carColor && 'focused'
                )}>
                <Controller
                  name={fields.carColor}
                  control={bookingForm.control}
                  rules={{ required: 'Car colour is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Colour *"
                      placeholder="Black..."
                      InputProps={{
                        disableUnderline: true,
                        onFocus: () => {
                          setFocused(items.colour)
                        },
                        onBlur: () => {
                          setFocused(items.none)
                        },
                        onKeyDown: onKeyDown(items.colour)
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={clsx(
                'book-steps-notes',
                focused !== items.notes && !errors[fields.notes] && values[fields.notes] && 'valid',
                focused !== items.notes && errors[fields.notes] && 'invalid',
                focused === items.notes && 'focused'
              )}>
              <Controller
                name={fields.notes}
                control={bookingForm.control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Notes"
                    placeholder="Have a second car? Please state the make, registration &amp; service required. Any specific request? Soft top deep clean, pet hair removal, child seat deep clean – just let us know!"
                    InputProps={{
                      disableUnderline: true,
                      onFocus: () => {
                        setFocused(items.notes)
                      },
                      onBlur: () => {
                        setFocused(items.none)
                      },
                      onKeyDown: onKeyDown(items.notes)
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    multiline
                    rows={isDesktop ? 4 : 5}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </div>
            <div className="book-steps-select-size">
              <Controller
                name={fields.carSize}
                control={bookingForm.control}
                rules={{ required: 'Car size is required' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <label className="book-steps-select-size-label">
                      Select car size * 
                      <a target="_blank" href="/terms_and_conditions#example-of-car-sizes">(see size guide)</a>
                    </label>
                    <input {...field} hidden />
                    <div
                      className={clsx(
                        'book-steps-select-size-item',
                        values[fields.carSize] === 'small' && 'selected',
                        errors[fields.carSize] && 'invalid'
                        //
                      )}
                      onClick={() => {
                        bookingForm.setValue(fields.carSize, 'small')
                        bookingForm.trigger(fields.carSize)
                      }}>
                      <div className="book-steps-select-size-name">Small to medium</div>
                    </div>
                    <div
                      className={clsx(
                        'book-steps-select-size-item',
                        values[fields.carSize] === 'large' && 'selected',
                        errors[fields.carSize] && 'invalid'
                        //
                      )}
                      onClick={() => {
                        bookingForm.setValue(fields.carSize, 'large')
                        bookingForm.trigger(fields.carSize)
                      }}>
                      <div className="book-steps-select-size-name">Large</div>
                      <div className="book-steps-select-size-description">Saloon, SUV or 4x4 - (£20 extra)</div>
                    </div>
                    {error?.message && (
                      <Typography variant="caption" style={{ color: 'red' }}>
                        {error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </div>
        <Typography className={classes.legend}>* — required fields</Typography>
        <NavButtons isFormValid={isValid} />
      </form>
    </div>
  )
}
