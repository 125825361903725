import 'url-search-params-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGTM from 'react-gtm-module'
import { settings } from './store/constants'
import { AppProvider } from './app-provider'
import './assets/styles'
// eslint-disable-next-line no-undef
require.context('./assets/images', true)

document.addEventListener('DOMContentLoaded', () => {
  ReactGTM.initialize({ gtmId: settings.googleTagManagerId })
  ReactDOM.render(<AppProvider />, document.body.appendChild(document.createElement('div')))
})
