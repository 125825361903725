export const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    borderBottom: '1px solid #dce0e6'
  },
  leftSide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    background: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    marginLeft: 70,
    position: 'absolute',
    top: 30
  },
  stepper: {
    padding: theme.spacing(3, 0, 1),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.only('md')]: {
      width: '70%'
    },
    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  }
})
