import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, FormLabel, Hidden } from '@material-ui/core'
import { CheckBoxRounded, CheckBoxOutlineBlankRounded } from '@material-ui/icons'
import clsx from 'clsx'

import { browserHistory } from '../../../../services/history'
import { context, useSelectors, setServices } from '../../../../store'
import { actions, AnalyticsService, categories } from '../../../../services/analytics'

import { BOOKING_ROUTES, STEPS } from '../steps'
import { NavButtons } from '../nav-buttons'

import { styles } from './styles'

export const StepService = React.memo(() => {
  const classes = makeStyles(styles)()
  const { dispatch } = useContext(context)
  const { baseService: selectedBaseService, prices, services } = useSelectors()

  const handleSelectBaseService = useCallback(
    serviceId => event => {
      event.preventDefault()
      const baseService = prices.byId[serviceId]

      baseService.id !== selectedBaseService.id && AnalyticsService.trackRemoveFromCart(selectedBaseService)

      const selectedServices = {
        ...services,
        ...baseService.excludes.reduce((acc, id) => ({ ...acc, [id]: false }), {}),
        [serviceId]: true
      }

      const servicesAvailability = Object.values(prices.byId).reduce((acc, service) => {
        return { ...acc, [service.id]: !baseService['includes'].includes(service.id) }
      }, {})

      dispatch(setServices({ services: selectedServices, servicesAvailability }))
    },
    [dispatch, prices.byId, selectedBaseService, services]
  )

  const renderSubHeader = useCallback(
    name => {
      const text = name === 'Mini Valet' ? 'Perfect as a regular Maintenance service' : name === 'Mini Valet PLUS' ? 'Ideal for a more intense clean' : null

      if (!text) return null

      return (
        <>
          <span className={classes.valetSubTitle}>{text}</span>
          <br />
        </>
      )
    },
    [classes]
  )

  const renderService = useCallback(
    serviceId => {
      const { name, description, customerPrice } = prices.byId[serviceId]
      const isSelected = services[serviceId]

      return (
        <Button
          key={serviceId}
          color="primary"
          className={clsx(classes.button, classes.serviceCard)}
          classes={{ label: classes.buttonLabel, outlinedPrimary: classes.outlinedPrimary }}
          variant={isSelected ? 'contained' : 'outlined'}
          onClick={handleSelectBaseService(serviceId)}
          fullWidth>
          <Box className={classes.valetTitle}>
            {isSelected ? (
              <CheckBoxRounded fontSize="inherit" style={{ color: '#37b45a', transform: 'scale(1.2)' }} />
            ) : (
              <CheckBoxOutlineBlankRounded fontSize="inherit" className={classes.checkbox} />
            )}
            <span className={classes.valetTitleServiceName}>{name}</span>
            <span className={classes.valetTitleServicePrice}>
              <small>({customerPrice})</small>
            </span>
          </Box>
          <Box className={classes.valetDescription} style={{ textAlign: 'justify', textJustify: 'distribute', textAlignLast: 'left' }}>
            {renderSubHeader(name)}
            {description}
          </Box>
        </Button>
      )
    },
    [classes, handleSelectBaseService, prices.byId, renderSubHeader, services]
  )

  const pricesArray = useMemo(() => Object.values(prices.byOrder), [prices.byOrder])

  const baseServices = useMemo(() => pricesArray.filter(service => service.group === 'base'), [pricesArray])
  const baseServicesFirstColumn = useMemo(() => baseServices.filter((_, i) => i % 2 === 0), [baseServices])
  const baseServicesSecondColumn = useMemo(() => baseServices.filter((_, i) => i % 2 !== 0), [baseServices])

  const handleNextStep = ev => {
    ev.preventDefault()
    browserHistory.push(BOOKING_ROUTES[STEPS.EXTRA])
  }

  useEffect(() => {
    if (selectedBaseService?.name) {
      AnalyticsService.trackEvent(categories.booking, actions.setlectBaseService, selectedBaseService.name, selectedBaseService.price.format().replace('£', ''))
      AnalyticsService.trackAddToCart(selectedBaseService)
    }
  }, [selectedBaseService])

  return (
    <form onSubmit={handleNextStep}>
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Box className={classes.innerContainer}>
            <FormLabel component="legend" className={classes.label}>
              Select a base service
            </FormLabel>

            <Hidden smDown>
              <Box className={classes.colWrapper}>
                <Box className={classes.col}>{baseServicesFirstColumn.map(({ id }) => renderService(id))}</Box>
                <Box className={classes.col}>{baseServicesSecondColumn.map(({ id }) => renderService(id))}</Box>
              </Box>
            </Hidden>

            <Hidden mdUp>
              {baseServices.map(({ id }) => (
                <Box key={id} className={classes.row}>
                  {renderService(id)}
                </Box>
              ))}
            </Hidden>
          </Box>
          <NavButtons />
        </Box>
      </Box>
    </form>
  )
})
