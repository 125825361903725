import { useCallback, useContext } from 'react'

import {
  setUser,
  apiUsersStatusStart,
  apiUsersStatusSuccess,
  apiUsersStatusFailure,
  apiUsersSignInStart,
  apiUsersSignInSuccess,
  apiUsersSignInFailure,
  apiUsersSignUpStart,
  apiUsersSignUpSuccess,
  apiUsersSignUpFailure
} from '../actions/users'
import { context } from '../context'
import { settings } from '../constants'

export const useUsersApi = () => {
  const { dispatch } = useContext(context)

  const apiUserSignIn = useCallback(
    ({ email, password }) => {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      }

      dispatch(apiUsersSignInStart())
      fetch(`${settings.host}/api/v2/users/sign_in`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

          const { data: user = {} } = json
          const { token } = user

          dispatch(setUser(user))
          window.localStorage.setItem('token', token)
          dispatch(apiUsersSignInSuccess())
        })
        .catch(error => {
          dispatch(apiUsersSignInFailure(`apiUserSignIn error: ${JSON.stringify(error)}`))
        })
    },
    [dispatch]
  )

  const apiUserSignUp = useCallback(
    ({ email, firstName, lastName, mobile, password }) => {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, first_name: firstName, last_name: lastName, mobile, password })
      }

      dispatch(apiUsersSignUpStart())
      fetch(`${settings.host}/api/v2/users/sign_up`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

          const { data: user = {} } = json
          const { token } = user

          dispatch(setUser(user))
          window.localStorage.setItem('token', token)
          dispatch(apiUsersSignUpSuccess())
        })
        .catch(error => {
          dispatch(apiUsersSignUpFailure(`apiUserSignUp error: ${JSON.stringify(error)}`))
        })
    },
    [dispatch]
  )

  const apiUserFacebookLogin = useCallback(
    ({ id: uid, email, first_name, last_name }) => {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, email, first_name, last_name })
      }

      dispatch(apiUsersSignInStart())
      fetch(`${settings.host}/api/v2/users/facebook_login`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

          const { data: user = {} } = json
          const { token } = user

          dispatch(setUser(user))
          window.localStorage.setItem('token', token)
          dispatch(apiUsersSignInSuccess())
        })
        .catch(error => {
          dispatch(apiUsersSignInFailure(`apiUserFacebookLogin error: ${JSON.stringify(error)}`))
        })
    },
    [dispatch]
  )

  const apiUserGoogleLogin = useCallback(
    ({ uid, email, first_name, last_name }) => {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, email, first_name, last_name })
      }

      dispatch(apiUsersSignInStart())
      fetch(`${settings.host}/api/v2/users/google_login`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

          const { data: user = {} } = json
          const { token } = user

          dispatch(setUser(user))
          window.localStorage.setItem('token', token)
          dispatch(apiUsersSignInSuccess())
        })
        .catch(error => {
          dispatch(apiUsersSignInFailure(`apiUserGoogleLogin error: ${JSON.stringify(error)}`))
        })
    },
    [dispatch]
  )

  const apiUserStatus = useCallback(() => {
    const token = window.localStorage.getItem('token')

    dispatch(apiUsersStatusStart())
    if (!token) return dispatch(apiUsersStatusFailure('User is not signed in.'))

    fetch(`${settings.host}/api/v2/users/status`, { headers: { 'X-User-Token': token } })
      .then(response => (response.ok ? response.json() : {}))
      .then(({ data: user = null } = {}) => {
        dispatch(setUser(user))
        dispatch(apiUsersStatusSuccess())
      })
      .catch(error => {
        dispatch(apiUsersStatusFailure(`userStatus error: ${JSON.stringify(error)}`))
      })
  }, [dispatch])

  const apiUserForgotPassword = useCallback(email => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    }

    return fetch(`${settings.host}/api/v2/users/forgot_password`, options)
      .then(response => response.json())
      .then((json = {}) => {
        const { status } = json

        if (status !== 'ok') {
          return 'not_found'
        }

        return 'success'
      })
      .catch(error => {
        return 'error'
      })
  }, [])

  const apiUserUpdate = useCallback(
    params => {
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Token': window.localStorage.getItem('token')
        },
        body: JSON.stringify(params)
      }

      return fetch(`${settings.host}/api/v2/users/update`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status, data: user = null } = json

          status === 'ok' && dispatch(setUser(user))

          return status
        })
        .catch(error => {
          return 'error'
        })
    },
    [dispatch]
  )

  return {
    apiUserSignIn,
    apiUserSignUp,
    apiUserStatus,
    apiUserFacebookLogin,
    apiUserGoogleLogin,
    apiUserForgotPassword,
    apiUserUpdate
  }
}
