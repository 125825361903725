import React, { useEffect, useLayoutEffect } from 'react'
import { Switch, Route, Redirect, useLocation, useRouteMatch } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import clsx from 'clsx'

import { AnalyticsService } from '../../services/analytics'

import { BookingStepper } from './components/stepper'
import { ServicesSummary } from './components/services-summary'
import { StepAddress } from './components/step-address'
import { StepService } from './components/step-service'
import { StepExtra } from './components/step-extra'
import { StepSignIn } from './components/step-sign-in'
import { StepCheckout } from './components/step-checkout'
import { StepFinish } from './components/step-finish'
import { ANALYTICS_STEPS, STEPS } from './components/steps'

import { styles } from './styles'

export const BookingPage = React.memo(() => {
  const classes = makeStyles(styles)()
  const location = useLocation()
  const step = useRouteMatch('/booking/:step')?.params?.step

  useEffect(() => {
    if ([STEPS.ADDRESS, STEPS.SERVICE, STEPS.EXTRA].includes(step)) AnalyticsService.trackImpressions(ANALYTICS_STEPS[step])
  }, [step])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isLoginStep = step === STEPS.SIGN_IN
  const isCheckoutStep = step === STEPS.CHECKOUT
  const showStepper = [STEPS.ADDRESS, STEPS.SERVICE, STEPS.EXTRA, STEPS.CHECKOUT].includes(step)
  const showSummary = [STEPS.ADDRESS, STEPS.SERVICE, STEPS.EXTRA].includes(step)

  return (
    <div className={clsx('book-steps', isLoginStep && 'book-steps-login-step', isCheckoutStep && 'book-steps-checkout-step')}>
      {showStepper && <BookingStepper logo={true} />}
      {showSummary && <ServicesSummary />}
      <Container className={classes.container}>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Switch location={location}>
              <Route exact path="/booking/address" component={StepAddress} />
              <Route exact path="/booking/service" component={StepService} />
              <Route exact path="/booking/extra" component={StepExtra} />
              <Route exact path="/booking/sign_in" component={StepSignIn} />
              <Route exact path="/booking/checkout" component={StepCheckout} />
              <Route exact path="/booking/finish" component={StepFinish} />
              <Redirect to="/" />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    </div>
  )
})
