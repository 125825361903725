export const SET_SERVICES = 'SET_SERVICES'
export const SET_ADDRESS = 'SET_ADDRESS'
export const SET_GEO_LOCATION = 'SET_GEO_LOCATION'
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM'
export const SET_DATE = 'SET_DATE'
export const SET_TIME_SLOT = 'SET_TIME_SLOT'
export const SET_HOME_ADDRESS = 'SET_HOME_ADDRESS'
export const SET_CAR_MODEL = 'SET_CAR_MODEL'
export const SET_CAR_REGISTRATION = 'SET_CAR_REGISTRATION'
export const SET_CAR_COLOR = 'SET_CAR_COLOR'
export const SET_NOTES = 'SET_NOTES'
export const SET_AGENT_TIP = 'SET_AGENT_TIP'
export const SET_ANYTIME_TODAY = 'SET_ANYTIME_TODAY'
export const SET_LARGE_CAR = 'SET_LARGE_CAR'
export const SET_CONGESTION_ZONE = 'SET_CONGESTION_ZONE'
export const RESET_BOOKING_FORM = 'RESET_BOOKING_FORM'
export const SET_IS_CARD_COMPLETE = 'SET_IS_CARD_COMPLETE'
export const SET_IS_PROCESSING_STRIPE = 'SET_IS_PROCESSING_STRIPE'
export const SET_IS_STRIPE_ERROR = 'SET_IS_STRIPE_ERROR'
export const SET_STRIPE_ERROR = 'SET_STRIPE_ERROR'
export const SET_IS_TERMS_ACCEPTED = 'SET_IS_TERMS_ACCEPTED'
