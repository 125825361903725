import React from 'react'
import { Avatar, Button, Container, TextField, Typography } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { styles } from './styles'

export const ResetPassword = React.memo(() => {
  const classes = makeStyles(styles)()

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Change Password
      </Typography>
      <form className={classes.form} noValidate>
        <TextField variant="outlined" margin="normal" required fullWidth name="password" label="New Password" type="password" id="password" />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label="Confirm new password"
          type="password"
          id="password_confirmation"
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Reset password
        </Button>
      </form>
    </Container>
  )
})
