import React from 'react'
import { BookNow } from '../../../components/book-now'

export const BookValet = () => (
  <section className="book-valet">
    <div className="book-valet-content">
      <h1 className="book-valet-title">Book a wash now</h1>
      <BookNow />
    </div>
    <div className="book-valet-bottom-triangle">
      <svg preserveAspectRatio="none" viewBox="0 0 100 100">
        <polygon points="100,100 100,0 0,100" opacity="1"></polygon>
      </svg>
    </div>
  </section>
)
