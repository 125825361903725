import { parse, stringify } from 'query-string'
import { createBrowserHistory } from 'history'

const createPreserveQueryBrowserHistory = options => {
  const history = createBrowserHistory(options)

  const oldPush = history.push
  const oldReplace = history.replace

  history.push = (path, state) => {
    const locationDescriptorObject = typeof path === 'string' ? { pathname: path, ...state } : path
    const currentQuery = parse(history.location.search)
    const newQuery = parse(locationDescriptorObject?.search)
    const params = {
      ...locationDescriptorObject,
      search: stringify({ ...currentQuery, ...newQuery })
    }

    return oldPush.apply(history, [params])
  }
  history.replace = (path, state) => {
    const locationDescriptorObject = typeof path === 'string' ? { pathname: path, ...state } : path
    const currentQuery = parse(history.location.search)
    const newQuery = parse(locationDescriptorObject?.search)
    const params = {
      ...locationDescriptorObject,
      search: stringify({ ...currentQuery, ...newQuery })
    }

    return oldReplace.apply(history, [params])
  }

  return history
}

export const browserHistory = createPreserveQueryBrowserHistory()
