import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../../components/page-header'

export const TermsAndConditionsPage = React.memo(() => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="home">
      <Helmet title="Terms and Conditions" />
      <PageHeader title="Terms and Conditions" />
      <div className="faqs">
        
        <h3><strong>INTRODUCTION</strong></h3>
        <p><strong>Company:</strong> GOWASHMYCAR LTD (<strong>“Company”</strong>) is a company registered in England and Wales with registered company number 09179506. The registered office is at 44 Elvaston Place, London, SW7 5NP.</p>
        <p><strong>Service:</strong> The Company provides a platform for you to communicate your order (<strong>"Order"</strong>) for a car wash (<strong>"Service"</strong>) to car wash operators (<strong>"Operators"</strong>) posted on the Website (<strong>"Platform"</strong>).</p>
        <p>The Company does not provide Car Wash Services and the Company is not a car wash operator. It is up to the 3rd party Operators to offer Car Wash Services which may be scheduled through the use of the Website or Service. The Company offers information and a method to obtain such 3rd party Car Wash Services but does not provide Car Wash Services or act in any way as a Car Wash Service provider, and has no responsibility or liability for any Car Wash Services provided to you by 3rd party Operators. In the event that you have a dispute with one or more Operators, you release GOWASHMYCAR LTD (and our officers, directors, agents, investors, subsidiaries, and employees) from any and all claims, demands, or damages (actual or consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</p>
        <p>Your use of the Platform constitutes your acceptance of and agreement to all of the terms and conditions in these Terms and Conditions and Privacy Policy and your representation that you are at least 18 years of age and are legally capable of entering into binding contracts.</p>


        <h3><strong>COMPANY WEBSITE</strong></h3>
        <p><strong>Terms:</strong> By accessing any part of the Platform, you indicate that you accept these Platform Terms.</p>
        <p><strong>Amendment of terms:</strong> The Platform Terms are subject to change at any time, and effective upon posting on the Platform. It is advised you check back regularly in the case of any amendments.</p>
        <p><strong>Website access:</strong> You may access some areas of the Website without making an Order or registering your details with us. Most areas of the Website are open to everyone.</p>
        <p><strong>Responsibility:</strong> You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your Internet connection are aware of these Website Terms and that they comply with them.</p>
        <p><strong>Website availability:</strong> While we try to ensure the Website is normally available twenty four hours a day, we do not undertake any obligation to do so, and we will not be liable to you if the Website is unavailable at any time or for any period.</p>
        <p><strong>Information security:</strong> The transmission of information via the internet is not completely secure. Although we take the steps required by law to protect your information, we cannot guarantee the security of your data transmitted to the Website; any transmission is at your own risk.</p>


        <h3><strong>GOVERNING LAW AND JURISDICTION</strong></h3>
        <p>These Website Terms shall be governed by and construed in accordance with English law. Disputes or claims arising in connection with these Website Terms (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English courts.</p>


        <h3><strong>PLACING AN ORDER</strong></h3>
        <p><strong>Order Posting:</strong> Enter the details of your vehicle and select the appropriate Service required to achieve your desired results. Enter your FULL address with post code. Once complete you can click on the NEXT button to proceed to payment. <strong>It is important you check that all your details are correct, as once payment has been made there is no guarantee that the order can be amended or cancelled. </strong></p>


        <h3 id="example-of-car-sizes"><strong>Example of Car Sizes</strong></h3>
        <p><strong>Medium</strong> size would be up to a BMW 5 series, Mercedes E class, Audi A6 etc. Estate cars come under the large car category.</p>
        <p><strong>Large</strong> cars would be BMW 7 series, Mercedes S Class, Audi A7, Bentley, Rolls Royce etc</p>
        <p> All <strong>4 X 4’s</strong> and <strong>SUVs</strong> with up to 5 seats are classed as large cars.</p>


        <h3><strong>Alloy Wheels</strong></h3>
        <p>Neglected wheels will incur an additional charge. Prices start from £10 per wheel and include a sealant.</p>


        <h3><strong>Vans</strong></h3>
        <p>Whilst we are happy to clean vans, we need photos to determine which service would be most appropriate together with any limitations. Note, we do not clean the roof, nor do we enter the load area.</p>


        <h3><strong>Seating</strong></h3>
        <p>All prices are based on cars with up to <strong>5</strong> seats. Seats are counted by the number of seat belts fitted. Please note our operators will charge accordingly in line with our price list for additional seating.</p>


        <h3><strong>Child seats</strong></h3>
        <p>if cleaning is required, the current rate is £25 per seat. Please remember to allow time for the seat to dry after cleaning.</p>


        <h3><strong>Order Amendment and Cancellation</strong></h3>
        <p>Once you have submitted your Order and your payment has been authorised, you may be able to change or amend your Order up to 24 hours before the start of the booking window. Cancellations made within 24 hours of the booking window are liable for a 50% refund of the original order charge, and within 4 hours of the slot time, we cannot offer a refund in part or full.</p>


        <h3><strong>Order Processing and Rejections</strong></h3>
        <p>Once you have posted your Order on the Platform, it will be open to review by the relevant Operators. Once accepted by an operator, you will be notified by email that your Order has been accepted together with the details of the relevant Operator. Please note that confirmation of acceptance of your order is only confirmed once you receive the specific email notification from the Operator.</p>


        <h3><strong>Service Delivery and Timings</strong></h3>
        <p>Estimated times for operator arrival and Service completion may be provided by the Operators and are only estimates for guidance. Neither the company nor the Operator guarantee that arrival times or wash times will be completed within the estimated times.</p>


        <h3><strong>On Completion</strong></h3>
        <p>On completion of the service, the customer is requested to view the completed car and sign the operators job sheet. Any defect that was included in the chosen service and is not to the customers satisfaction, the operator will rectify before leaving. If the customer chooses not to check the completed clean, neither the company nor the operator accept any further liability.</p>


        <h3><strong>Operator Terms and Conditions</strong></h3>
        <p>By placing an Order and having it accepted by an Operator, you are entering into an agreement between yourself and the Operator company that accepted the order. </p>
        <p>Our operators will assist customers in making sure the correct service is carried out to manage their expectations.  Any additional charges will be charged accordingly as agreed between the customer and operator. If the service booked is not sufficient to meet the customer’s expectations, and the customer is not willing to upgrade the service, two options are available. Firstly, the customer may wish to cancel at which point there are no refunds, secondly continue with the service as booked and accept the operator will perform best job possible, which means the operator may not meet the customers’ expectations, at which point Neither The Company nor the operator will not accept any complaint.</p>


        <h3><strong>DISCLAIMER</strong></h3>
        <p><strong><i><mark>*PLEASE NOTE** GOWASHMYCAR and your operator, reserves the right to adjust the price on inspection of the car at time of arrival. Excessively dirty cars may incur a surcharge. Your operator will inform you at the time.</mark></i></strong></p>
        <p><strong><i><mark>An additional charge will be made for Vehicles that require Vomit, poo or similar contamination removal.  Our operators will discuss the appropriate action and cost.</mark></i></strong></p>


        <h3><strong>Remote unlocking/locking</strong></h3>
        <p>Gowashmycar or our operators, will not be held responsible for any loss or damage to the vehicle or any items left inside the car. Operators will remain with the car for a maximum 5 minutes after the notification is sent with the request to lock the car. After which, Waiting time will be charged at the current rate of £1 per minute, for a maximum of 10 mins, after which they will leave.  If they leave and the customer has not locked the car, neither the company nor the operator accept any liability.</p>


        <h3><strong>Waiting time</strong></h3>
        <p>Our operators will allow up to 10 minutes waiting time after which the customer accepts that a standard charge is applicable at the current rate of £1 per minute.</p>


        <h3><strong>Valuables and Personal possessions</strong></h3>
        <p>To avoid additional charges, it is important the customer removes <strong>ALL</strong> personal items and valuables, which would include, but not limited to, Child seats, Buggies and or wheelchairs, tissues, face masks and general rubbish, prior to the arrival to the arrival of your operator. Our operators are instructed <strong>NOT</strong> to remove such items, as It is NOT the responsibility of the operator to determine what is rubbish and what is not.</p>


        <h3><strong>Access to customer car</strong></h3>
        <p><strong>Needs to know.</strong> Car parks may have a height restriction. We need to know that height. The Customer <strong>MUST</strong> obtain permission to wash cars in car parks from the land/car park owner. Any charges applicable for parking are paid by the customer. The customer also accepts that carrying out the service under artificial lighting is not best option. The operator will always do best job possible, however they cannot be held responsible for less than perfect cleans.</p>
        <p>Our operators are used to working roadside. The customer must ensure the operator will <strong>NOT</strong> be in harm’s way, and is parked legally, whilst cleaning the car.</p>
        <p>Operators need to park directly Infront or behind the customer car. Opposite may not be an option as it is dangerous to drag hose pipes and electrical cables across the road or public footways.</p>
        <p>Our operators are instructed <strong>NOT</strong> to move customer cars. Therefore, it is essential for the owner or a representative of the owner, is available to move the car to an alternative safe and suitable location if required.</p>
        <p>If for any reason the service cannot be carried out, there is no refund option.</p>

        <h3><strong>PAYMENT</strong></h3>
        <p>
          <strong>Payment</strong> The Company uses an internationally recognized company called Stripe to process all payments. Payment for Orders must be made
          by an accepted credit or debit card through the Platform.
        </p>
        <p>
          <strong>Card payments:</strong> Please note that from time to time there may be delays with the processing of card payments and transactions; this may
          result in payments taking up to (60) days to be deducted from your bank account or charged to your credit or debit card. All card payments are
          processed by GOWASHMYCAR LTD, please contact customer support should you have any payment related queries. Upon placing an order, your operator can
          request to view the card used for payment. The operator and GOWASHMYCAR reserve the right to cancel the order should the customer fail to produce the
          card used for payment.
        </p>
        <p>
          <strong>Cancelled Orders:</strong> Due to standard banking procedures, once you have submitted an Order that you are paying for by credit or debit
          card and your payment has been authorised, your bank or card issuer will &quot;ring-fence&quot; the full amount of your Order. A cancellation of an
          order does not guarantee funds will be released back to your account immediately. You acknowledge and agree that neither we nor the relevant Operator
          will be responsible or liable to you in relation to a delay by your bank or card issuer in the release of funds back into your account.{' '}
        </p>

        <h3>
          <strong>ORDER MAINTENANCE AND CUSTOMER FEEDBACK</strong>
        </h3>
        <p>
          <strong>General:</strong> We welcome any feedback and want to help our customers. In the case of any questions or problems, please send us an email to
          the address listed at the bottom of the page. In the event that you are dissatisfied with the quality of any Products or the Service provided by an
          Operator, please consider providing feedback by emailing us. The feedback is important part of our quality control and helps us to improve.
        </p>
        <p>
          <strong>Order delays or questions:</strong> If your Order is taking longer than expected or you have any other problems with your Order, we recommend
          you contact the Operator first but failing that please call us or send an email to let us know.
        </p>


        <h3>
          <strong>PLATFORM TERMS OF PERMITTED USE</strong>
        </h3>
        <p>
          <strong>You are permitted to use the Platform for your own personal non-commercial use on the following basis:</strong>
        </p>
        <ul>
          <li>You must not misuse the Website by or attempt to hack it in any way</li>
          <li>
            The copyright and other intellectual property rights (such as text, graphics, data, graphics, HTML, videos, images and other content) in the
            Platform and in material published on it are owned by us or our licensors. These works are protected by copyright laws and treaties around the world
            and all rights are reserved. Any use of such material from the Website is prohibited unless with prior consent.
          </li>
          <li>In order to use any of the materials on the Website or the Website itself for commercial purposes, you will require a licence from us.</li>
          <li>
            You must not modify the digital or paper copies of any materials that you print off and you must not use any pictures, photographs or any other
            graphics, video or audio sequences separately from any accompanying text.
          </li>
          <li>In the case of any use, our status as the author of the material should always be acknowledged. </li>
        </ul>
        <p></p>
        <p>
          <strong>Reservation of rights:</strong> Any rights not expressly granted in these Website Terms are reserved.
        </p>

        <h3>
          <strong>PLATFORM AND OPERATOR REVIEWS</strong>
        </h3>
        <p>
          <strong>General:</strong>
        </p>
        <ul>
          <li>
            Any reviews or information you post, upload or transmit or upload to the Website will be considered non-confidential and non-proprietary. By
            posting, uploading or transmitting any Visitor Data, you represent and warrant that you own or otherwise control all of the rights to such Visitor
            Data. You agree that we will have no obligations with respect to any Visitor Data, and that we and anyone we designate will be free to copy,
            disclose, distribute, incorporate and otherwise use any Visitor Material and all data, images, sounds, text and other things embodied in it for any
            and all commercial or non-commercial purposes.
          </li>
          <li>
            You represent and warrant that that any Visitor Data you post, upload or transmit does not and will not breach any of the restrictions listed below.{' '}
          </li>
        </ul>
        <p></p>
        <p>
          <strong>Visitor Data Policy: </strong>You are prohibited from posting, uploading or transmitting to or from the Website any Visitor Material
          (including any Reviews) that:
        </p>
        <ul>
          <li>breaches any applicable local, national or international law;</li>
          <li>is unlawful or fraudulent;</li>
          <li>amounts to unauthorised advertising; or</li>
          <li>contains viruses or any other harmful programs.</li>
        </ul>
        <p></p>
        <p>
          <strong>Reviews Policy: </strong>In particular (but without limitation), any Reviews that you submit through the Website must not:
        </p>
        <ul>
          <li>contain any offensive , or defamatory material;</li>
          <li>infringe the intellectual property rights of another person;</li>
          <li>breach any legal duty owed to a third party;</li>
          <li>give the impression that they originate from us; or</li>
          <li>promote discrimination, illegal activity or violence;</li>
          <li>be used to misrepresent yourself or attempt to impersonate another person.</li>
        </ul>
        <p></p>
        <p>
          <strong>Removal of Reviews: </strong>We reserve the right and have the sole discretion to remove or edit at any time any Reviews or other Visitor
          Material posted, uploaded or transmitted to the Website that we determine breaches a prohibition or is otherwise objectionable or may expose us or any
          third parties to any harm or liability of any type, or for any other reason.
        </p>
        <p>
          <strong>Liability: </strong>You agree to indemnify us against any losses, damages and claims (and all related costs) incurred by or made against us by
          an Operator or any other third party arising out of or in connection with any Reviews or other Visitor Data that you provide in breach of any of the
          representations and warranties, agreements or restrictions set forth in this paragraph.
        </p>
        <p>
          <strong>Disclosure to authorities and courts: </strong>You acknowledge that we will fully co-operate with any competent authority requesting or
          directing us to disclose the identity or location of anyone posting any Reviews or other Visitor Material in breach. Furthermore you release us to the
          fullest extent permitted by law from all liability in relation to such disclosure.
        </p>

        <h3>
          <strong>3RD PARTY WEBSITES AND LINKING</strong>
        </h3>
        <p>
          <strong>Linking permission:</strong> In order to link to the Website (www.gowashmycar.com), you need to ensure:
        </p>
        <ul>
          <li>You do not damage or take advantage of our reputation by creating the link;</li>
          <li>you do not create an association or endorsement with the linking site where none exists;</li>
          <li>any website from which you link must comply with the content standards set out in these Website Terms;</li>
          <li>we have the right to withdraw linking permission at any time and for any reason.</li>
        </ul>
        <p></p>
        <p>
          <strong>Third party websites:</strong> Links to third party websites on the Website are provided solely for your convenience. If you use these links,
          you leave the Website. We have not reviewed and do not control any of these third party websites (and are not responsible for these websites or their
          content or availability). We do not endorse or make any representation about these websites, their content, or the results from using such websites or
          content. If you decide to access any of the third party websites linked to the Website, you do so entirely at your own risk.
        </p>

        <h3>
          <strong>DISCLAIMERS OF WARRANTIES</strong>
        </h3>
        <p>
          <strong>Website information: </strong>We try to ensure all information on the site is accurate and relevant however we offer no guarantee of this
          accuracy, relevance or completeness. We reserve the right to make changes without notice.
        </p>
        <p>
          <strong>Operator actions: </strong>The legal contract for the supply and purchase of a Service is between you and the Operator that you place your
          Order with. We have no control over the actions or omissions of any Operators. You acknowledge and accept the following by using the Website:
        </p>
        <ul>
          <li>
            We do not give any undertaking that the Service provided by any Operator through the Platform will be of satisfactory quality or suitable for your
            purpose and we disclaim any such warranties.{' '}
          </li>
          <li>
            Estimated times for arrivals are provided by the Operator and are only estimates. Neither we nor the Operators guarantee that Orders will be
            completed within the estimated times.
          </li>
          <li>
            Although our Operators are held to a high quality standard, we cannot be held liable for any damage suffered by the vehicle or object being washed,
            losses or consequential damages as a result of the Operators actions. Any recourse as a result of damage should be pursued with the Operator company
            that provided the particular service.
          </li>
          <li>
            We encourage all our Operators to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon
            as reasonably practicable if an Operator rejects your Order. However, we do not guarantee that Operators will accept all Orders, and Operators have
            the discretion to reject Orders at any time because they are too busy, due to weather conditions or for any other reason.
          </li>
          <li>The foregoing disclaimers do not affect your statutory rights against any Operator. </li>
        </ul>
        <p></p>
        <p>
          <strong>Exclusion of terms: </strong>We provide you with access to the Platform and Service on the basis that, to the maximum extent permitted by law,
          we exclude all representations, warranties, conditions, undertakings and other terms in relation to the Website and Service (including any
          representations, warranties, conditions, undertakings and other terms which might otherwise apply to the Website or Service, or be otherwise implied
          or incorporated into these Website Terms, by statute, common law or otherwise).
        </p>

        <h3>
          <strong>LIABILITY</strong>
        </h3>
        <p>
          <strong>General: </strong>Nothing in these Website Terms excludes or limits our liability for death or personal injury arising from our negligence,
          our liability for fraudulent misrepresentation, or any other liability which cannot be excluded or limited under applicable law. Nothing in these
          Website Terms affects your statutory rights.
        </p>
        <p>
          <strong>Exclusion of liability: </strong>We will under no circumstances whatever be liable to you, whether in contract, tort (including negligence),
          breach of statutory duty, or otherwise, even if forseeable, arising under or in connection with the Service or the Website (including the use,
          inability to use or the results of use of the Service or the Website) for:
        </p>
        <ul>
          <li>any loss of profits, sales, business, or revenue; </li>
          <li>loss or corruption of data, information or software; </li>
          <li>loss of business opportunity; </li>
          <li>loss of anticipated savings; </li>
          <li>loss of goodwill; or </li>
          <li>any indirect or consequential loss. </li>
        </ul>
        <p></p>
        <p>
          <strong>Limitation of liability: </strong>Subject to clauses mentioned above, our total liability to you in respect of all other losses arising under
          or in connection with the Service or the Website, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in
          no circumstances exceed £20.
        </p>
        <p>
          <strong>Additional costs: </strong>You assume full and sole responsibility for any additional or associated costs that you may incur in connection
          with or as a result of your use of the Website, including without limitation costs relating to the servicing, repair or adaptation of any equipment,
          software or data that you may own, lease, license or otherwise use.
        </p>

        <h3>
          <strong>TERMINATION</strong>
        </h3>
        <p>
          <strong>Grounds for termination: </strong>We may terminate or suspend (at our absolute discretion) your right to use the Platform and the Service
          immediately by notifying you in writing (including by email) if we believe in our sole discretion that:
        </p>
        <ul>
          <li>you have used the Website in breach of our permitted terms of use </li>
          <li>you have posted Reviews or other Visitor Material in breach Visitor Material Policy; </li>
          <li>you have breached any other material terms of these Website Terms. </li>
        </ul>
        <p></p>
        <p>
          <strong>Obligations upon termination: </strong>Upon termination or suspension you must immediately destroy any downloaded or printed extracts from the
          Website.
        </p>

        <h3>
          <strong>WRITTEN COMMUNICATIONS</strong>
        </h3>
        <p>
          Applicable laws require that some of the information or communications we send to you should be in writing. When using the Website or ordering
          Products via the Website, you accept that communication with us will be mainly electronic. We will contact you by email or provide you with
          information by posting notices on the Website. For contractual purposes, you agree to this electronic means of communication and you acknowledge that
          all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such
          communications be in writing. This condition does not affect your statutory rights.
        </p>

        <h3>
          <strong>ADDITIONAL TERMS</strong>
        </h3>
        <p>
          <strong>Privacy Policy:</strong> We are committed to protecting your privacy and security. All personal data that we collect from you will be
          processed in accordance with our Privacy Policy. You should review our Privacy Policy.{' '}
        </p>
        <p>
          <strong>Other terms:</strong> You should also review our Cookies Policy for information regarding how and why we use cookies to improve the quality of
          the Website and Service.
        </p>
        <p>
          <strong>Severability: </strong>If any of these Website Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any
          extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be
          valid to the fullest extent permitted by law.
        </p>
        <p>
          <strong>Entire agreement: </strong> These Website Terms and any document expressly referred to in them constitute the whole agreement between you and
          us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us relating to the
          subject matter of any contract.
        </p>
        <p>
          <strong>No waiver: </strong> TAny failure or delay by you or us in enforcing (in whole or in part) any provision of these Website Terms will not be
          interpreted as a waiver of your or our rights or remedies.
        </p>
        <p>
          <strong>Assignment: </strong> You may not transfer any of your rights or obligations under these Website Terms without our prior written consent. We
          may transfer any of our rights or obligations under these Website Terms without your prior written consent to any of our affiliates or any business
          that we enter into a joint venture with, purchase or are sold to.
        </p>
        <p>
          <strong>Headings: </strong> The headings in these Website Terms are included for convenience only and shall not affect their interpretation.
        </p>
      </div>
    </div>
  )
})
