import React from 'react'
import { Helmet } from 'react-helmet'
import { Paper } from '@material-ui/core'

import SignUpOverflow from '../components/sign-up/SignUpOverflow'

export const SignUpPage = React.memo(() => {
  return (
    <Paper>
      <Helmet title="Sign Up" />
      <SignUpOverflow />
    </Paper>
  )
})
