import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Box, Container } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { useSelectors } from '../../../../store'

import { styles } from './styles'

export const ServicesSummary = React.memo(props => {
  const { defaultExpanded, expanded } = props
  const classes = makeStyles(styles)()
  const {
    address,
    date,
    sameDay,
    timeSlot,
    carRegistration,
    baseService,
    extraServicesCount,
    prices,
    agentTip,
    agentTipPrice,
    promoCode,
    percentDiscountPrice,
    creditDiscountPrice,
    price,
    sortedSelectedServiceIds
  } = useSelectors()

  const header = useMemo(() => {
    const extrasString = `${extraServicesCount ? ` + ${extraServicesCount} extra${extraServicesCount > 1 ? 's' : ''}` : ''}`
    const tipString = `${agentTip ? ' + tip' : ''}`
    const headerString = `${baseService?.name}${extrasString}${tipString}`

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography className={classes.heading}>{headerString}</Typography>
        <Typography className={classes.headingTotal}>{price.format()}</Typography>
      </div>
    )
  }, [extraServicesCount, agentTip, baseService, classes, price])

  const summary = useMemo(() => {
    return (
      <Container className={classes.container}>
        <Box className={classes.headingContainer}>
          <Typography className={classes.summary}>Order summary</Typography>
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.firstLine}>Location: </Typography>
          <Typography className={classes.secondLine}>{address?.split(',')?.[0]}</Typography>
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.firstLine}>Date: </Typography>
          <Typography className={classes.secondLine}>
            {date ? format(date, 'eeee dd MMM yyyy') : ''}, {sameDay ? 'Anytime' : timeSlot}
          </Typography>
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.firstLine}>Car registration: </Typography>
          <Typography className={classes.secondLine}>{carRegistration}</Typography>
        </Box>
        {sortedSelectedServiceIds.map(id => (
          <Box className={classes.row} key={`brief-summary-${id}`}>
            <Typography className={classes.text}>{prices.byId[id]?.name}</Typography>
            <Typography className={classes.text}>{prices.byId[id]?.price.format()}</Typography>
          </Box>
        ))}
        {agentTip && (
          <Box className={classes.row}>
            <Typography className={classes.text}>Agent tip</Typography>
            <Typography className={classes.text}>{agentTipPrice.format()}</Typography>
          </Box>
        )}
        {percentDiscountPrice.intValue > 0 && (
          <Box className={classes.row}>
            <Typography className={classes.text}>{promoCode && `${promoCode.value}% discount`}</Typography>
            <Typography className={classes.text}>–{percentDiscountPrice.format()}</Typography>
          </Box>
        )}
        {creditDiscountPrice.intValue > 0 && (
          <Box className={classes.row}>
            <Typography className={classes.text}>Credit</Typography>
            <Typography className={classes.text}>–{creditDiscountPrice.format()}</Typography>
          </Box>
        )}
        <Box className={classes.rowTotal}>
          <Typography className={classes.totalText}>Total: {price.format()}</Typography>
        </Box>
      </Container>
    )
  }, [
    classes,
    address,
    date,
    sameDay,
    timeSlot,
    carRegistration,
    sortedSelectedServiceIds,
    agentTip,
    agentTipPrice,
    percentDiscountPrice,
    promoCode,
    creditDiscountPrice,
    price,
    prices.byId
  ])

  return (
    <ExpansionPanel className={classes.brief} defaultExpanded={defaultExpanded} expanded={expanded}>
      <ExpansionPanelSummary className={classes.briefSummary} expandIcon={<ExpandMore />}>
        {header}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.briefDetails}>{summary}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
})
