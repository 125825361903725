import { useCallback, useContext } from 'react'

import { setServicePrices, apiServicesFetchAllStart, apiServicesFetchAllSuccess, apiServicesFetchAllFailure } from '../actions/services'
import { setServices } from '../actions/booking'
import { context } from '../context'
import { settings, LARGE_CAR_SERVICE, SAME_DAY_SERVICE, CONGESTION_ZONE_SERVICE } from '../constants'
import { servicesAdapter } from '../adapters'

export const useServicesApi = () => {
  const { dispatch } = useContext(context)

  const apiServicesFetchAll = useCallback(() => {
    dispatch(apiServicesFetchAllStart())
    fetch(`${settings.host}/api/v2/services`)
      .then(response => {
        if (response.ok) return response.json()
        else throw new Error(`apiServicesFetchAll status: ${response.statusText}`)
      })
      .then((json = {}) => {
        const { status, data: apiServices } = json

        if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

        dispatch(apiServicesFetchAllSuccess())
        const services = servicesAdapter(apiServices)
        dispatch(setServicePrices(services))

        const firstBaseService = services.find(service => service.group === 'base')
        const largeCarService = services.find(service => service.name === 'Extra for large car')
        const sameDayService = services.find(service => service.name === 'Extra for same day')
        const congestionZoneService = services.find(service => service.name === 'Congestion zone premium')
        largeCarService && Object.assign(LARGE_CAR_SERVICE, largeCarService)
        sameDayService && Object.assign(SAME_DAY_SERVICE, sameDayService)
        congestionZoneService && Object.assign(CONGESTION_ZONE_SERVICE, congestionZoneService)

        const selectedServices = {
          ...services.reduce((acc, service) => ({ ...acc, [service.id]: false }), {}),
          [firstBaseService.id]: true,
          [largeCarService.id]: null
        }

        const servicesAvailability = services.reduce((acc, service) => ({ ...acc, [service.id]: !firstBaseService.includes.includes(service.id) }), {})

        dispatch(setServices({ services: selectedServices, servicesAvailability }))
      })
      .catch(error => dispatch(apiServicesFetchAllFailure(`apiServicesFetchAll error: ${JSON.stringify(error)}`)))
  }, [dispatch])

  return {
    apiServicesFetchAll
  }
}
