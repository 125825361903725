import React, { useState, useEffect } from 'react'
import { withGoogleMap, GoogleMap, Polygon } from 'react-google-maps'
import { compose, withHandlers } from 'recompose'

import { debounce } from '../../../../../lib/debounce'

import { styles } from './styles'

const MapComponent = ({ center, zoom, onDragEnd, onMapMounted, onZoomChanged }) => {
  const [mapZoom, setMapZoom] = useState(zoom)

  useEffect(() => {
    setMapZoom(zoom)
  }, [zoom])

  return (
    <GoogleMap
      ref={onMapMounted}
      onDragEnd={onDragEnd}
      onZoomChanged={debounce(() => {
        const zoom = onZoomChanged()
        setMapZoom(zoom)
      }, 500)}
      center={center}
      zoom={mapZoom}
      defaultOptions={{
        zoom: 11,
        minZoom: 9,
        maxZoom: 17,
        zoomControl: true,
        zoomControlOpt: { style: 'MEDIUM', position: 'LEFT_BOTTOM' },
        panControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: 'greedy',
        styles
      }}>
      {mapZoom < 14 && (
        <Polygon
          paths={window.google.maps.geometry.encoding.decodePath(
            'mvvxHqzg@sUkSaKcK_KeMyP_WcNsXeFcLuRgd@kPsUiO_KgUmEif@l@kf@bByNfJyOpGeKiG{HaKw]eu@qFiKeG}LuGgM_HkHwd@{a@qRyNaIcGaImDcScFuUn@_c@}C{[mCy\\oDmx@zDa[r@{[_C}PwF}PyHaa@mYkb@{Pkb@wOe\\aGeZyCuVqGk\\sViSyJ{LcAoK?_Jj@iKnCu[tLuy@tb@u[j^iNpSuUdOiYpGo\\xBo\\|E}RnMi\\bb@uTjUiQlUw\\|gAqP~c@eS|b@}Z|i@oVl_@mZh]eVrZwVfc@uWnr@gPxw@_Hbe@_L`e@{t@ppAiV~hA}d@ppA}Jtf@_KbyAqi@~vAsK|j@hBvf@hDzrB|TzdB~Nps@rMd_@~Fja@|Dbm@iH|gAsKl`AhBtrASd|@_Ih~@iBn|BrAjnAqi@dwB_C`xA}Hrt@rAnc@~Prt@_A~vArSh|A_Alb@iLjq@h@tf@hT|x@hB`]_If}@}Dpd@R`^~Jre@~B`]_Evg@_Mtt@{^vg@}ZxY_QvI}PfQgZ|x@{p@xtAsIha@~@dn@fPre@hNlb@hD`]_C`l@sG|gAUtf@rMloA?flAsGvu@}Zf{AsEtf@~@nr@hJzw@rAbm@i@d|@Tb^rI|j@|Lb^rUf`@|Z|i@pUhRrUpGpu@fB|XfQrWbm@fV~j@tGnd@~@fg@|Dtm@hNpd@h\\pVzn@a@r_@vXrg@bO~VjSf^ps@rc@pd@hb@d_@hXxJ|`AzKhp@`Ote@xJ~p@vIri@nFt[nF~p@wItl@kSth@oU~ToTjNwX~L{i@hFa]tOyYt]iRlt@}Ljz@?`o@lT`[iCvo@qd@`a@oF`e@zKll@xg@bo@`Ovg@`]bk@hp@xu@vXbc@dm@ba@bP`[b^xe@tWzu@wInn@lEl\\?|iAmc@nd@qd@zg@ekAl`@qd@|y@{ZxZc^d`@g`@x^_\\n]g`@hy@gCnb@{KlVqUdWgo@vSo{BvKia@zf@g{Ape@waBbM}gAk@go@wMc{@aKuf@aIst@uG}dByMimA`Em`AxUyg@pf@{ZxUmTfc@qs@jLsbAvGurAlNqs@xUcm@d_@wg@pd@yYxOmS|c@udAzc@{tAbKcm@~Bwv@_EqpAcKsbAcSkq@mPka@aI{i@kDem@tCuf@jHod@jFse@`Cal@tAyg@tA_]tE}ZbMuf@vMytAjDsqA_CyeAwEurA`Cuf@hBkp@_Eyw@yQmq@cMoTwO_]wKgn@k@yeA`GmoAH}b@_B{a@cVowAwE_k@_@y`@~Bip@uAgn@kGwk@gK_k@{KsmA{Iuf@yKse@kJyYgFyYkFoc@{A_d@oC_d@kEc^wFc^wKuf@wUkDoPqCaLuGoWqq@cFsJwF_IoKyHeJmBqK?wItAaYhCeQV}Q{@gRq@sPbAcNzDgU|HoKxAcMTmXs@e]Yo`@hAgMP{PcB}O_J}O{Om[sl@mQ{i@{GeTqIaRmH}L{GuIsG}HuGiG}R{OuSuM}RkLuIwFiHkHk[ec@oMqP'
          )}
          strokeColor="#00a0d0"
          strokeOpacity={0.6}
          strokeWeight={2}
          fillColor="#00a0d0"
          fillOpacity={0.2}
        />
      )}
    </GoogleMap>
  )
}

// const handlers = ({ dragHandler }) => {
//   const refs = {
//     map: undefined
//   }

//   return {
//     onMapMounted: () => ref => {
//       refs.map = ref
//     },
//     onDragEnd: () => () => {
//       dragHandler(refs.map.getCenter())
//     },
//     onZoomChanged: () => () => {
//       dragHandler(refs.map.getCenter())

//       return refs.map.getZoom()
//     }
//   }
// }

export const Map = compose(
  // withHandlers(handlers),
  withGoogleMap
)(MapComponent)
