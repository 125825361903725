import React, { useState, Component } from 'react'
import { Hidden } from '@material-ui/core'
import { CheckRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@material-ui/icons'
import Slider from 'react-slick'

import { image } from '../../../lib/image'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const colors = ['#F88247', '#FDC455', '#2ABFF3', '#0070D7', '#0A2540', '#6A6A6A']
const borderColors = ['#F07132', '#E3A835', '#1CABE2', '#0062BD', '#010D17', '#5F5D5D']
const pricesList = ['£59', '£59', '£95', '£215', '£215', '£145']
const matrix = [
  {
    name: 'Exterior wash',
    services: [true, true, true, true, true, false],
    colors: [true, true, true, true, true, true]
  },
  {
    name: 'Wheels cleaned & tyres dressed',
    services: [true, true, true, true, true, false],
    colors: [true, true, true, true, true, true]
  },
  {
    name: 'Basic body wax',
    services: [true, false, true, false, false, false],
    colors: [true, true, true, true, true, true]
  },
  {
    name: 'Door shuts, door cards, centre console & dash wiped',
    services: [false, true, true, true, true, false],
    colors: [false, true, true, true, true, true]
  },
  {
    name: 'Seats, carpet, mats & boot vacuumed',
    services: [false, true, true, true, true, true],
    colors: [false, true, true, true, true, true]
  },
  {
    name: 'Screenwash refill, oil & fluid check',
    services: [false, false, true, true, true, false],
    colors: [false, false, true, true, true, true]
  },
  {
    name: 'Boot & bonnet gutters cleaned',
    services: [false, false, true, true, true, false],
    colors: [false, false, true, true, true, true]
  },
  {
    name: 'Interior windows cleaned',
    services: [false, false, true, true, true, true],
    colors: [false, false, true, true, true, true]
  },
  {
    name: 'Upholstery shampooed or leather cleaned & conditioned',
    services: [false, false, false, true, true, true],
    colors: [false, false, false, true, true, true]
  },
  {
    name: 'Carpet & mats shampooed',
    services: [false, false, false, true, true, true],
    colors: [false, false, false, true, true, true]
  },
  {
    name: 'Pet hair removal',
    services: [false, false, false, true, false, false],
    colors: [false, false, false, true, true, true]
  },
  {
    name: 'Spare wheel area vacuumed',
    services: [false, false, false, false, true, true],
    colors: [false, false, false, false, true, true]
  },
  {
    name: 'Engine bay cleaned',
    services: [false, false, false, false, true, false],
    colors: [false, false, false, false, true, false]
  },
  {
    name: 'HD Carnauba Wax Coating or Protective Paint Sealant applied (choice)',
    services: [false, false, false, false, true, false],
    colors: [false, false, false, false, true, false]
  }
]

function PrevArrow(props) {
  const { className, style, onClick } = props

  return (
    <div className={className} style={style} onClick={onClick}>
      <KeyboardArrowLeftRounded className="services-plans-slider-arrow" />
    </div>
  )
}

function NextArrow(props) {
  const { className, style, onClick } = props

  return (
    <div className={className} style={style} onClick={onClick}>
      <KeyboardArrowRightRounded className="services-plans-slider-arrow" />
    </div>
  )
}

class MobileServicesPlansSlider extends Component {
  state = {
    titles: ['Exterior Wash & Wax', 'Mini Valet', 'Mini Valet PLUS', 'Dog Owners Valet', 'Full Valet', 'Interior Only Valet'],

    slideItemsLists: matrix.reduce((lists, row) => {
      const extraName = row.name

      row.services.reduce((_, isChecked, i) => {
        if (isChecked) {
          lists[i] = !lists[i] ? [extraName] : [...lists[i], extraName]
        }
      }, 0)

      return lists
    }, []),

    settings: {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: current => this.setState({ activeSlide: current }),
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />
    },

    activeSlide: 0
  }

  render() {
    const { settings, activeSlide, slideItemsLists, titles } = this.state

    return (
      <div className="services-plans-slider">
        <h1 className="services-plans-slider-title">Our services</h1>
        <div className="services-plans-slider-note">
          Please note. Prices quoted
          <br />
          are for cars with up to 5 seats
        </div>
        <Slider {...settings}>
          {titles.map((title, i) => (
            <div key={i} className="services-plans-slide">
              <div className="services-plans-slide-inner">
                <div className="plans-slide-title" style={{ background: colors[i] }}>
                  {title}
                </div>
                {i === 1 && <div className="plans-slide-subtitle">Perfect as a regular Maintenance service</div>}
                {i === 2 && <div className="plans-slide-subtitle">Ideal for a more intense clean</div>}
                <ul className="plans-slide-items">
                  {i === 4 && <li key="plus">All services from Mini Valet PLUS</li>}
                  {i === 4 && slideItemsLists[i].slice(7).map((item, index) => <li key={index}>{item}</li>)}
                  {i !== 4 &&
                    slideItemsLists[i].map((item, index) => {
                      let text = item
                      if (item === 'Seats, carpet, mats & boot vacuumed') {
                        if (i === 1) text = 'Seats, carpet, mats & boot light vacuumed'
                        if (i === 2) text = 'Seats, carpet, mats & boot intense vacuumed'
                      }

                      return <li key={index}>{text}</li>
                    })}
                </ul>
                <div className="services-plans-slide-select">
                  <div className="services-plans-slide-select-price">{pricesList[i]}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="services-plans-slider-current">{activeSlide + 1} / 6</div>
      </div>
    )
  }
}

/* Desktop services plans components */

const PlansNames = () => {
  const data = [
    { backgroundImg: './services_orange.svg', title: 'Exterior Wash & Wax', time: '~ 30 min' },
    { backgroundImg: './services_yellow_popular.svg', title: 'Mini Valet', subtitle: 'Perfect as a regular maintenance', time: '1-2hr' },
    { backgroundImg: './services_blue_light_popular.svg', title: 'Mini Valet PLUS', subtitle: 'Ideal for a more intense clean', time: '15-30 min' },
    { backgroundImg: './services_blue.svg', title: 'Dog Owners Valet', time: '15-30 min' },
    { backgroundImg: './services_blue_dark.svg', title: 'Full Valet', time: 'est. 2hr' },
    { backgroundImg: './services_gray.svg', title: 'Interior Only Valet', time: '15-30 min' }
  ]

  return (
    <tr>
      <th></th>
      {data.map(({ backgroundImg, title, subtitle, time }, index) => (
        <th key={index} className="services-plans-name" style={{ background: colors[index] }}>
          <div
            className="services-plans-name-content"
            style={{
              backgroundImage: `url('${image(backgroundImg)}')`,
              backgroundSize: '100% 80%',
              backgroundRepeat: 'no-repeat'
            }}>
            <h3 className="services-plans-name-title">{title}</h3>
            {subtitle && (
              <div className="services-plans-name-subtitle">
                <p className="services-plans-name-subtitle-text">{subtitle}</p>
              </div>
            )}
            {/* <div className="services-plans-name-time">
                                <WatchLater className="services-plans-watch" /> 
                                <p className="services-plans-name-time-text">{time}</p>
                            </div> */}
          </div>
        </th>
      ))}
    </tr>
  )
}

const PlansPrices = () => {
  return (
    <tr>
      <td key="name" className="services-plans-price" style={{ background: 'white' }}>
        Whats included
      </td>
      {pricesList.map((val, index) => (
        <td key={index} className="services-plans-price" style={{ background: colors[index], borderColor: borderColors[index] }}>
          {val}
        </td>
      ))}
    </tr>
  )
}

const PlansInServices = ({ collapsed }) => {
  const showedData = collapsed ? matrix.slice(0, 7) : matrix

  return (
    <>
      {showedData.map((row, index) => (
        <tr key={index}>
          <td key="name">{row.name}</td>
          {row.services.map((isChecked, i) => (
            <td
              key={i}
              style={{
                background: row.colors[i] ? colors[i] : index % 2 ? 'white' : '#f8f8f8',
                ...(row.colors[i] ? { borderColor: borderColors[i] } : { borderColor: '#E4E4E4' })
              }}>
              {isChecked ? <CheckRounded className="services-plans-check" fontSize="large" /> : ''}
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

export const ServicesPlans = () => {
  const [showMore, toggleMore] = useState(false)

  return (
    <section className="services-plans">
      <Hidden mdUp>
        <MobileServicesPlansSlider />
      </Hidden>
      <Hidden smDown>
        <div className="services-plans-desktop-content">
          <table className="services-plans-table">
            <tbody>
              <PlansNames />
              <PlansPrices />
              <PlansInServices collapsed={!showMore} />
            </tbody>
          </table>

          <div className="services-plans-show" onClick={() => toggleMore(!showMore)}>
            {!showMore ? (
              <>
                Show more <KeyboardArrowDownRounded className="services-plans-more" />
              </>
            ) : (
              <>
                Hide <KeyboardArrowUpRounded className="services-plans-more" />
              </>
            )}
          </div>
        </div>
      </Hidden>
    </section>
  )
}
