import { actions } from '../../../services/analytics'

export const STEPS = {
  ADDRESS: 'address',
  SERVICE: 'service',
  EXTRA: 'extra',
  SIGN_IN: 'sign_in',
  CHECKOUT: 'checkout',
  SECCESS: 'success',
  FINISH: 'finish'
}

export const stepNumber = {
  [STEPS.ADDRESS]: 0,
  [STEPS.SERVICE]: 1,
  [STEPS.EXTRA]: 2,
  [STEPS.SIGN_IN]: 3,
  [STEPS.CHECKOUT]: 3
}

export const BOOKING_ROUTES = {
  [STEPS.ADDRESS]: '/booking/address',
  [STEPS.SERVICE]: '/booking/service',
  [STEPS.EXTRA]: '/booking/extra',
  [STEPS.SIGN_IN]: '/booking/sign_in',
  [STEPS.CHECKOUT]: '/booking/checkout',
  [STEPS.FINISH]: '/booking/finish',
  [STEPS.SECCESS]: '/booking/success'
}

export const nextStep = {
  [STEPS.ADDRESS]: STEPS.SERVICE,
  [STEPS.SERVICE]: STEPS.EXTRA,
  [STEPS.EXTRA]: STEPS.SIGN_IN,
  [STEPS.SIGN_IN]: STEPS.CHECKOUT,
  [STEPS.CHECKOUT]: STEPS.FINISH
}

export const prevStep = {
  [STEPS.ADDRESS]: STEPS.ADDRESS,
  [STEPS.SERVICE]: STEPS.ADDRESS,
  [STEPS.EXTRA]: STEPS.SERVICE,
  [STEPS.SIGN_IN]: STEPS.EXTRA,
  [STEPS.CHECKOUT]: STEPS.EXTRA
}

export const ANALYTICS_ACTIONS = {
  [STEPS.ADDRESS]: actions.bookingStep1,
  [STEPS.SERVICE]: actions.bookingStep2,
  [STEPS.EXTRA]: actions.bookingStep3,
  [STEPS.SIGN_IN]: actions.bookingStepLogin,
  [STEPS.CHECKOUT]: actions.bookingStep4,
  [STEPS.FINISH]: actions.placeOrder,
  [STEPS.SECCESS]: actions.placeOrder
}

export const ANALYTICS_STEPS = {
  [STEPS.ADDRESS]: 1,
  [STEPS.SERVICE]: 2,
  [STEPS.EXTRA]: 3,
  [STEPS.SIGN_IN]: 4,
  [STEPS.CHECKOUT]: 5,
  [STEPS.SECCESS]: 6,
  [STEPS.FINISH]: 6
}
