import React from 'react'
import { Helmet } from 'react-helmet'
import { Paper } from '@material-ui/core'

import SignInOverflow from '../components/sign-in/SignInOverflow'

export const SignInPage = React.memo(() => {
  return (
    <Paper>
      <Helmet title="Sign In" />
      <SignInOverflow />
    </Paper>
  )
})
