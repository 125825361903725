import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Link, Typography } from '@material-ui/core'

import { styles } from './styles'

const CheckoutCancelingComponent = React.memo(() => {
  const classes = makeStyles(styles)()

  const handleChatClick = useCallback(e => {
    e.preventDefault()
    window?.Intercom('show')
  }, [])

  return (
    <div className={classes.container}>
      <Typography className={classes.heading}>YOUR ORDER AND PAYMENT HAS BEEN CANCELLED!</Typography>
      <Divider />
      <Typography className={classes.text2}>ps. we are very sorry that you were unable to pay for your order</Typography>

      <Typography className={classes.text}>
        Should you wish to amend your order please use{' '}
        <Link href="/" color="secondary" onClick={handleChatClick}>
          chat support
        </Link>
      </Typography>
      <Typography className={classes.text}>
        Follow us on{' '}
        <Link href="https://www.facebook.com/gowashmycar?ref=hl" target="_blank" rel="noopener noreferrer" color="secondary">
          facebook
        </Link>{' '}
        or{' '}
        <Link href="https://twitter.com/gowashmycar" color="secondary">
          twitter
        </Link>
      </Typography>
      <Typography className={classes.text}>
        <NavLink to="/">Return to the homepage</NavLink>
      </Typography>
    </div>

  )
})

export const CheckoutCancel = React.memo(() => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <CheckoutCancelingComponent/>
  )
})
