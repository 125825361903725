import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Step, Stepper, StepLabel } from '@material-ui/core'

import { image } from '../../../../lib/image'

import { STEPS, stepNumber } from '../steps'
import { styles } from './styles'

const steps = ['Details', 'Services', 'Extras', 'Checkout']

export const BookingStepper = React.memo(({ logo }) => {
  const classes = makeStyles(styles)()
  const step = useRouteMatch('/booking/:step')?.params?.step || STEPS.ADDRESS

  return (
    <div>
      <div className={classes.leftSide}>
        {logo && (
          <Box className={classes.logo}>
            <NavLink to="/">
              <img width="180px" height="41px" src={image('./logo.png')} alt="Gowashmycar logo" />
            </NavLink>
          </Box>
        )}
      </div>
      <div className={classes.container}>
        <Stepper activeStep={stepNumber[step]} className={classes.stepper} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  )
})
