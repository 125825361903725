import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, List, ListItem, ListItemText, IconButton } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { styles } from './styles'
import { useSelectors } from '../../../../store'

const promoCodeTypes = {
  'PercentPromoCode::BaseService': 'Base Service ',
  'PercentPromoCode::ExtraService': 'Extra Service ',
  'PercentPromoCode::OverallService': ''
}

export const UserPromoCode = React.memo(() => {
  const classes = makeStyles(styles)()
  const { promoCode, promoCodeValue } = useSelectors()
  const { type } = promoCode || {}

  return (
    promoCode && (
      <Paper className={classes.container}>
        <List className={classes.list}>
          <ListItem className={classes.item}>
            <ListItemText
              className={classes.itemText}
              primary={`${promoCodeValue}% ${promoCodeTypes[type] || ''}promo code will be applied for your next order`}
            />
            <IconButton edge="end" size="small">
              <MoreVert />
            </IconButton>
          </ListItem>
        </List>
      </Paper>
    )
  )
})
