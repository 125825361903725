export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 480,
    backgroundColor: 'white',
    margin: '16px auto'
    // margin: theme.spacing(2, 1, 1, 1),
    // '@media (min-width: 960px)': {
    //   margin: '16px auto'
    // }
  },

  summary: {
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(1)
  },

  mobile: {
    padding: '10px 15px 0px 15px',
    border: '1px solid #c7c7c7',
    borderRadius: '5px',
    backgroundColor: '#fff',
    margin: theme.spacing(0, 1, 4, 1)
  },

  card: {
    margin: theme.spacing(0, 1, 2, 1),
    boxShadow: 'none',
    border: '1px solid #ddd'
  },

  cardHeaderRoot: {
    padding: theme.spacing(1),
    backgroundColor: '#f5f5f5'
  },

  cardHeaderTitle: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#555'
  },

  cardContentRoot: {
    padding: theme.spacing(2, 1, 1, 1)
  },

  textFieldContainer: {
    width: '100%'
  },

  stripeTerms: {
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(1)
  },

  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 1)
  },

  prevButton: {
    color: '#555'
  },

  poweredByStripe: {},

  button: {
    margin: theme.spacing(0),
    position: 'relative',
    fontSize: '1.125rem'
  },

  signInButton: {
    margin: 'auto'
  },

  buttonProgress: {
    color: '#37b45a',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },

  snackbar: {
    backgroundColor: theme.palette.error.dark
  },

  summaryContainer: {
    margin: theme.spacing(0),
    padding: theme.spacing(0)
  },

  headingContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },

  heading: {
    display: 'flex',
    justifyContent: 'center',
    color: '#555',
    fontWeight: 'bold'
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    color: '#555',
    borderTop: '1px solid #dddddd'
  },

  rowRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5, 1, 0.5, 1),
    color: '#555',
    borderTop: '1px solid #dddddd'
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0)
  },

  firstLine: {
    // textDecoration: 'underline'
  },

  secondLine: {
    textAlign: 'right',
    paddingLeft: theme.spacing(2)
  },

  rowTotal: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    borderTop: '1px solid #dddddd',
    color: '#555'
  },

  rowTotalText: {
    fontSize: '1.125rem',
    fontWeight: 'bold'
  },

  stripeContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    maxWidth: 480
  },

  stripe: {},

  stripeBadge: {
    height: '26px'
  },

  termsContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },

  terms: {
    fontSize: '0.875rem'
  },

  input: {
    marginRight: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: '1px solid #ddd',
    borderRadius: theme.spacing(0.5),
    fontSize: '0.875rem'
  },

  promoCodeButton: {
    padding: theme.spacing(0.5, 4, 0.5, 4),
    textTransform: 'none'
  },

  promoCodeButtonText: {
    textTransform: 'none'
  },

  tipButton: {
    padding: theme.spacing(0.5, 0, 0.5, 0)
  },

  tipButtonText: {
    textTransform: 'none'
  },

  removeTipButton: {
    marginLeft: theme.spacing(1),
    padding: 0
  }
})
