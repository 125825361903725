export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '420px',
    backgroundColor: 'white',
    margin: '32px auto 64px auto',
    padding: theme.spacing(1),
    '@media (min-width: 960px)': {
      maxWidth: '640px'
    }
  },

  heading: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
    color: '#555',
    fontSize: '2rem',
    fontWeight: 'bold'
  },

  text: {
    fontSize: '1.2rem',
    marginTop: '24px'
  },

  text2: {
    marginTop: '24px',
    fontSize: '0.875rem'
  }
})
