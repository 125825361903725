import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Box, Toolbar, Hidden } from '@material-ui/core'
import clsx from 'clsx'

import { HeaderMenu } from './menu'
import { HeaderAccount } from './account'
import { image } from '../../lib/image'
import { styles } from './styles'

export const Header = React.memo(() => {
  const classes = makeStyles(styles)()

  const isServicesPage = useRouteMatch('/services')
  // const isDesktop = useMediaQuery('(min-width:960px)')
  const isBookingFinishPage = !!useRouteMatch('/booking/finish')
  const isBookingPage = !!useRouteMatch('/booking/:step') && !isBookingFinishPage

  if (isBookingPage) return null

  return (
    <AppBar position="static" color="default" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <Hidden smDown>
          <Box className={classes.toolbarLeft}>
            <NavLink to="/">
              <img width="180px" height="41px" src={image('./logo.png')} alt="Gowashmycar logo" />
            </NavLink>
          </Box>
          <Box className={classes.toolbarRight}>
            <Box className={isServicesPage ? classes.toolbarItemWrapperActive : classes.toolbarItemWrapper}>
              <NavLink to="/services" className={classes.toolbarItem}>
                Services &amp; Pricing
              </NavLink>
            </Box>
            <Box className={classes.toolbarItemWrapper}>
              <a href="https://gowashmycar.typeform.com/to/oPLq2u" className={classes.toolbarItem}>
                Corporate
              </a>
            </Box>
            <Box className={clsx(classes.toolbarItemWrapper, classes.volvo)}>
              <a href="https://www.gowashmycar.com/volvocars" className={classes.toolbarItem}>
                Volvo
              </a>
            </Box>
            <HeaderAccount />
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.toolbarCenter}>
            <Box className={classes.toolbarLeft}>
              <NavLink to="/">
                <img width="180px" height="41px" src={image('./logo.png')} alt="Gowashmycar logo" />
              </NavLink>
            </Box>
          </Box>
          <HeaderMenu />
        </Hidden>
      </Toolbar>
    </AppBar>
  )
})
