export const styles = theme => ({
  error: {
    position: 'absolute',
    bottom: -10,
    right: theme.spacing(1.5),
    backgroundColor: 'white',
    color: 'red',
    fontSize: '0.75rem',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  congestion: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  congestionRoot: {
    marginRight: '0px'
  },
  congestionLabel: {
    fontSize: '0.75rem'
  },
  legend: {
    margin: '0px 20px 20px 20px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    '@media (min-width: 960px)': {
      margin: '0px 35px 20px 35px'
    }
  }
})
