import React from 'react'
import { image } from '../../lib/image'

export const PageHeader = React.memo(({ title }) => (
  <section className="small-description-hero">
    <div className="description-left"></div>
    <div className="small-description-center">
      <h1 className="small-description-h1">{title}</h1>
      <img className="small-description-img" src={image('./description_hero.jpg')} alt="background" />
    </div>
    <div className="description-right"></div>
  </section>
))
