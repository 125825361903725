import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../../components/page-header'

export const FAQsPage = React.memo(() => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="home">
      <Helmet title="FAQs" />
      <PageHeader title="FAQs" />
      <div className="faqs">
        <h3>What areas does GoWashMyCar cover?</h3>
        <p>GoWashMyCar currently services the Greater London area.</p>
        <h3>How do I book?</h3>
        <p></p>
        <ul className="no-margin">
          <li>Locate your car by searching a postcode or address - you can drag and adjust the map to fix the pin to the exact location of the car</li>
          <li>Enter the Make &amp; Model of the car, Registration and Colour</li>
          <li>Please specify in the Notes section anything we need to know (eg. access restrictions, bird droppings, how to contact you etc.)</li>
          <li>Select the Car Size and the Service which you require</li>
          <li>Choose the Date and Time Slot</li>
          <li>Click Next to Sign in or Register a new account</li>
          <li>At Payment check the summary of your order is correct - you are welcome to tip your operator (we pass the whole tip on to the operator)</li>
          <li>Enter your card details, please note GoWashMyCar will only charge your card after order has been accepted by the operator.</li>
        </ul>
        <p>
          <strong>PLEASE NOTE: </strong>
          We use the leading online payments provider Stripe.com to store all card details and handle transactions - we do not store any personal card details.
          <br />
          Please carefully read the Terms and Conditions before placing your order.
        </p>
        <h3>How much will it cost?</h3>
        <p>Prices are found on the main booking page and in the Pricing tab on the website.</p>
        <h3>Does GoWashMyCar provide Detailing Services? (Paint restoration, concrete and tar removal etc)</h3>
        <p>Yes we do! In most cases, detailing will be a bespoke service so please drop us a line and we can discuss your requirements and quote accordingly</p>
        <h3>What are your hours of operation?</h3>
        <p>
          The booking can be made at anytime, You can book in advance from Monday to Sunday from 7am to 8pm (Summer) and 8am to 5pm (Winter) - or until
          nightfall.
        </p>
        <h3>What if I need to cancel or reschedule my booking?</h3>
        <p>
          You are welcome to cancel or change your order up to 2 hours before the start of the booking window. After that, you will be liable for 50% of the
          order fee. Should you need to change the time of your booking or cancel the booking you may contact us on the live chat on the website, (bottom right
          corner of the web page), or call us on 0203 389 8680 or email us at: support@gowashmycar.com. During the hours of operation we will be on hand to help
          facilitate any changes. Please refer to the cancellation policy for more details.
        </p>
        <p>
          <strong>Waiting times:</strong>
          If the operator arrives to the job and the car is not present at the booked location, or the customer is not present, there will be a £0.50 per minute
          charge added starting from 5 minutes past the end of the booking window. Maximum waiting time is 30 minutes.
        </p>
        <p>
          <strong>Car location:</strong>
          When the car is located in an underground car park - please check the location allows car washing services to take place.
          <br />
          When the car is located in the street - the operator&apos;s van needs to be able to park within 10 meters of the car location, please be mindful when
          parking your vehicle so as to allow access.
          <br />
          <br />
          GoWashMyCar reserve the right to charge the full amount should they arrive and the service is not permitted to take place.
        </p>
        <h3>Do I need to be present?</h3>
        <p>
          No! For the exterior wash services, our Operators come straight to your car, so you can relax and enjoy your day.
          <br />
          For interior wash services, the Operator will contact you to get access to the car.
        </p>
        <h3>When is my payment processed?</h3>
        <p>
          Your card is charged at the time an operator accepts your order. You will know this via a confirmation email sent to you. Your details are held
          securely and encrypted with Stripe.com.
        </p>
        <h3>Where does my car have to be?</h3>
        <p>Anywhere that is publicly accessible. Please note if the car is not accessible by our Operators, they will kindly ask you to relocate the car.</p>
        <h3>Who will turn up and how am I assured of the quality of the service?</h3>
        <p>All our Operators are fully vetted by us and are experienced within the car wash industry.</p>
        <h3>Who will turn up and will they be any good?</h3>
        <p>All our Car Wash Pros are fully vetted by us and are experienced within the car wash industry.</p>
        <h3>How will I know when my car has been washed?</h3>
        <p>You will receive a confirmation email once your car has been washed along with a picture of it looking nice and clean.</p>
        <h3>Can I have a receipt so I can expense the wash.</h3>
        <p>Yes, one is automatically sent to you in a separate email after the completion of your car wash.</p>
        <h3>Bad Weather</h3>
        <p>
          If you would like to cancel or reschedule your appointment due to bad weather please contact your Operator as soon as possible, they will be happy to
          reschedule your order.
        </p>
        <h3>Child seats and other detachable items:</h3>
        <p>
          If you require the area under detachable items to be cleaned please remove these items prior to the wash taking place. Please note the operator will
          not remove or re-attach any of these items.
        </p>
        <h3>Health Check</h3>
        <p>
          Health Check includes Screenwash refill; check the Tyre pressure &amp; depth, Coolant level and visual car Body check. Please note, this product is
          only available to Volvo owners, who have downloaded Volvo Car Mobile Wash App and made the booking through the App. For more information, please
          visit:&emsp;
          <a href="/volvocars">www.gowashmycar.com/volvocars</a>
          <br />
          This product will be offered to all customers in the near future.
        </p>
        <h3>Remote Unlock/Lock</h3>
        <p>
          Please note GoWashMyCar is not responsible for any loss or damage to the vehicle or any items inside the car while the customer provided access to the
          car via a remote lock and unlock mechanism, a Third Party App or the GoWashMyCar App.
        </p>
        <p>
          In cases when GoWashMyCar operator cannot contact the client to lock the car remotely, waiting time will be charged at £60 per hour. Please note
          operator will remain at the location for a maximum of 2 hours. It is customer&apos;s responsibility to respond to the company in a timely manner.
        </p>
      </div>
    </div>
  )
})
