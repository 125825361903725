import { useContext, useMemo } from 'react'

import { context } from '../../context'
import { GBP } from '../../../lib/gbp'
import { LARGE_CAR_SERVICE, SAME_DAY_SERVICE, CONGESTION_ZONE_SERVICE, GROPUS } from '../../constants'

export const selectedServiceIds = state =>
  Object.entries(state.services)
    .filter(([_, value]) => value)
    .map(([key, _]) => key)

export const selectedServices = state => selectedServiceIds(state).map(serviceId => state.prices.byId[serviceId])

export const useSelectors = () => {
  const { state } = useContext(context)

  const step = useMemo(() => state.step, [state.step])

  const servicesAvailability = useMemo(() => state.servicesAvailability, [state.servicesAvailability])
  const services = useMemo(() => state.services, [state.services])

  const largeCar = useMemo(() => services[LARGE_CAR_SERVICE.id], [services])
  const sameDay = useMemo(() => services[SAME_DAY_SERVICE.id], [services])
  const congestionZone = useMemo(() => services[CONGESTION_ZONE_SERVICE.id], [services])

  const address = useMemo(() => state.address, [state.address])
  const geoLocation = useMemo(() => state.geoLocation, [state.geoLocation])
  const zoom = useMemo(() => state.zoom, [state.zoom])
  const date = useMemo(() => state.date, [state.date])
  const timeSlot = useMemo(() => state.timeSlot, [state.timeSlot])
  const homeAddress = useMemo(() => state.homeAddress, [state.homeAddress])
  const carModel = useMemo(() => state.carModel, [state.carModel])
  const carRegistration = useMemo(() => state.carRegistration, [state.carRegistration])
  const carColor = useMemo(() => state.carColor, [state.carColor])
  const notes = useMemo(() => state.notes, [state.notes])
  const agentTip = useMemo(() => state.agentTip, [state.agentTip])
  const prices = useMemo(() => state.prices, [state.prices])
  const message = useMemo(() => state.message, [state.message])
  const messageTimeout = useMemo(() => state.messageTimeout, [state.messageTimeout])
  const messagePosition = useMemo(() => state.messagePosition, [state.messagePosition])
  const messageColor = useMemo(() => state.messageColor, [state.messageColor])
  const promoCode = useMemo(() => state.promoCode, [state.promoCode])
  const promoCodeValue = useMemo(() => (promoCode ? promoCode.value || 0 : 0), [promoCode])
  const promoCodeInfo = useMemo(() => state.promoCodeInfo, [state.promoCodeInfo])

  const isSignIn = useMemo(() => state.isSignIn, [state.isSignIn])
  const isCardComplete = useMemo(() => state.isCardComplete, [state.isCardComplete])
  const isProcessingStripe = useMemo(() => state.isProcessingStripe, [state.isProcessingStripe])
  const isStripeError = useMemo(() => state.isStripeError, [state.isStripeError])
  const stripeError = useMemo(() => state.stripeError, [state.stripeError])
  const isTermsAccepted = useMemo(() => state.isTermsAccepted, [state.isTermsAccepted])

  const user = useMemo(() => state.user, [state.user])
  const isUserSignedIn = useMemo(() => user && user.token, [user])
  const userName = useMemo(() => (isUserSignedIn ? `${user.first_name || 'first_name'} ${user.last_name || 'first_name'}` : ''), [user, isUserSignedIn])
  const userCredit = useMemo(() => (isUserSignedIn ? user.credit || '0' : ''), [user, isUserSignedIn])
  const userCreditCents = useMemo(() => {
    const { type, value = 0 } = promoCodeInfo || {}
    const { credit_cents = 0 } = user || {}

    return isUserSignedIn ? credit_cents : /^CreditPromoCode/.test(type) ? value * 100 : 0
  }, [user, isUserSignedIn, promoCodeInfo])

  const selectedServiceIds = useMemo(
    () =>
      Object.entries(services)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
    [services]
  )

  const selectedServices = useMemo(() => selectedServiceIds.map(serviceId => prices.byId[serviceId]), [prices.byId, selectedServiceIds])

  const selectedBaseServiceId = useMemo(() => selectedServiceIds.filter(id => prices.byId[id]?.group === GROPUS.BASE)?.[0] || prices.ids[0], [
    prices,
    selectedServiceIds
  ])

  const selectedPopularServicesIds = useMemo(() => selectedServiceIds.filter(id => prices.byId[id]?.group === GROPUS.POPULAR), [
    prices.byId,
    selectedServiceIds
  ])

  const selectedExtrasServicesIds = useMemo(() => selectedServiceIds.filter(id => prices.byId[id]?.group === GROPUS.EXTRAS), [prices.byId, selectedServiceIds])

  const selectedDetailingServicesIds = useMemo(() => selectedServiceIds.filter(id => prices.byId[id]?.group === GROPUS.DETAILING), [
    prices.byId,
    selectedServiceIds
  ])

  const selectedSurchargeServiceIds = useMemo(() => selectedServiceIds.filter(id => prices.byId[id]?.group === GROPUS.SURCHARGE), [
    prices.byId,
    selectedServiceIds
  ])

  const baseService = useMemo(() => prices.byId[selectedBaseServiceId], [prices.byId, selectedBaseServiceId])
  const sameDayServiceId = useMemo(() => prices.ids.find(id => prices.byId[id].name === 'Extra for same day'), [prices.byId, prices.ids])
  const isSameDaySelected = useMemo(() => !!services[sameDayServiceId], [sameDayServiceId, services])

  const allSelectedExtraServicesIds = [...selectedPopularServicesIds, ...selectedExtrasServicesIds, ...selectedDetailingServicesIds]

  const sortedSelectedServiceIds = useMemo(
    () => [selectedBaseServiceId, ...selectedPopularServicesIds, ...selectedExtrasServicesIds, ...selectedDetailingServicesIds, ...selectedSurchargeServiceIds],
    [selectedBaseServiceId, selectedDetailingServicesIds, selectedExtrasServicesIds, selectedPopularServicesIds, selectedSurchargeServiceIds]
  )

  const extraServicesCount = useMemo(() => allSelectedExtraServicesIds.length + selectedSurchargeServiceIds.length, [
    allSelectedExtraServicesIds.length,
    selectedSurchargeServiceIds.length
  ])

  const baseServicesPrice = useMemo(() => baseService?.price || GBP(0), [baseService])

  const extraServicesPrice = useMemo(() => allSelectedExtraServicesIds.reduce((acc, id) => acc.add(prices.byId[id].price), GBP(0)), [
    allSelectedExtraServicesIds,
    prices.byId
  ])

  const servicesPrice = useMemo(() => selectedServiceIds.reduce((acc, id) => acc.add(prices.byId[id]?.price || 0), GBP(0)), [selectedServiceIds, prices.byId])

  const agentTipPrice = useMemo(() => servicesPrice.multiply(0.1), [servicesPrice])

  const percentDiscountPrice = useMemo(() => {
    if (!promoCode && !promoCodeInfo) return GBP(0)

    const { type, value } = promoCodeInfo || promoCode

    switch (type) {
      case 'PercentPromoCode::BaseService':
        return baseServicesPrice.multiply(value).divide(100)
      case 'PercentPromoCode::BaseService::ExteriorWash':
        return baseService?.name == 'Exterior Wash' ? baseServicesPrice.multiply(value).divide(100) : GBP(0)
      case 'PercentPromoCode::BaseService::BasicValet':
        return baseService?.name == 'Basic Valet' ? baseServicesPrice.multiply(value).divide(100) : GBP(0)
      case 'PercentPromoCode::BaseService::MiniValet':
        return baseService?.name == 'Mini Valet' ? baseServicesPrice.multiply(value).divide(100) : GBP(0)
      case 'PercentPromoCode::BaseService::FullValet':
        return baseService?.name == 'Full Valet' ? baseServicesPrice.multiply(value).divide(100) : GBP(0)

      case 'PercentPromoCode::ExtraService':
        return extraServicesPrice.multiply(value).divide(100)

      case 'PercentPromoCode::OverallService':
        // eslint-disable-next-line prettier/prettier
        return servicesPrice.multiply(value).divide(100)
      default:
        return GBP(0)
    }
  }, [promoCode, baseServicesPrice, extraServicesPrice, servicesPrice, promoCodeInfo])

  const creditDiscountPrice = useMemo(() => {
    const priceWithPercentDiscount = servicesPrice.add(agentTipPrice).subtract(percentDiscountPrice).intValue
    const min = Math.min(priceWithPercentDiscount, Math.max(userCreditCents, 0))

    return GBP(min).divide(100)
  }, [userCreditCents, servicesPrice, agentTipPrice, percentDiscountPrice])

  const discountPrice = useMemo(() => percentDiscountPrice.add(creditDiscountPrice), [percentDiscountPrice, creditDiscountPrice])

  const price = useMemo(() => (agentTip ? servicesPrice.add(agentTipPrice).subtract(discountPrice) : servicesPrice.subtract(discountPrice)), [
    agentTip,
    agentTipPrice,
    servicesPrice,
    discountPrice
  ])

  const orders = useMemo(() => state.orders, [state.orders])
  const availableOrders = useMemo(() => state.orders.filter(order => order.status === 'available'), [state.orders])
  const allocatedOrders = useMemo(() => state.orders.filter(order => order.status === 'allocated'), [state.orders])
  const completedOrders = useMemo(() => state.orders.filter(order => order.status === 'completed'), [state.orders])

  const config = useMemo(() => state.config, [state.config])

  return {
    step,
    servicesAvailability,
    services,
    largeCar,
    sameDay,
    congestionZone,
    baseService,
    extraServicesCount,
    address,
    homeAddress,
    geoLocation,
    zoom,
    date,
    timeSlot,
    carModel,
    carRegistration,
    carColor,
    notes,
    agentTip,
    selectedServices,
    selectedServiceIds,
    sortedSelectedServiceIds,
    servicesPrice,
    agentTipPrice,
    prices,
    percentDiscountPrice,
    creditDiscountPrice,
    price,
    isSameDaySelected,
    isCardComplete,
    isProcessingStripe,
    isStripeError,
    stripeError,
    isTermsAccepted,
    isSignIn,
    user,
    isUserSignedIn,
    userName,
    userCredit,
    userCreditCents,
    message,
    messageTimeout,
    messagePosition,
    messageColor,
    orders,
    availableOrders,
    allocatedOrders,
    completedOrders,
    promoCode,
    promoCodeValue,
    promoCodeInfo,
    config
  }
}
