import { useCallback, useContext } from 'react'
import { format } from 'date-fns'

import {
  setOrders,
  apiOrdersFetchAllStart,
  apiOrdersFetchAllSuccess,
  apiOrdersFetchAllFailure,
  apiOrdersCreateStart,
  apiOrdersCreateSuccess,
  apiOrdersCreateFailure
} from '../actions/orders'
import { setIsProcessingStripe, setIsStripeError, setStripeError } from '../actions/booking'
import { context } from '../context'
import { settings } from '../constants'
import { transformOrderKeys } from '../adapters'
import { actions, AnalyticsService, categories } from '../../services/analytics'
import { useSelectors } from './selectors/use-selectors'
import { ANALYTICS_STEPS, STEPS } from '../../pages/booking/components/steps'

export const useOrdersApi = () => {
  const { dispatch } = useContext(context)

  const {
    agentTip,
    address,
    geoLocation,
    date,
    timeSlot,
    homeAddress,
    carModel,
    carColor,
    carRegistration,
    notes,
    selectedServices,
    selectedServiceIds,
    promoCode
  } = useSelectors()

  const apiOrderFetchAll = useCallback(() => {
    const token = window.localStorage.getItem('token')

    if (!token) return

    const options = {
      headers: { 'X-User-Token': token }
    }

    dispatch(apiOrdersFetchAllStart())
    fetch(`${settings.host}/api/v2/orders`, options)
      .then(response => response.json())
      .then((json = {}) => {
        const { status, data: orders } = json

        if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

        dispatch(apiOrdersFetchAllSuccess())
        dispatch(setOrders(transformOrderKeys(orders)))
      })
      .catch(error => dispatch(apiOrdersFetchAllFailure(`apiOrderFetchAll error: ${JSON.stringify(error)}`)))
  }, [dispatch])

  const prepareOrderParams = useCallback(
    order => {
      return JSON.stringify({
        agent_tip: order.agentTip,
        date: format(order.date, 'dd-MM-yyyy'),
        address: order.address,
        home_address: order.homeAddress,
        make_model: order.carModel,
        color: order.carColor,
        registration: order.carRegistration,
        notes: order.notes,
        time_bucket: order.timeSlot,
        lat: order.geoLocation.lat,
        lng: order.geoLocation.lng,
        service_ids: order.selectedServiceIds,
        promo_code: order.promoCode?.code
      });
    }
  )

  const apiOrderCreate = useCallback(
    order => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Token': window.localStorage.getItem('token')
        },
        body: prepareOrderParams(order)
      }

      dispatch(apiOrdersCreateStart())

      return fetch(`${settings.host}/api/v2/orders`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

          AnalyticsService.trackEvent(categories.booking, actions.orderPlaced, 'GWMC Fee', json.data?.net_gwmc_fee)
          AnalyticsService.trackPurchase(json.data, selectedServices, promoCode?.code)
          AnalyticsService.trackCheckoutStep(ANALYTICS_STEPS[STEPS.FINISH])

          dispatch(apiOrdersCreateSuccess())

          return json.data
        })
        .catch(error => {
          dispatch(apiOrdersCreateFailure(`apiOrderCreate error: ${JSON.stringify(error)}`))

          return null
        })
    },
    [dispatch, promoCode, selectedServices]
  )

  const handleProcessCheckout = useCallback(
    (stripe, sessionId) => {

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Token': window.localStorage.getItem('token')
        },
        body: JSON.stringify({session_id: sessionId})
      }

      dispatch(apiOrdersCreateStart())

      return fetch(`${settings.host}/api/v2/orders/process_checkout`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status !== 'ok') throw new Error(`json: ${JSON.stringify(json)}`)

          console.log('track data: ', json?.data, json.data?.track_services, json.data?.promo_code)
          window.track_service = AnalyticsService;

          AnalyticsService.trackEvent(categories.booking, actions.orderPlaced, 'GWMC Fee', json.data?.net_gwmc_fee)
          AnalyticsService.trackPurchase(json?.data, json.data?.track_services, json.data?.promo_code)
          AnalyticsService.trackCheckoutStep(ANALYTICS_STEPS[STEPS.FINISH])

          dispatch(apiOrdersCreateSuccess())

          return json.data
        })
        .catch(error => {
          dispatch(apiOrdersCreateFailure(`apiOrderCreate error: ${JSON.stringify(error)}`))

          return null
        })
    },
    [dispatch, promoCode, selectedServices]
  )

  const handleCheckout = useCallback(
    (stripe, cardElement) => {
      AnalyticsService.trackEvent(categories.booking, actions.clickPlaceOrder)
      AnalyticsService.trackCheckoutStep(ANALYTICS_STEPS[STEPS.CHECKOUT])

      const order = {
        agentTip,
        address,
        geoLocation,
        date,
        timeSlot,
        homeAddress,
        carModel,
        carColor,
        carRegistration,
        notes,
        selectedServiceIds,
        promoCode
      }

      fetch(`${settings.host}/api/v2/orders/checkout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-User-Token': window.localStorage.getItem('token') },
        body: prepareOrderParams(order)
      })
        .then(response => response.json())
        .then(json => {
          if (json.status === 'ok') {
            dispatch(setIsProcessingStripe(false))
            dispatch(setStripeError(''))
            dispatch(setIsStripeError(false))

            stripe.redirectToCheckout({sessionId: json.data?.session_id});
          } else {
            dispatch(setIsProcessingStripe(false))
            dispatch(setStripeError(json?.message))
            dispatch(setIsStripeError(true))
          }
        })
    },
    [address, agentTip, apiOrderCreate, carColor, carModel, carRegistration, date, dispatch, geoLocation, homeAddress, notes, selectedServiceIds, selectedServices, promoCode, timeSlot]
  )

  return {
    apiOrderFetchAll,
    apiOrderCreate,
    handleCheckout,
    handleProcessCheckout
  }
}
