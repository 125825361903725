export const fields = {
  address: 'address',
  homeAddress: 'homeAddress',
  congestionZone: 'congestionZone',
  agentTip: 'agentTip',
  geoLocation: 'geoLocation',
  date: 'date',
  timeSlot: 'timeSlot',
  carModel: 'carModel',
  carColor: 'carColor',
  carSize: 'carSize',
  carRegistration: 'carRegistration',
  notes: 'notes',
  selectedServiceIds: 'selectedServiceIds'
}
