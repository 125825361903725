export const styles = theme => ({
  infoIcon: {
    fontSize: '1.25rem',
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  closeIcon: {
    fontSize: '1.25rem'
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})
