import React, { useContext } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { apiUsersSignInFailure, context, useUsersApi } from '../../../../store'
import { settings } from '../../../../store/constants'
import { image } from '../../../../lib/image'

const CustomButton = ({ onClick, title }) => {
  return (
    <button type="button" className="facebook-login-button" onClick={onClick}>
      <img src={image('./login_facebook.svg')} title="Facebook" alt="Facebook" />
      <div>{title}</div>
    </button>
  )
}

export const FacebookLoginButton = React.memo(props => {
  const { title } = props
  const { dispatch } = useContext(context)
  const { apiUserFacebookLogin } = useUsersApi()

  const handleResponse = response => {
    response.email && apiUserFacebookLogin(response)
  }

  const handleFailure = data => {
    dispatch(apiUsersSignInFailure(`FacebookLogin error: ${JSON.stringify(data)}`))
  }

  return (
    <FacebookLogin
      appId={settings.facebookAppId}
      scope="public_profile, email"
      fields="name,email"
      callback={handleResponse}
      onFailure={handleFailure}
      disableMobileRedirect
      render={fbProps => <CustomButton {...fbProps} title={title} />}
    />
  )
})
