import React from 'react'

import { image } from '../../../lib/image'

export const HowItWorks = () => (
  <section className="how-it-works">
    <div className="how-it-works-providers">
      <img className="how-it-works-provider-img" src={image('./coversure.svg')} alt="Choose service" />
      <img className="how-it-works-provider-img" src={image('./direct_line.png')} alt="Choose service" />
    </div>
    <h1 className="how-it-works-title">Book a car wash in just a few clicks</h1>
    <div className="how-it-works-steps">
      <div className="how-it-works-step">
        <img className="how-it-works-img" src={image('./how_it_works_1_34.svg')} alt="Enter location" />
        <p className="how-it-works-step-title">Enter location</p>
        <p className="how-it-works-step-text">Select your location, date&nbsp;&amp;&nbsp;time</p>
      </div>
      <div className="how-it-works-step">
        <img className="how-it-works-img" src={image('./how_it_works_2_34.svg')} alt="Choose service" />
        <p className="how-it-works-step-title">Choose service</p>
        <p className="how-it-works-step-text">Choose from our selection of base services &amp; extras</p>
      </div>
      <div className="how-it-works-step">
        <img className="how-it-works-img" src={image('./how_it_works_3_34.svg')} alt="Wash" />
        <p className="how-it-works-step-title">Get washing</p>
        <p className="how-it-works-step-text">One of our vetted car wash pro’s will be on the way to wash your car!</p>
      </div>
    </div>
  </section>
)
