import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { Cancel, Close } from '@material-ui/icons'

import { actions, AnalyticsService, categories } from '../../../../services/analytics'
import { settings } from '../../../../store/constants'
import {
  context,
  setAgentTip,
  setIsCardComplete,
  setIsStripeError,
  setIsTermsAccepted,
  useOrdersApi,
  usePromoCodesApi,
  useSelectors,
  useUsersApi
} from '../../../../store'
import { browserHistory } from '../../../../services/history'
import { image } from '../../../../lib/image'

import { styles } from './styles'

const options = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Poppins,"Helvetica Neue",sans-serif'
      // backgroundColor: '#eee',
      // color: '#555'
    }
  }
}

const CheckoutFormComponent = React.memo(() => {
  const classes = makeStyles(styles)()
  const { dispatch } = useContext(context)
  const [showPromoCode, setShowPromoCode] = useState(false)
  const [promoCode, setPromoCode] = useState('')
  const {
    agentTip,
    agentTipPrice,
    percentDiscountPrice,
    creditDiscountPrice,
    prices,
    price,
    sortedSelectedServiceIds,
    isCardComplete,
    isTermsAccepted,
    isProcessingStripe,
    isStripeError,
    stripeError,
    user
  } = useSelectors()

  const [mobile, setMobile] = useState(user?.mobile || '')
  const stripe = useStripe()
  const elements = useElements()
  const { handleCheckout } = useOrdersApi()
  const { apiUserStatus, apiUserUpdate } = useUsersApi()
  const { apiApplyPromoCode } = usePromoCodesApi()

  const handlePromoCode = useCallback(
    event => {
      event.preventDefault()
      setShowPromoCode(false)
      AnalyticsService.trackEvent(categories.booking, actions.applyPromoCode, promoCode)
      apiApplyPromoCode(promoCode)
    },
    [promoCode, apiApplyPromoCode]
  )

  const addTip = useCallback(() => dispatch(setAgentTip(true)), [dispatch])
  const removeTip = useCallback(() => dispatch(setAgentTip(false)), [dispatch])

  const handleToggleTerms = useCallback(() => {
    dispatch(setIsTermsAccepted(!isTermsAccepted))
  }, [dispatch, isTermsAccepted])

  const handleCardComplete = useCallback(
    ({ complete }) => {
      dispatch(setIsCardComplete(complete))
    },
    [dispatch]
  )

  const handleClose = useCallback(() => {
    dispatch(setIsStripeError(false))
  }, [dispatch])

  const checkout = useCallback(() => {
    !user?.mobile && mobile && apiUserUpdate({ mobile })
    const cardElement = elements.getElement(CardElement)
    handleCheckout(stripe, cardElement)
  }, [apiUserUpdate, elements, handleCheckout, mobile, stripe, user])

  const renderSummary = useCallback(() => {
    return (
      <Box className={classes.summary}>
        <Container className={classes.summaryContainer}>
          <Box className={classes.headingContainer}>
            <Typography className={classes.heading}>Order summary</Typography>
          </Box>
          {sortedSelectedServiceIds.map(id => (
            <Box className={classes.row} key={`brief-summary-${id}`}>
              <Typography>{prices.byId[id]?.name}</Typography>
              <Typography>{prices.byId[id]?.price.format()}</Typography>
            </Box>
          ))}
          {agentTip && (
            <Box className={classes.row} key="brief-summary-tip">
              <Typography>
                Agent tip
                <IconButton className={classes.removeTipButton} aria-label="delete" color="secondary" onClick={removeTip}>
                  <Cancel />
                </IconButton>
              </Typography>
              <Typography>{agentTipPrice.format()}</Typography>
            </Box>
          )}
          {percentDiscountPrice.intValue > 0 && (
            <Box className={classes.row} key="brief-summary-discount">
              <Typography>Percent discount</Typography>
              <Typography>–{percentDiscountPrice.format()}</Typography>
            </Box>
          )}
          {creditDiscountPrice.intValue > 0 && (
            <Box className={classes.row} key="brief-summary-credit">
              <Typography>Credit</Typography>
              <Typography>–{creditDiscountPrice.format()}</Typography>
            </Box>
          )}

          {showPromoCode ? (
            <Box className={classes.rowRight} key="brief-summary-promo-code">
              <TextField
                value={promoCode}
                onChange={event => setPromoCode(event.target.value)}
                onBlur={() => setTimeout(() => setShowPromoCode(false), 250)}
                margin="none" // 'none' | 'dense' | 'normal'
                autoComplete="off"
                fullWidth
                id="promo_code"
                placeholder="Promo code"
                name="promo_code"
                autoFocus
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.input
                  },
                  endAdornment: (
                    <Button
                      classes={{
                        root: classes.promoCodeButton,
                        label: classes.promoCodeButtonText
                      }}
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={handlePromoCode}>
                      Apply
                    </Button>
                  )
                }}
              />
            </Box>
          ) : (
            <Box className={classes.rowRight} key="brief-summary-promo-code-button">
              <Button
                classes={{
                  root: classes.tipButton,
                  label: classes.tipButtonText
                }}
                type="button"
                color="secondary"
                onClick={() => setShowPromoCode(true)}>
                Do you have a promo code?
              </Button>
            </Box>
          )}

          {!agentTip && (
            <Box className={classes.rowRight} key="brief-summary-tip-button">
              <Button
                classes={{
                  root: classes.tipButton,
                  label: classes.tipButtonText
                }}
                type="button"
                color="secondary"
                onClick={addTip}>
                {`Give the agent a 10% tip (${agentTipPrice.format()})`}
              </Button>
            </Box>
          )}

          <Box className={classes.rowTotal}>
            <Typography className={classes.rowTotalText}>Total: {price.format()}</Typography>
          </Box>
        </Container>
      </Box>
    )
  }, [
    addTip,
    agentTip,
    agentTipPrice,
    classes,
    creditDiscountPrice,
    handlePromoCode,
    percentDiscountPrice,
    price,
    prices.byId,
    promoCode,
    removeTip,
    showPromoCode,
    sortedSelectedServiceIds
  ])



  const renderMobile = useCallback(
    () =>
      user?.mobile ? null : (
        <div className={classes.mobile}>
          <TextField
            id="phone"
            label="Mobile phone"
            placeholder="07766554433"
            value={mobile}
            required
            onChange={event => setMobile(event.target.value)}
            InputProps={{
              name: 'phone',
              autoComplete: 'tel',
              disableUnderline: true
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      ),
    [classes.mobile, mobile, user]
  )

  const renderCard = useCallback(
    () => (
      <Card className={classes.card}>

        <CardContent
          classes={{
            root: classes.cardContentRoot
          }}>

          <Box className={classes.stripeTerms}>
            <FormControlLabel
              className={classes.termsContainer}
              control={<Checkbox checked={isTermsAccepted} onChange={handleToggleTerms} value={isTermsAccepted} size="small" />}
              label={
                <Typography className={classes.terms}>
                  I accept&nbsp;
                  <NavLink to="/terms_and_conditions">terms &amp; conditions</NavLink>
                </Typography>
              }
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            disabled={!isTermsAccepted || !mobile}>
            Place Order
            {isProcessingStripe && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </CardActions>
      </Card>
    ),
    [classes, handleCardComplete, handleToggleTerms, isCardComplete, isProcessingStripe, isTermsAccepted, mobile]
  )

  const renderNavigation = useCallback(() => {
    return (
      <Box className={classes.bottomContainer}>
        <NavLink
          to="/booking/extra"
          onClick={e => {
            e.preventDefault()
            AnalyticsService.trackEvent(categories.booking, actions.bookingStep3)
            browserHistory.goBack()
          }}
          className={classes.prevButton}>
          Go Back
        </NavLink>
      </Box>
    )
  }, [classes])

  const renderError = useCallback(
    () => (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          classes: {
            root: classes.snackbar
          }
        }}
        open={isStripeError}
        onClose={handleClose}
        message={stripeError || 'Payment processing error'}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" className={classes.close} onClick={handleClose}>
            <Close />
          </IconButton>
        ]}
      />
    ),
    [classes.snackbar, classes.close, isStripeError, handleClose, stripeError]
  )

  const handleSubmit = ev => {
    ev.preventDefault()
    checkout()
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    apiUserStatus()
  }, [apiUserStatus])

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.container}>
        {renderSummary()}
        {renderMobile()}
        {renderCard()}
        {renderNavigation()}
        {renderError()}
      </div>
    </form>
  )
})

const stripePromise = loadStripe(settings.stripeApiKey)

export const StepCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutFormComponent />
    </Elements>
  )
}
