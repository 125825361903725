import React, { useContext } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { debounce } from 'lodash'
import { useForm, useFormContext } from 'react-hook-form'

import { context } from '../../store'
import { fields } from '../../store/constants'
import { BookAddress } from '../book-address'
import { BookDate } from '../book-date'
import { TimeSlots } from '../time-slots'

export const BookNow = () => {
  const history = useHistory()
  const isServicePage = Boolean(useRouteMatch('/services'))

  const { state } = useContext(context)

  const bookingForm = useFormContext()

  const bookNowForm = useForm({
    defaultValues: {
      [fields.address]: state.address,
      [fields.date]: state.date,
      [fields.timeSlot]: state.timeSlot
    }
  })
  const { handleSubmit } = bookNowForm

  const debounced = debounce(() => {
    const bookStepsSection = document.getElementById('book-steps-section')
    bookStepsSection.scrollIntoView({ behavior: 'smooth' })
  }, 100)

  const submitHandler = (data, ev) => {
    ev.preventDefault()
    bookingForm.setValue(fields.address, data[fields.address])
    bookingForm.setValue(fields.date, data[fields.date])
    bookingForm.setValue(fields.timeSlot, data[fields.timeSlot])
    data[fields.address] && bookingForm.trigger(fields.address)
    data[fields.date] && bookingForm.trigger(fields.date)
    data[fields.timeSlot] && bookingForm.trigger(fields.timeSlot)

    if (isServicePage) history.push('/')

    debounced()
  }

  return (
    <form className="book-now-form" onSubmit={handleSubmit(submitHandler)}>
      <div className="book-now-form-wrapper">
        <div className="book-now-form-control">
          <div className="book-now-form-control-wrapper">
            <BookAddress form={bookNowForm} name={fields.address} label="Car location" placeholder="Enter address or postcode" updateMap />
          </div>
        </div>
        <div className="book-now-form-control">
          <div className="book-now-form-control-wrapper">
            <BookDate form={bookNowForm} />
          </div>
        </div>
        <div className="book-now-form-control">
          <div className="book-now-form-control-wrapper">
            <TimeSlots form={bookNowForm} />
          </div>
        </div>
      </div>
      <button className="book-now-button" type="submit">
        book your wash now
      </button>
    </form>
  )
}
