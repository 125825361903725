import React from 'react'
import { image } from '../../../lib/image'

export const ServicesBricks = () => (
  <section className="services-bricks">
    <div className="services-bricks-content">
      <h1 className="services-bricks-title">Services &amp; pricing</h1>
      <p className="services-bricks-text">We have a wide range of services and extras to cater for every need</p>
    </div>
    <img className="services-bricks-img" src={image('./services_bricks.png')} alt="service" />
  </section>
)
