import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, List, ListItem, ListSubheader, ListItemAvatar, ListItemText, IconButton, Divider } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { image } from '../../../../lib/image'
import { useSelectors } from '../../../../store'
import { styles } from './styles'

export const UserOrders = React.memo(() => {
  const classes = makeStyles(styles)()
  const { availableOrders, allocatedOrders, completedOrders } = useSelectors()

  const renderOrders = useCallback(
    orders =>
      orders.map(order => (
        <ListItem key={order.id} className={classes.item}>
          <ListItemAvatar>
            <img className={classes.itemImage} src={order.image ? order.image : image('./icon_60pt@2x.png')} alt="car avatar" />
          </ListItemAvatar>
          <ListItemText
            className={classes.itemText}
            primary={`${order.carModel}, ${order.carRegistration}, ${order.date}, ${order.timeBucket}`}
            secondary={`${order.address}`}
          />
          <IconButton edge="end" size="small">
            <MoreVert />
          </IconButton>
        </ListItem>
      )),
    [classes]
  )

  return (
    <>
      {availableOrders.length > 0 && (
        <Paper className={classes.container}>
          <List className={classes.list} subheader={<ListSubheader>Orders awaiting allocation</ListSubheader>}>
            {renderOrders(availableOrders)}
          </List>
        </Paper>
      )}
      {availableOrders.length > 0 && allocatedOrders.length > 0 && <Divider className={classes.divider} />}
      {allocatedOrders.length > 0 && (
        <Paper className={classes.container}>
          <List className={classes.list} subheader={<ListSubheader>Allocated orders</ListSubheader>}>
            {renderOrders(allocatedOrders)}
          </List>
        </Paper>
      )}
      {completedOrders.length > 0 && (availableOrders.length > 0 || allocatedOrders.length > 0) && <Divider className={classes.divider} />}
      {completedOrders.length > 0 && (
        <Paper className={classes.container}>
          <List className={classes.list} subheader={<ListSubheader>Completed orders</ListSubheader>}>
            {renderOrders(completedOrders)}
          </List>
        </Paper>
      )}
    </>
  )
})
