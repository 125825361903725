import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { context, actions$, useUsersApi, useSelectors, toggleSignIn } from '../../../../store'
import { API_USERS_SIGN_IN_SUCCESS, API_USERS_SIGN_UP_SUCCESS } from '../../../../store/types'

import { SignIn } from '../../../user/components/sign-in'
import { SignUp } from '../../../user/components/sign-up'

export const StepSignIn = React.memo(props => {
  const history = useHistory()
  const { dispatch } = useContext(context)

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const subscription = actions$.subscribe(({ type }) => {
      if (type === API_USERS_SIGN_IN_SUCCESS || type === API_USERS_SIGN_UP_SUCCESS) history.replace('/booking/checkout')
    })

    return () => subscription.unsubscribe()
  }, [history])

  const { isSignIn } = useSelectors()
  const flipSignInAndUp = useCallback(() => dispatch(toggleSignIn()), [dispatch])
  const { apiUserSignIn, apiUserSignUp } = useUsersApi()

  // Sign In
  const [signInEmail, setSignInEmail] = useState('')
  const [signInPassword, setSignInPassword] = useState('')
  const handleSignInEmailChange = useCallback(event => setSignInEmail(`${event.target.value}`.toLowerCase()), [])
  const handleSignInPasswordChange = useCallback(event => setSignInPassword(event.target.value), [])
  const handleSignInSubmit = useCallback(
    event => {
      event.preventDefault()
      apiUserSignIn({ email: signInEmail, password: signInPassword })
    },
    [apiUserSignIn, signInEmail, signInPassword]
  )

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  // Sign Up
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [signUpEmail, setSignUpEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [signUpPassword, setSignUpPassword] = useState('')

  const handleFirstNameChange = useCallback(event => setFirstName(event.target.value), [setFirstName])
  const handleLastNameChange = useCallback(event => setLastName(event.target.value), [setLastName])
  const handleSignUpEmailChange = useCallback(event => setSignUpEmail(`${event.target.value}`.toLowerCase()), [setSignUpEmail])
  const handleMobileChange = useCallback(event => setMobile(event.target.value), [setMobile])
  const handleSignUpPasswordChange = useCallback(event => setSignUpPassword(event.target.value), [setSignUpPassword])
  const handleSignUpSubmit = useCallback(
    event => {
      event.preventDefault()
      apiUserSignUp({ firstName, lastName, email: signUpEmail, mobile, password: signUpPassword })
    },
    [apiUserSignUp, firstName, lastName, signUpEmail, mobile, signUpPassword]
  )

  return (
    <div>
      {isSignIn ? (
        <SignIn
          handleSubmit={handleSignInSubmit}
          handleEmailChange={handleSignInEmailChange}
          handlePasswordChange={handleSignInPasswordChange}
          toggleSignIn={flipSignInAndUp}
        />
      ) : (
        <SignUp
          handleSubmit={handleSignUpSubmit}
          handleFirstNameChange={handleFirstNameChange}
          handleLastNameChange={handleLastNameChange}
          handleMobileChange={handleMobileChange}
          handleEmailChange={handleSignUpEmailChange}
          handlePasswordChange={handleSignUpPasswordChange}
          toggleSignIn={flipSignInAndUp}
        />
      )}
    </div>
  )
})
