export const TOGGLE_SIGN_IN = 'TOGGLE_SIGN_IN'
export const SET_USER = 'SET_USER'
export const SIGN_OUT = 'SIGN_OUT'
export const API_USERS_STATUS_START = 'API_USERS_STATUS_START'
export const API_USERS_STATUS_SUCCESS = 'API_USERS_STATUS_SUCCESS'
export const API_USERS_STATUS_FAILURE = 'API_USERS_STATUS_FAILURE'
export const API_USERS_SIGN_IN_START = 'API_USERS_SIGN_IN_START'
export const API_USERS_SIGN_IN_SUCCESS = 'API_USERS_SIGN_IN_SUCCESS'
export const API_USERS_SIGN_IN_FAILURE = 'API_USERS_SIGN_IN_FAILURE'
export const API_USERS_SIGN_UP_START = 'API_USERS_SIGN_UP_START'
export const API_USERS_SIGN_UP_SUCCESS = 'API_USERS_SIGN_UP_SUCCESS'
export const API_USERS_SIGN_UP_FAILURE = 'API_USERS_SIGN_UP_FAILURE'
