import { Subject, BehaviorSubject } from 'rxjs'

import { initialState } from './reducer'

export const stateSubject = new BehaviorSubject(initialState)
export const state$ = stateSubject.asObservable()
export const getState = () => stateSubject.getValue()

export const actionsSubject = new Subject()

export const actions$ = actionsSubject.asObservable()

// export const logger = actions$.subscribe(action => console.log(action))
