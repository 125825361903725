import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Container, CssBaseline, makeStyles, Snackbar } from '@material-ui/core'
import Intercom from 'react-intercom'

import { Routes } from './routes'
import { Header } from './components/header'
import { Footer } from './components/footer'
import { SnackbarContentWrapper } from './components/snackbar'
import { context } from './store/context'
import { setMessage, startupStart } from './store/actions'
import { useSelectors, useSideEffects } from './store/hooks'
import { settings } from './store/constants'

import { styles } from './styles'

export const App = React.memo(() => {
  const classes = makeStyles(styles)()
  const { dispatch } = useContext(context)
  const [open, setOpen] = useState(false)
  const { message, messageTimeout, messagePosition, messageColor } = useSelectors()

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return
      }

      dispatch(setMessage(null))
      setOpen(false)
    },
    [dispatch]
  )

  useSideEffects()

  useEffect(() => {
    dispatch(startupStart())
  }, [dispatch])

  useEffect(() => {
    if (!message) return

    setOpen(true)
  }, [message, setOpen])

  return (
    <>
      <Intercom appID={settings.intercomAppId} />
      <CssBaseline />
      <Header />
      <Container classes={{ root: classes.container }} component="main" maxWidth={false}>
        <Routes />
      </Container>
      <Footer />
      <Snackbar
        anchorOrigin={{
          vertical: messagePosition || 'bottom',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={messageTimeout}
        onClose={handleClose}>
        <SnackbarContentWrapper onClose={handleClose} message={message} style={messageColor ? { backgroundColor: messageColor } : null} />
      </Snackbar>
    </>
  )
})
