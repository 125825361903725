import React, { useContext, useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import { Button, IconButton, ListItem, Menu, MenuItem } from '@material-ui/core'
import { Menu as MenuIcon, PersonRounded, ExitToAppRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { context, useSelectors, userSignOut } from '../../../store'

import { CustomRouterLink } from '../../custom-router-link'

import { styles } from './styles'

export const HeaderMenu = React.memo(() => {
  const classes = makeStyles(styles)()

  const { dispatch } = useContext(context)
  const { isUserSignedIn } = useSelectors()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = useCallback(
    event => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleSignOut = useCallback(() => dispatch(userSignOut()), [dispatch])

  const renderMenuItem = useCallback(
    (name, icon, href, onClick) => (
      <ListItem className={classes.item} disableGutters>
        <Button className={classes.button} component={CustomRouterLink} to={href} onClick={onClick}>
          <div className={classes.icon}>{icon}</div>
          {name}
        </Button>
      </ListItem>
    ),
    [classes]
  )

  return (
    <>
      <IconButton edge="end" className={classes.menuButton} onClick={handleMenu} color="inherit" aria-label="menu">
        <MenuIcon className={classes.menuIcon} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}>
        {/* <MenuItem onClick={handleClose}>
          <NavLink to="/" className={classes.menuItem}>
            Homepage
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to="/" className={classes.menuItem}>
            Book a wash
          </NavLink>
        </MenuItem> */}
        <MenuItem onClick={handleClose}>
          <NavLink to="/services" className={classes.menuItem}>
            Services &amp; Pricing
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to="/faqs" className={classes.menuItem}>
            FAQs
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.volvo}>
          <a href="https://www.gowashmycar.com/volvocars" className={classes.menuItem}>
            Volvo
          </a>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <a href="https://gowashmycar.typeform.com/to/zMq9Ya" className={classes.menuItem}>
            Become an operator
          </a>
        </MenuItem> */}
        <MenuItem onClick={handleClose}>
          <a href="https://gowashmycar.typeform.com/to/oPLq2u" className={classes.menuItem}>
            Corporate
          </a>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <a href="https://subscriptions.gowashmycar.com" className={classes.menuItem}>
            Subscription
          </a>
        </MenuItem> */}

        {isUserSignedIn
          ? renderMenuItem('Sign out', <ExitToAppRounded />, '/', () => {
              handleClose()
              handleSignOut()
            })
          : renderMenuItem('Sign in', <PersonRounded />, '/user/sign_in', handleClose)}
      </Menu>
    </>
  )
})
