import React from 'react'
import { NavLink } from 'react-router-dom'

import { FacebookLoginButton } from '../facebook-login-button'
import { GoogleLoginButton } from '../google-login-button'

import { image } from '../../../../lib/image'

const title = 'Please create an account before payment'

export const SignUp = React.memo(
  ({ handleSubmit, handleFirstNameChange, handleLastNameChange, handleMobileChange, handleEmailChange, handlePasswordChange, toggleSignIn }) => {
    return (
      <div className="signup-background">
        <div className="signup-container">
          <div className="signup-content">
            <NavLink to="/">
              <img width="180px" height="41px" src={image('./logo.png')} alt="Gowashmycar logo" />
            </NavLink>

            <h2 className="signup-title">{title}</h2>

            <form noValidate className="signup-form" onSubmit={handleSubmit}>
              <div className="signup-form-names">
                <input className="signup-form-firstname" id="fName" name="fname" placeholder="First Name" onChange={handleFirstNameChange} />
                <input className="signup-form-lastname" id="lName" name="lName" placeholder="Last Name" onChange={handleLastNameChange} />
              </div>
              <input className="signup-form-phone" id="phone" name="phone" autoComplete="tel" placeholder="Mobile phone" onChange={handleMobileChange} />
              <input className="signup-form-email" id="email" name="email" autoComplete="email" placeholder="Email address" onChange={handleEmailChange} />
              <input
                className="signup-form-password"
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="Password"
                onChange={handlePasswordChange}
              />

              <input className="signup-form-submit" type="submit" value="Continue" />

              <div className="signup-form-horizontal-row">or</div>

              <GoogleLoginButton title="Continue with Google" />
              <FacebookLoginButton title="Continue with Facebook" />
            </form>

            <div className="signup-extra">
              <input name="allowExtraEmails" id="allowExtraEmails" type="checkbox" value="true" />
              <label htmlFor="allowExtraEmails">I want to receive inspiration, marketing promotions and updates via email.</label>
            </div>

            <p className="signup-form-question">Already have an account?</p>
            <div onClick={toggleSignIn} className="signup-form-create">
              <span>Sign in</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
)
