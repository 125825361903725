import React, { useContext, useState } from 'react'
import { addDays, isToday, getHours } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, ButtonGroup, FormLabel, Dialog, DialogContent, DialogActions, InputAdornment, TextField, Hidden, Typography } from '@material-ui/core'
import { KeyboardArrowDownRounded } from '@material-ui/icons'
import { Controller } from 'react-hook-form'

import { context, setAnytimeToday, useSelectors } from '../../store'
import { fields, slots } from '../../store/constants'
import { styles } from './styles'

export const TimeSlots = ({ form, required = false }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { isSameDaySelected, date } = useSelectors()

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const classes = makeStyles(styles)()
  const { state, dispatch } = useContext(context)

  const handleTimeSlot = newTimeSlot => event => {
    event.preventDefault()
    if (new Date().toDateString() === state.date?.toDateString()) {
      form.setValue(fields.date, addDays(new Date(), 1))
    }
    dispatch(setAnytimeToday(false))
    form.setValue(fields.timeSlot, newTimeSlot)
    form.trigger(fields.timeSlot)
    handleDialogClose()
  }

  const handleAnytimeToday = () => {
    dispatch(setAnytimeToday(true))
    form.setValue(fields.timeSlot, slots.today)
    form.setValue(fields.date, new Date())
    form.trigger(fields.timeSlot)
    form.trigger(fields.date)
    handleDialogClose()
  }

  const isAnytimeTodayDisabled = (date && !isToday(date)) || getHours(Date.now()) > 13

  return (
    <Controller
      name={fields.timeSlot}
      control={form.control}
      rules={{ required: required && 'Time is required' }}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            label={`Time${required ? ' *' : ''}`}
            placeholder="Select a time"
            error={!!error}
            helperText={error ? error.message : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <KeyboardArrowDownRounded className="book-now-control-icon" />
                </InputAdornment>
              ),
              disableUnderline: true,
              readOnly: true
            }}
            InputLabelProps={{
              shrink: true
            }}
            onClick={handleDialogOpen}
          />
          <Dialog onClose={handleDialogClose} open={dialogOpen}>
            <DialogContent className={classes.container}>
              <Box className={classes.box}>
                <FormLabel component="legend" className={classes.label}>
                  Time slot
                </FormLabel>

                <Hidden mdUp>
                  <Button
                    color="primary"
                    disabled={isSameDaySelected}
                    className={classes.button}
                    classes={state.timeSlot === slots.allday ? { root: classes.buttonPressed } : {}}
                    variant={state.timeSlot === slots.allday ? 'contained' : 'outlined'}
                    onClick={handleTimeSlot(slots.allday)}
                    fullWidth>
                    Anytime 8am-6pm
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSameDaySelected}
                    className={classes.button}
                    classes={state.timeSlot === slots.slot1 ? { root: classes.buttonPressed } : {}}
                    variant={state.timeSlot === slots.slot1 ? 'contained' : 'outlined'}
                    onClick={handleTimeSlot(slots.slot1)}
                    fullWidth>
                    8am-12pm
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSameDaySelected}
                    className={classes.button}
                    classes={state.timeSlot === slots.slot2 ? { root: classes.buttonPressed } : {}}
                    variant={state.timeSlot === slots.slot2 ? 'contained' : 'outlined'}
                    onClick={handleTimeSlot(slots.slot2)}
                    fullWidth>
                    10am-2pm
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSameDaySelected}
                    className={classes.button}
                    classes={state.timeSlot === slots.slot3 ? { root: classes.buttonPressed } : {}}
                    variant={state.timeSlot === slots.slot3 ? 'contained' : 'outlined'}
                    onClick={handleTimeSlot(slots.slot3)}
                    fullWidth>
                    2pm-6pm
                  </Button>
                  {/* <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  classes={state.timeSlot === slots.morning ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.morning ? 'contained' : 'outlined'}
                  className={classes.button}
                  onClick={handleTimeSlot(slots.morning)}
                  fullWidth>
                  Morning 8am-2pm
                </Button>
                <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  className={classes.button}
                  classes={state.timeSlot === slots.afternoon ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.afternoon ? 'contained' : 'outlined'}
                  onClick={handleTimeSlot(slots.afternoon)}
                  fullWidth>
                  Afternoon 2pm-8pm
                </Button>
                <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  className={classes.button}
                  classes={state.timeSlot === slots.slot08 ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.slot08 ? 'contained' : 'outlined'}
                  onClick={handleTimeSlot(slots.slot08)}
                  fullWidth>
                  08-10am
                </Button>
                <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  className={classes.button}
                  classes={state.timeSlot === slots.slot10 ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.slot10 ? 'contained' : 'outlined'}
                  onClick={handleTimeSlot(slots.slot10)}
                  fullWidth>
                  10-12am
                </Button>
                <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  className={classes.button}
                  classes={state.timeSlot === slots.slot12 ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.slot12 ? 'contained' : 'outlined'}
                  onClick={handleTimeSlot(slots.slot12)}
                  fullWidth>
                  12-14pm
                </Button>
                <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  className={classes.button}
                  classes={state.timeSlot === slots.slot14 ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.slot14 ? 'contained' : 'outlined'}
                  onClick={handleTimeSlot(slots.slot14)}
                  fullWidth>
                  14-16pm
                </Button>
                <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  className={classes.button}
                  classes={state.timeSlot === slots.slot16 ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.slot16 ? 'contained' : 'outlined'}
                  onClick={handleTimeSlot(slots.slot16)}
                  fullWidth>
                  16-18pm
                </Button>
                <Button
                  color="primary"
                  disabled={isSameDaySelected}
                  className={classes.button}
                  classes={state.timeSlot === slots.slot18 ? { root: classes.buttonPressed } : {}}
                  variant={state.timeSlot === slots.slot18 ? 'contained' : 'outlined'}
                  onClick={handleTimeSlot(slots.slot18)}
                  fullWidth>
                  18-20pm
                </Button> */}
                  <Button
                    color="primary"
                    disabled={isAnytimeTodayDisabled}
                    className={classes.button}
                    classes={state.timeSlot === slots.today ? { root: classes.buttonPressed } : {}}
                    variant={state.timeSlot === slots.today ? 'contained' : 'outlined'}
                    onClick={handleAnytimeToday}
                    fullWidth>
                    Anytime today (extra £25)
                  </Button>
                </Hidden>

                <Hidden smDown>
                  <Typography className={classes.text}>If you&apos;ll be around all day</Typography>
                  <Button
                    color="primary"
                    disabled={isSameDaySelected}
                    className={classes.button}
                    classes={state.timeSlot === slots.allday ? { root: classes.buttonPressed } : {}}
                    variant={state.timeSlot === slots.allday ? 'contained' : 'outlined'}
                    onClick={handleTimeSlot(slots.allday)}
                    fullWidth>
                    Anytime 8am-6pm
                  </Button>
                  {/* <Typography className={classes.text}>If you&apos;re a little flexible but have somewhere to be</Typography>
                <ButtonGroup fullWidth disableRipple>
                  <Button
                    color="primary"
                    disabled={isSameDaySelected}
                    classes={state.timeSlot === slots.morning ? { root: classes.buttonPressed } : {}}
                    className={classes.button}
                    onClick={handleTimeSlot(slots.morning)}>
                    Morning 8am-2pm
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSameDaySelected}
                    className={classes.button}
                    classes={state.timeSlot === slots.afternoon ? { root: classes.buttonPressed } : {}}
                    onClick={handleTimeSlot(slots.afternoon)}>
                    Afternoon 2pm-8pm
                  </Button>
                </ButtonGroup> */}
                  <Typography className={classes.text}>If you&apos;d prefer a more specific time</Typography>
                  <Box style={{ display: 'flex', width: '100%' }}>
                    <ButtonGroup style={{ flex: 1 }} disableRipple>
                      <Button
                        color="primary"
                        disabled={isSameDaySelected}
                        className={classes.button}
                        classes={state.timeSlot === slots.slot1 ? { root: classes.buttonPressed } : {}}
                        onClick={handleTimeSlot(slots.slot1)}>
                        8am-12pm
                      </Button>
                      <Button
                        color="primary"
                        disabled={isSameDaySelected}
                        className={classes.button}
                        classes={state.timeSlot === slots.slot2 ? { root: classes.buttonPressed } : {}}
                        onClick={handleTimeSlot(slots.slot2)}>
                        10am-2pm
                      </Button>
                      <Button
                        color="primary"
                        disabled={isSameDaySelected}
                        className={classes.button}
                        classes={state.timeSlot === slots.slot3 ? { root: classes.buttonPressed } : {}}
                        onClick={handleTimeSlot(slots.slot3)}>
                        2pm-6pm
                      </Button>
                      {/* <Button
                      color="primary"
                      disabled={isSameDaySelected}
                      className={classes.button}
                      classes={state.timeSlot === slots.slot08 ? { root: classes.buttonPressed } : {}}
                      onClick={handleTimeSlot(slots.slot08)}>
                      8-10am
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSameDaySelected}
                      className={classes.button}
                      classes={state.timeSlot === slots.slot10 ? { root: classes.buttonPressed } : {}}
                      onClick={handleTimeSlot(slots.slot10)}>
                      10-12am
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSameDaySelected}
                      className={classes.button}
                      classes={state.timeSlot === slots.slot12 ? { root: classes.buttonPressed } : {}}
                      onClick={handleTimeSlot(slots.slot12)}>
                      12-2pm
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSameDaySelected}
                      className={classes.button}
                      classes={state.timeSlot === slots.slot14 ? { root: classes.buttonPressed } : {}}
                      onClick={handleTimeSlot(slots.slot14)}>
                      2-4pm
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSameDaySelected}
                      className={classes.button}
                      classes={state.timeSlot === slots.slot16 ? { root: classes.buttonPressed } : {}}
                      onClick={handleTimeSlot(slots.slot16)}>
                      4-6pm
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSameDaySelected}
                      className={classes.button}
                      classes={state.timeSlot === slots.slot18 ? { root: classes.buttonPressed } : {}}
                      onClick={handleTimeSlot(slots.slot18)}>
                      6-8pm
                    </Button> */}
                    </ButtonGroup>
                  </Box>
                  <Typography className={classes.text}>Or if you need a wash urgently!</Typography>
                  <Button
                    color="primary"
                    disabled={isAnytimeTodayDisabled}
                    className={classes.button}
                    classes={state.timeSlot === slots.today ? { root: classes.buttonPressed } : {}}
                    variant={state.timeSlot === slots.today ? 'contained' : 'outlined'}
                    onClick={handleAnytimeToday}
                    fullWidth>
                    Anytime today (extra £25)
                  </Button>
                </Hidden>
              </Box>
            </DialogContent>
            <DialogActions className={classes.confirm}>
              <Button onClick={handleDialogClose} color="primary">
                CANCEL
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    />
  )
}
