export const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: theme.spacing(1)
    padding: '20px 100px'
  },
  containedPrimaryButton: {
    backgroundColor: '#4c69ba'
  },
  facebook: {
    // backgroundColor: 'blue',
    marginBottom: theme.spacing(2)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: '10px 0 40px'
  },

  displayNone: {
    display: 'none'
  }
})
