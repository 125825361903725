import {
  SET_SERVICES,
  SET_ADDRESS,
  SET_GEO_LOCATION,
  SET_MAP_ZOOM,
  SET_DATE,
  SET_TIME_SLOT,
  SET_HOME_ADDRESS,
  SET_CAR_MODEL,
  SET_CAR_REGISTRATION,
  SET_CAR_COLOR,
  SET_NOTES,
  SET_AGENT_TIP,
  SET_ANYTIME_TODAY,
  SET_LARGE_CAR,
  SET_CONGESTION_ZONE,
  SET_IS_CARD_COMPLETE,
  SET_IS_PROCESSING_STRIPE,
  SET_IS_STRIPE_ERROR,
  SET_STRIPE_ERROR,
  SET_IS_TERMS_ACCEPTED,
  RESET_BOOKING_FORM
} from '../types/booking'

export const setServices = ({ services, servicesAvailability }) => ({ type: SET_SERVICES, services, servicesAvailability })
export const setAddress = address => ({ type: SET_ADDRESS, address })
export const setGeoLocation = geoLocation => ({ type: SET_GEO_LOCATION, geoLocation })
export const setMapZoom = zoom => ({ type: SET_MAP_ZOOM, zoom })
export const setDate = date => ({ type: SET_DATE, date })
export const setTimeSlot = timeSlot => ({ type: SET_TIME_SLOT, timeSlot })
export const setHomeAddress = homeAddress => ({ type: SET_HOME_ADDRESS, homeAddress })
export const setCarModel = carModel => ({ type: SET_CAR_MODEL, carModel })
export const setCarRegistration = carRegistration => ({ type: SET_CAR_REGISTRATION, carRegistration })
export const setCarColor = carColor => ({ type: SET_CAR_COLOR, carColor })
export const setNotes = notes => ({ type: SET_NOTES, notes })
export const setAgentTip = agentTip => ({ type: SET_AGENT_TIP, agentTip })
export const setAnytimeToday = sameDay => ({ type: SET_ANYTIME_TODAY, sameDay })
export const setLargeCar = largeCar => ({ type: SET_LARGE_CAR, largeCar })
export const setCongestionZone = congestionZone => ({ type: SET_CONGESTION_ZONE, congestionZone })
export const resetBookingForm = () => ({ type: RESET_BOOKING_FORM })
export const setIsCardComplete = isCardComplete => ({ type: SET_IS_CARD_COMPLETE, isCardComplete })
export const setIsProcessingStripe = isProcessingStripe => ({ type: SET_IS_PROCESSING_STRIPE, isProcessingStripe })
export const setIsStripeError = isStripeError => ({ type: SET_IS_STRIPE_ERROR, isStripeError })
export const setStripeError = stripeError => ({ type: SET_STRIPE_ERROR, stripeError })
export const setIsTermsAccepted = isTermsAccepted => ({ type: SET_IS_TERMS_ACCEPTED, isTermsAccepted })
