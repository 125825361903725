import React from 'react'

import { image } from '../../../lib/image'

export const Bossman = () => (
  <section className="bossman">
    <div className="bossman-content">
      <div className="bossman-photo">
        <img className="bossman-photo-img" src={image('./bossman_barry.svg')} alt="Barry" />
      </div>
      <div className="bossman-details">
        <h1 className="bossman-title">Head of Operations</h1>
        <h2 className="bossman-name">Barry Cohen</h2>
        <div className="bossman-speach">
          <p className="bossman-speach-text">Hi, I’m Barry and I have been valeting cars for 25+ years!</p>
          <p className="bossman-speach-text">
            At <span className="bossman-speach-go">GoWashMyCar</span> I manage a team of passionate and enthusiastic professionals.
          </p>
          <p className="bossman-speach-text">We all look forward to caring for your car.</p>
        </div>
      </div>
    </div>
    <div className="bossman-footer">
      <div className="bossman-footer-cover"></div>
    </div>
  </section>
)
