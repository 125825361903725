import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, List, ListItem, ListItemText, IconButton } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

import { useSelectors } from '../../../../store'
import { styles } from './styles'

export const UserCredit = React.memo(() => {
  const classes = makeStyles(styles)()
  const { userCredit, userCreditCents } = useSelectors()

  return (
    userCreditCents > 0 && (
      <Paper className={classes.container}>
        <List className={classes.list}>
          <ListItem className={classes.item}>
            <ListItemText className={classes.itemText} primary={`Credit ${userCredit}`} />
            <IconButton edge="end" size="small">
              <MoreVert />
            </IconButton>
          </ListItem>
        </List>
      </Paper>
    )
  )
})
