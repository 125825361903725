import React from 'react'
import { IconButton, SnackbarContent } from '@material-ui/core'
import { Close as CloseIcon, Info as InfoIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { styles } from './styles'

export function SnackbarContentWrapper(props) {
  const classes = makeStyles(styles)()
  const { className, message, onClose, ...other } = props

  return (
    <SnackbarContent
      className={className}
      message={
        <span id="client-snackbar" className={classes.message}>
          <InfoIcon className={classes.infoIcon} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}
