import { colors } from '@material-ui/core'

export const styles = theme => ({
  brief: {
    boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.2)'
  },
  briefSummary: {
    maxWidth: '1048px',
    margin: '0 auto'
  },
  briefDetails: {
    maxWidth: '1048px',
    margin: '0 auto'
  },
  container: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    color: colors.blueGrey[800]
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderTop: '1px solid #dddddd',
    color: colors.blueGrey[800]
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    color: colors.blueGrey[800],
    fontWeight: 'bold',
    fontSize: '0.875rem'
  },
  headingTotal: {
    display: 'flex',
    justifyContent: 'center',
    color: colors.blueGrey[800],
    fontWeight: 'bold',
    fontSize: '0.875rem'
  },
  summary: {
    display: 'flex',
    justifyContent: 'center',
    color: '#555',
    fontWeight: 'bold',
    fontSize: '0.875rem'
  },
  strong: {
    fontWeight: 'bold'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderTop: '1px solid #dddddd',
    color: '#555'
  },
  rowTotal: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    borderTop: '1px solid #dddddd'
  },
  firstLine: {
    fontSize: '0.875rem'
  },
  secondLine: {
    fontSize: '0.875rem',
    textAlign: 'right',
    paddingLeft: theme.spacing(2)
  },
  text: {
    color: '#555',
    fontSize: '0.875rem'
  },
  totalText: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: '#555'
  }
})
