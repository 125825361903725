import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { UserMenu } from './components/menu'
import { UserCredit } from './components/credit'
import { UserPromoCode } from './components/promo-code'
import { UserOrders } from './components/orders'
import { useUsersApi } from '../../store/hooks'
import { styles } from './styles'

export const UserPage = React.memo(() => {
  const classes = makeStyles(styles)()
  const { apiUserStatus } = useUsersApi()

  useEffect(() => {
    apiUserStatus()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      apiUserStatus()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Helmet title="Your Orders" />
      <Grid container spacing={1} className={classes.containerGrid}>
        <Grid item xs={12} sm={9}>
          <UserCredit />
          <UserPromoCode />
          <UserOrders />
        </Grid>
        <Grid container direction="column" item sm={3} spacing={1}>
          <Grid item>
            <UserMenu />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
})
