export const styles = theme => ({
  container: {
    marginTop: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  appbar: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0px 16px',
    '@media (min-width: 960px)': {
      padding: '0px 70px'
    }
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '5px 0px 0px 0px'
  },
  toolbarCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(5),
    flexGrow: 1,
    '@media (min-width: 960px)': {
      paddingLeft: 0,
      justifyContent: 'flex-start'
    }
  },
  toolbarRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'stretch'
  },
  toolbarItem: {
    display: 'block',
    margin: '0 10px',
    '&:hover': {
      textShadow: '0 0 8px #ccc'
    },
    fontFamily: "Poppins, 'Helvetica Neue', sans-serif",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
    color: '#1C202F',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    transition: 'text-shadow 0.3s'
  },
  toolbarItemWrapper: {},
  toolbarItemWrapperActive: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: '5px solid #2ABFF3',
    background: '#F8F8F8'
  },
  covidNotification: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundColor: '#1E2338',
    fontFamily: "Poppins, 'Helvetica Neue', sans-serif",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px'
  },
  volvo: {
    display: 'none'
  }
})
