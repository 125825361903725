import React, { useContext } from 'react'
import { GoogleLogin } from 'react-google-login'

import { apiUsersSignInFailure, context, useUsersApi } from '../../../../store'
import { settings } from '../../../../store/constants'
import { image } from '../../../../lib/image'

const CustomButton = ({ onClick, title }) => {
  return (
    <button type="button" className="google-login-button" onClick={onClick}>
      <img src={image('./login_google.svg')} title="Google" alt="Google" />
      <div>{title}</div>
    </button>
  )
}

export const GoogleLoginButton = React.memo(props => {
  const { title } = props
  const { dispatch } = useContext(context)
  const { apiUserGoogleLogin } = useUsersApi()

  const handleSuccess = googleData => {
    const email = googleData?.profileObj?.email
    const first_name = googleData?.profileObj?.givenName || googleData?.profileObj?.name
    const last_name = googleData?.profileObj?.familyName || ''
    const uid = googleData?.profileObj?.googleId || ''

    email && apiUserGoogleLogin({ uid, email, first_name, last_name })
  }

  const handleFailure = data => {
    dispatch(apiUsersSignInFailure(`GoogleLogin error: ${JSON.stringify(data)}`))
  }

  return (
    <GoogleLogin
      clientId={settings.googleAppId}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      cookiePolicy="single_host_origin"
      render={gProps => <CustomButton {...gProps} title={title} />}
    />
  )
})
