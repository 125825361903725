import {
  TOGGLE_SIGN_IN,
  SET_USER,
  SIGN_OUT,
  API_USERS_STATUS_START,
  API_USERS_STATUS_SUCCESS,
  API_USERS_STATUS_FAILURE,
  API_USERS_SIGN_IN_START,
  API_USERS_SIGN_IN_SUCCESS,
  API_USERS_SIGN_IN_FAILURE,
  API_USERS_SIGN_UP_START,
  API_USERS_SIGN_UP_SUCCESS,
  API_USERS_SIGN_UP_FAILURE
} from '../types/users'

export const toggleSignIn = () => ({ type: TOGGLE_SIGN_IN })
export const setUser = user => ({ type: SET_USER, user })
export const userSignOut = () => ({ type: SIGN_OUT })
export const apiUsersStatusStart = () => ({ type: API_USERS_STATUS_START })
export const apiUsersStatusSuccess = () => ({ type: API_USERS_STATUS_SUCCESS })
export const apiUsersStatusFailure = error => ({ type: API_USERS_STATUS_FAILURE, error })
export const apiUsersSignInStart = () => ({ type: API_USERS_SIGN_IN_START })
export const apiUsersSignInSuccess = () => ({ type: API_USERS_SIGN_IN_SUCCESS })
export const apiUsersSignInFailure = error => ({ type: API_USERS_SIGN_IN_FAILURE, error })
export const apiUsersSignUpStart = () => ({ type: API_USERS_SIGN_UP_START })
export const apiUsersSignUpSuccess = () => ({ type: API_USERS_SIGN_UP_SUCCESS })
export const apiUsersSignUpFailure = error => ({ type: API_USERS_SIGN_UP_FAILURE, error })
