import React, { useCallback, useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, FormLabel, Hidden } from '@material-ui/core'
import { CheckBoxRounded, CheckBoxOutlineBlankRounded } from '@material-ui/icons'
import clsx from 'clsx'

import { context, useSelectors, setServices } from '../../../../store'
import { actions, AnalyticsService, categories } from '../../../../services/analytics'
import { browserHistory } from '../../../../services/history'

import { NavButtons } from '../nav-buttons'
import { BOOKING_ROUTES, STEPS } from '../steps'

import { styles } from './styles'

export const StepExtra = React.memo(() => {
  const classes = makeStyles(styles)()
  const { dispatch } = useContext(context)
  const { baseService, isUserSignedIn, prices, services, servicesAvailability } = useSelectors()

  const handleSelectService = useCallback(
    serviceId => event => {
      event.preventDefault()

      const extraService = prices.byId[serviceId]

      if (services[serviceId]) {
        AnalyticsService.trackEvent(categories.booking, actions.desetlectExtraService, extraService.name, extraService.price.format().replace('£', ''))
        AnalyticsService.trackRemoveFromCart(extraService)
      } else {
        AnalyticsService.trackEvent(categories.booking, actions.setlectExtraService, extraService.name, extraService.price.format().replace('£', ''))
        AnalyticsService.trackAddToCart(extraService)
      }

      const selectedServices = {
        ...services,
        ...extraService.excludes.reduce((acc, id) => ({ ...acc, [id]: false }), {}),
        [serviceId]: !services[serviceId]
      }

      const servicesAvailability = Object.values(prices.byId).reduce(
        (acc, service) => ({ ...acc, [service.id]: !(baseService['includes'].includes(service.id) || extraService['includes'].includes(service.id)) }),
        {}
      )

      dispatch(setServices({ services: selectedServices, servicesAvailability }))
    },
    [baseService, dispatch, prices.byId, services]
  )

  const renderService = useCallback(
    serviceId => {
      const { name, description, customerPrice } = prices.byId[serviceId]
      const isSelected = services[serviceId]

      return (
        <Button
          key={serviceId}
          color="primary"
          className={clsx(classes.button, classes.serviceCard)}
          classes={{ label: classes.buttonLabel, outlinedPrimary: classes.outlinedPrimary }}
          variant={isSelected ? 'contained' : 'outlined'}
          onClick={handleSelectService(serviceId)}
          fullWidth>
          <Box className={classes.valetTitle}>
            {isSelected ? (
              <CheckBoxRounded fontSize="inherit" style={{ color: '#37b45a', transform: 'scale(1.2)' }} />
            ) : (
              <CheckBoxOutlineBlankRounded fontSize="inherit" className={classes.checkbox} />
            )}
            <span className={classes.valetTitleServiceName}>{name}</span>
            <span className={classes.valetTitleServicePrice}>
              <small>({customerPrice})</small>
            </span>
          </Box>
          <Box className={classes.valetDescription} style={{ textAlign: 'justify', textJustify: 'distribute', textAlignLast: 'left' }}>
            {description}
          </Box>
        </Button>
      )
    },
    [classes, handleSelectService, prices.byId, services]
  )

  const pricesArray = useMemo(() => Object.values(prices.byOrder), [prices.byOrder])
  const pricesAvailabilityArray = useMemo(() => pricesArray.filter(service => servicesAvailability[service.id]), [pricesArray]);

  const popularAddons = useMemo(() => pricesAvailabilityArray.filter(service => service.group === 'popular'), [pricesAvailabilityArray])
  const popularAddonsFirstColumn = useMemo(() => popularAddons.filter((_, i) => i % 2 === 0), [popularAddons])
  const popularAddonsSecondColumn = useMemo(() => popularAddons.filter((_, i) => i % 2 !== 0), [popularAddons])

  const specialistExtras = useMemo(() => pricesArray.filter(service => service.group === 'extras'), [pricesArray])
  const specialistExtrasFirstColumn = useMemo(() => specialistExtras.filter((_, i) => i % 2 === 0), [specialistExtras])
  const specialistExtrasSecondColumn = useMemo(() => specialistExtras.filter((_, i) => i % 2 !== 0), [specialistExtras])

  const detailingExtras = useMemo(() => pricesArray.filter(service => service.group === 'detailing'), [pricesArray])
  const detailingExtrasFirstColumn = useMemo(() => detailingExtras.filter((_, i) => i % 2 === 0), [detailingExtras])
  const detailingExtrasSecondColumn = useMemo(() => detailingExtras.filter((_, i) => i % 2 !== 0), [detailingExtras])

  const handleNextStep = ev => {
    ev.preventDefault()
    const nextStep = isUserSignedIn ? BOOKING_ROUTES[STEPS.CHECKOUT] : BOOKING_ROUTES[STEPS.SIGN_IN]
    browserHistory.push(nextStep)
  }

  return (
    <form onSubmit={handleNextStep}>
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Box className={classes.innerContainer}>
            <FormLabel component="legend" className={classes.label}>
              Popular extras
            </FormLabel>

            <Hidden smDown>
              <Box className={classes.colWrapper}>
                <Box className={classes.col}>{popularAddonsFirstColumn.map(({ id }) => servicesAvailability[id] && renderService(id))}</Box>
                <Box className={classes.col}>{popularAddonsSecondColumn.map(({ id }) => servicesAvailability[id] && renderService(id))}</Box>
              </Box>
            </Hidden>

            <Hidden mdUp>
              {popularAddons.map(
                ({ id }) =>
                  servicesAvailability[id] && (
                    <Box key={id} className={classes.row}>
                      {renderService(id)}
                    </Box>
                  )
              )}
            </Hidden>

            <br />

            <FormLabel component="legend" className={classes.label}>
              Specialist extras
            </FormLabel>

            <Hidden smDown>
              <Box className={classes.colWrapper}>
                <Box className={classes.col}>{specialistExtrasFirstColumn.map(({ id }) => servicesAvailability[id] && renderService(id))}</Box>
                <Box className={classes.col}>{specialistExtrasSecondColumn.map(({ id }) => servicesAvailability[id] && renderService(id))}</Box>
              </Box>
            </Hidden>

            <Hidden mdUp>
              {specialistExtras.map(
                ({ id }) =>
                  servicesAvailability[id] && (
                    <Box key={id} className={classes.row}>
                      {renderService(id)}
                    </Box>
                  )
              )}
            </Hidden>

            <br />

            <FormLabel component="legend" className={classes.label}>
              Enhanced services
            </FormLabel>

            <Hidden smDown>
              <Box className={classes.colWrapper}>
                <Box className={classes.col}>{detailingExtrasFirstColumn.map(({ id }) => servicesAvailability[id] && renderService(id))}</Box>
                <Box className={classes.col}>{detailingExtrasSecondColumn.map(({ id }) => servicesAvailability[id] && renderService(id))}</Box>
              </Box>
            </Hidden>

            <Hidden mdUp>
              {detailingExtras.map(
                ({ id }) =>
                  servicesAvailability[id] && (
                    <Box key={id} className={classes.row}>
                      {renderService(id)}
                    </Box>
                  )
              )}
            </Hidden>
          </Box>

          <NavButtons />
        </Box>
      </Box>
    </form>
  )
})
