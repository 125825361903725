import React, { useCallback, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Hidden } from '@material-ui/core'
import { ExitToAppRounded } from '@material-ui/icons'
import { useSelectors, userSignOut, context } from '../../../store'
import { styles } from './styles'

export const HeaderAccount = React.memo(props => {
  const classes = makeStyles(styles)()

  const { dispatch } = useContext(context)
  const { isUserSignedIn } = useSelectors()
  // const history = useHistory()

  // const isUserRoute = useMemo(() => /^\/user/.test(history.location.pathname), [history.location.pathname])

  // const handleAccount = useCallback(() => {
  //   isUserRoute ? history.push('/') : history.push('/user')
  // }, [isUserRoute, history])

  // const renderMenuIcon = useCallback(
  //   () =>
  //     isUserRoute ? (
  //       <IconButton aria-label="homepage" onClick={handleAccount} color="inherit">
  //         <HomeRounded className={classes.menuIcon} />
  //       </IconButton>
  //     ) : (
  //       <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleAccount} color="inherit">
  //         <AccountCircle className={classes.menuIcon} />
  //       </IconButton>
  //     ),
  //   [classes, handleAccount, isUserRoute]
  // )

  const handleSignOut = useCallback(() => dispatch(userSignOut()), [dispatch])

  const renderSignOut = useCallback(
    ({ text = false } = {}) => (
      <Button className={classes.button} onClick={handleSignOut}>
        <div className={classes.icon}>{<ExitToAppRounded />}</div>
        {text && 'Sign Out'}
      </Button>
    ),
    [classes.button, classes.icon, handleSignOut]
  )

  const renderSignIn = useCallback(
    () => (
      <Box className={classes.loginContainer}>
        <NavLink to="/user/sign_in" className={classes.loginItem}>
          Sign In
        </NavLink>
        <NavLink to="/user/sign_up" className={classes.loginItem}>
          Sign Up
        </NavLink>
      </Box>
    ),
    [classes]
  )

  return (
    <>
      <Hidden mdUp>{renderSignOut()}</Hidden>
      <Hidden smDown>{isUserSignedIn ? renderSignOut({ text: true }) : renderSignIn()}</Hidden>
    </>
  )
})
