import { camelCase } from 'lodash'

import { LARGE_CAR_SERVICE, SAME_DAY_SERVICE, CONGESTION_ZONE_SERVICE } from './constants/prices'
import * as TYPES from './types'
import { GBP } from '../lib/gbp'

const initialServices = {
  0: true
}

const initialBookingForm = {
  servicesAvailability: { ...initialServices },
  services: { ...initialServices },
  address: '',
  homeAddress: '',
  geoLocation: {
    lat: 51.5073509,
    lng: -0.1277583
  },
  zoom: 9,
  date: null,
  timeSlot: '',
  carModel: '',
  carColor: '',
  carRegistration: '',
  notes: '',
  agentTip: false
}

export const initialState = {
  ...initialBookingForm,
  prices: { ids: [], byId: {}, byName: {}, byOrder: [] },
  isSignIn: false,
  user: null,
  orders: [],
  message: null,
  messageTimeout: null,
  messagePosition: null,
  messageColor: null,
  promoCode: null,
  promoCodeInfo: null,
  isCardComplete: false,
  isProcessingStripe: false,
  isStripeError: false,
  stripeError: '',
  isTermsAccepted: false,
  config: {
    ALLOW_BOOKING_IN_DAYS: 0
  }
}

export const reducer = (state = initialState, action) => {
  const {
    type,
    services,
    servicesAvailability,
    address,
    geoLocation,
    zoom,
    date,
    timeSlot,
    homeAddress,
    carModel,
    carRegistration,
    carColor,
    notes,
    agentTip,
    largeCar,
    sameDay,
    congestionZone,
    apiServices,
    user,
    orders,
    message,
    messageTimeout,
    messagePosition,
    messageColor,
    promoCode,
    promoCodeInfo,
    credit,
    isCardComplete,
    isProcessingStripe,
    isStripeError,
    stripeError,
    isTermsAccepted,
    config
  } = action

  switch (type) {
    case TYPES.SET_SERVICES:
      return { ...state, services, servicesAvailability }
    case TYPES.SET_ADDRESS:
      return { ...state, address }
    case TYPES.SET_GEO_LOCATION:
      return { ...state, geoLocation }
    case TYPES.SET_MAP_ZOOM:
      return { ...state, zoom }
    case TYPES.SET_DATE:
      return { ...state, date }
    case TYPES.SET_TIME_SLOT:
      return { ...state, timeSlot }
    case TYPES.SET_HOME_ADDRESS:
      return { ...state, homeAddress }
    case TYPES.SET_CAR_MODEL:
      return { ...state, carModel }
    case TYPES.SET_CAR_REGISTRATION:
      return { ...state, carRegistration }
    case TYPES.SET_CAR_COLOR:
      return { ...state, carColor }
    case TYPES.SET_NOTES:
      return { ...state, notes }
    case TYPES.SET_AGENT_TIP:
      return { ...state, agentTip }
    case TYPES.SET_LARGE_CAR:
      return { ...state, services: { ...state.services, [LARGE_CAR_SERVICE.id]: largeCar } }
    case TYPES.SET_ANYTIME_TODAY:
      return { ...state, services: { ...state.services, [SAME_DAY_SERVICE.id]: sameDay } }
    case TYPES.SET_CONGESTION_ZONE:
      return { ...state, services: { ...state.services, [CONGESTION_ZONE_SERVICE.id]: congestionZone } }
    case TYPES.SET_SERVICE_PRICES: {
      const ids = apiServices.map(service => service.id)
      const byName = apiServices.reduce((acc, service) => ({ ...acc, [camelCase(service.name)]: service }), {})
      const byId = apiServices.reduce((acc, service) => ({ ...acc, [service.id]: service }), {})
      const byOrder = [...apiServices]

      return { ...state, prices: { ids, byId, byName, byOrder } }
    }
    case TYPES.TOGGLE_SIGN_IN: {
      return { ...state, isSignIn: !state.isSignIn }
    }
    case TYPES.SET_USER:
      return { ...state, user }
    case TYPES.SIGN_OUT:
      return {
        ...state,
        user: null,
        orders: [],
        address: '',
        homeAddress: '',
        geoLocation: { lat: 51.5073509, lng: -0.1277583 },
        zoom: 9,
        date: null,
        timeSlot: '',
        carModel: '',
        carRegistration: '',
        carColor: '',
        notes: ''
      }
    case TYPES.SET_ORDERS:
      return { ...state, orders }
    case TYPES.SET_MESSAGE:
      return { ...state, message, messageTimeout, messagePosition, messageColor }
    case TYPES.SET_PROMO_CODE:
      return { ...state, promoCode }
    case TYPES.API_PROMO_CODES_INFO_SUCCESS:
      return { ...state, promoCodeInfo }
    case TYPES.API_PROMO_CODES_APPLY_SUCCESS:
      const currencyCredit = GBP(credit)

      return { ...state, user: { ...state.user, credit: currencyCredit.format(), credit_cents: currencyCredit.intValue } }
    case TYPES.RESET_BOOKING_FORM:
      const firstBaseServiceId = state.prices.ids.find(serviceId => state.prices.byId[serviceId].group === 'base')
      const firstBaseService = state.prices.byId[firstBaseServiceId]
      const largeCarServiceId = state.prices.ids.find(serviceId => state.prices.byId[serviceId].name === 'Extra for large car')
      const deselectedServices = {
        ...state.prices.ids.reduce((acc, serviceId) => ({ ...acc, [serviceId]: false }), {}),
        [firstBaseServiceId]: true,
        [largeCarServiceId]: null
      }
      const resetServicesAvailability = state.prices.ids.reduce(
        (acc, serviceId) => ({ ...acc, [serviceId]: !firstBaseService.includes.includes(serviceId) }),
        {}
      )

      return { ...state, ...initialBookingForm, services: deselectedServices, servicesAvailability: resetServicesAvailability, date: null, promoCode: null }
    case TYPES.SET_IS_CARD_COMPLETE:
      return { ...state, isCardComplete }
    case TYPES.SET_IS_PROCESSING_STRIPE:
      return { ...state, isProcessingStripe }
    case TYPES.SET_IS_STRIPE_ERROR:
      return { ...state, isStripeError }
    case TYPES.SET_STRIPE_ERROR:
      return { ...state, stripeError }
    case TYPES.SET_IS_TERMS_ACCEPTED:
      return { ...state, isTermsAccepted }
    case TYPES.API_GET_CONFIG_SUCCESS:
      return { ...state, config }
    default:
      return state
  }
}
