import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Link, Typography } from '@material-ui/core'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useStripe } from '@stripe/react-stripe-js'

import { settings } from '../../../../store/constants'
import {
  context,
  useOrdersApi
} from '../../../../store'

import { styles } from './styles'

const stripePromise = loadStripe(settings.stripeApiKey)

const CheckoutProcessingComponent = React.memo(() => {
  const classes = makeStyles(styles)()

  const { handleProcessCheckout } = useOrdersApi()
  const stripe = useStripe()
  const { sessionId } = useParams();

  useEffect(() => {
    handleProcessCheckout(stripe, sessionId)
  }, [])

  return (
    <div className={classes.container}>
      <Typography className={classes.heading}>THANK YOU FOR YOUR ORDER!</Typography>
      <Divider />
      <Typography className={classes.text2}>ps. payment is only taken once the order is accepted.</Typography>
    </div>

  )
})

export const CheckoutSuccess = React.memo(() => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <CheckoutProcessingComponent/>
    </Elements>

  )
})
