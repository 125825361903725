import React from 'react'
import { NavLink } from 'react-router-dom'
import { FacebookLoginButton } from '../facebook-login-button'
import { GoogleLoginButton } from '../google-login-button'

import { image } from '../../../../lib/image'

const title = 'Please login before payment'

export const SignIn = React.memo(({ handleSubmit, handleEmailChange, handlePasswordChange, toggleSignIn }) => {
  return (
    <div className="signin-background">
      <div className="signin-container">
        <div className="signin-content">
          <NavLink to="/">
            <img width="180px" height="41px" src={image('./logo.png')} alt="Gowashmycar logo" />
          </NavLink>

          <h2 className="signin-title">{title}</h2>

          <form noValidate className="signin-form" onSubmit={handleSubmit}>
            <input className="signin-form-email" id="email" name="email" autoComplete="email" placeholder="Email address" onChange={handleEmailChange} />
            <input
              className="signin-form-password"
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              onChange={handlePasswordChange}
            />

            <input className="signin-form-submit" type="submit" value="Continue" />

            <div className="signin-form-horizontal-row">or</div>

            <GoogleLoginButton title="Continue with Google" />
            <FacebookLoginButton title="Continue with Facebook" />
          </form>

          <p className="signin-form-question">{`Don't have an account?`}</p>
          <div onClick={toggleSignIn} className="signin-form-create">
            <span>Create account</span>
          </div>
          <p className="signin-form-question">Forgot your password?</p>
          <NavLink to="/user/restore_password" className="signin-form-create">
            <span>Restore password</span>
          </NavLink>
        </div>
      </div>
    </div>
  )
})
