import React, { useCallback, useState } from 'react'
import { Avatar, Button, Container, TextField, Typography } from '@material-ui/core'
import { DialpadRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { usePromoCodesApi } from '../../../../store/hooks/use-promo-codes-api'

import { styles } from './styles'

export const ApplyPromoCode = React.memo(() => {
  const classes = makeStyles(styles)()
  const [promoCode, setPromoCode] = useState('')
  const { apiApplyPromoCode } = usePromoCodesApi()

  const handlePromoCode = useCallback(
    event => {
      event.preventDefault()
      apiApplyPromoCode(promoCode)
    },
    [promoCode, apiApplyPromoCode]
  )

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Avatar className={classes.avatar}>
        <DialpadRounded />
      </Avatar>
      <Typography component="h1" variant="h5">
        Enter Promo Code
      </Typography>
      <form className={classes.form} noValidate onSubmit={handlePromoCode}>
        <TextField
          value={promoCode}
          onChange={event => setPromoCode(event.target.value)}
          variant="outlined"
          margin="normal"
          autoComplete="off"
          required
          fullWidth
          id="promo_code"
          label="Promo code"
          name="promo_code"
          autoFocus
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Apply Promo Code
        </Button>
      </form>
    </Container>
  )
})
