import { colors } from '@material-ui/core'

export const styles = theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    display: 'inline-flex',
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(1)
  },
  menuIcon: {
    color: '#6c6c6c'
  },
  menuItem: {
    '&:hover': {
      textShadow: '0 0 8px #ccc'
    },
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: 1,
    color: '#6c6c6c',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    transition: 'text-shadow 0.3s'
  },
  volvo: {
    display: 'none'
  }
})
