import React, { useContext, useCallback, useMemo } from 'react'
import { addDays, isBefore, format, isToday, getHours } from 'date-fns'
import { InputAdornment, makeStyles, Typography } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardArrowDownRounded } from '@material-ui/icons'
import { Controller } from 'react-hook-form'

import { context, setAnytimeToday, setTimeSlot, useSelectors } from '../../store'
import { fields, slots } from '../../store/constants'
import { styles } from './styles'

export const BookDate = ({ form, required }) => {
  const classes = makeStyles(styles)()
  const { dispatch } = useContext(context)

  const { date, sameDay, config } = useSelectors()

  const isAnytimeTodayDisabled = (date && !isToday(date)) || getHours(Date.now()) > 13
  const daysToAddFromConfig = config?.ALLOW_BOOKING_IN_DAYS || 0
  const daysToAddForAnytimeToday = isAnytimeTodayDisabled ? 1 : 0
  const minDate = useMemo(() => addDays(new Date(), daysToAddFromConfig || daysToAddForAnytimeToday), [daysToAddForAnytimeToday, daysToAddFromConfig])

  // const placeholder = useMemo(() => format(addDays(new Date(), 1), 'dd/MM/yyyy'), [])

  return (
    <Controller
      name={fields.date}
      control={form.control}
      rules={{ required: required && 'Date is required' }}
      render={({ field, fieldState: { error } }) => (
        <>
          <Typography className={classes.title}>{required ? 'Date *' : 'Date'}</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              {...field}
              autoOk
              okLabel={null}
              onChange={newDate => {
                const now = new Date()

                if (isToday(newDate)) {
                  dispatch(setAnytimeToday(true))
                  dispatch(setTimeSlot(slots.today))
                  field.onChange(newDate)
                } else {
                  const validDate = isBefore(newDate, now) ? addDays(now, 1) : newDate

                  field.onChange(validDate)
                  if (sameDay) {
                    dispatch(setAnytimeToday(false))
                    dispatch(setTimeSlot(null))
                  }
                }
              }}
              format="dd/MM/yyyy"
              animateYearScrolling
              disablePast
              minDate={minDate}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{
                name: 'date',
                placeholder: 'Select a date',
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardArrowDownRounded className="book-now-control-icon" />
                  </InputAdornment>
                ),
                disableUnderline: true
              }}
            />
          </MuiPickersUtilsProvider>
        </>
      )}
    />
  )
}
