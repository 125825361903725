export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  textFieldContainer: {
    width: '100%',
    padding: theme.spacing(1)
  },
  textField: {
    // margin: theme.spacing(1)
  },
  locationMapContainer: {
    width: '100%',
    padding: '20px 0 0 0',
    position: 'relative',
    '@media (min-width: 960px)': {
      padding: '30px 0 0 0'
    }
  },
  containerElement: {
    height: '260px',
    borderRadius: '5px',
    overflow: 'hidden',
    '@media (min-width: 300px)': {
      height: '290px'
    },
    '@media (min-width: 960px)': {
      height: '320px'
    }
  },
  mapElement: {
    height: '100%'
  },
  marker: {
    position: 'absolute',
    top: 'calc(50% - 53px)',
    left: 'calc(50% - 15px)',
    zIndex: 100
  },
  navButtons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(2)
  },
  navButton: {
    margin: theme.spacing(1)
  },
  loadingElement: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  }
})
