import { useCallback, useContext } from 'react'

import {
  setPromoCode,
  apiPromoCodeInfoStart,
  apiPromoCodeInfoSuccess,
  apiPromoCodeInfoFailure,
  apiPromoCodeApplyStart,
  apiPromoCodeApplySuccess,
  apiPromoCodeApplyFailure
} from '../actions/promocodes'
import { useSelectors } from './selectors/use-selectors'
import { setMessage } from '../actions/settings'
import { context } from '../context'
import { settings } from '../constants'
import { GBP } from '../../lib/gbp'

export const usePromoCodesApi = () => {
  const { dispatch } = useContext(context)
  const { promoCodeInfo } = useSelectors()

  const applyPromoCodeOnSignIn = useCallback(() => {
    if (!promoCodeInfo) return

    const { code } = promoCodeInfo

    if (!code) return

    apiApplyPromoCode(code)
  }, [promoCodeInfo, apiApplyPromoCode])

  const apiPromoCodeInfo = useCallback(
    code => {
      dispatch(apiPromoCodeInfoStart())
      fetch(`${settings.host}/api/v2/promo_codes/promo_code_info?code=${code}`)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status === 'error') {
            const message = json.message || 'Promo code not recognised'

            dispatch(apiPromoCodeInfoFailure(message))
            dispatch(setMessage(message, null, null, '#f44336'))

            return
          }

          if (status !== 'ok') throw new Error(JSON.stringify(json))

          const {
            data: { type, value }
          } = json

          dispatch(apiPromoCodeInfoSuccess({ code, type, value }))
          if (/^PercentPromoCode/.test(type)) {
            dispatch(setMessage(`Promo code applied. You will have ${value}% discount on this order.`, null, 'top', '#37b45a'))
          } else {
            dispatch(setMessage(`Promo code applied. You will have ${GBP(value).format()} discount on this order.`, null, 'top', '#37b45a'))
          }
        })
        .catch(error => {
          const message = `apiPromoCodeInfo error: ${error.message}`

          dispatch(apiPromoCodeInfoFailure(message))
          dispatch(setMessage(message, null, null, '#f44336'))
        })
    },
    [dispatch]
  )

  const apiApplyPromoCode = useCallback(
    code => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Token': window.localStorage.getItem('token')
        },
        body: JSON.stringify({ code })
      }

      dispatch(apiPromoCodeApplyStart())
      fetch(`${settings.host}/api/v2/promo_codes`, options)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status === 'error') {
            const message = json.message || 'Promo code not recognised'

            dispatch(apiPromoCodeApplyFailure(message))
            dispatch(setMessage(message, null, null, '#f44336'))

            return
          }

          if (status !== 'ok') throw new Error(JSON.stringify(json))

          const {
            data: { credit, percent }
          } = json

          if (percent) {
            const { type, value } = percent

            dispatch(setPromoCode({ code, type, value }))
            dispatch(apiPromoCodeApplySuccess(credit))
            !promoCodeInfo && dispatch(setMessage(`Promo code applied. You will have ${value}% discount on this order.`, null, 'top', '#37b45a'))
          } else {
            dispatch(apiPromoCodeApplySuccess(credit))
            !promoCodeInfo && dispatch(setMessage(`Promo code applied. You will have ${GBP(credit).format()} discount on this order.`, null, 'top', '#37b45a'))
          }
          dispatch(apiPromoCodeInfoSuccess(null))
        })
        .catch(error => {
          const message = `apiApplyPromoCode error: ${error.message}`

          dispatch(apiPromoCodeApplyFailure(message))
          dispatch(setMessage(message, null, null, '#f44336'))
        })
    },
    [dispatch, promoCodeInfo]
  )

  return {
    applyPromoCodeOnSignIn,
    apiPromoCodeInfo,
    apiApplyPromoCode
  }
}
