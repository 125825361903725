import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Paper } from '@material-ui/core'

import { browserHistory } from '../../../services/history'
import { useSelectors } from '../../../store/hooks'

import { ApplyPromoCode } from '../components/apply-promo-code'

export const PromoCodePage = React.memo(() => {
  const { isUserSignedIn } = useSelectors()

  useEffect(() => {
    !isUserSignedIn && browserHistory.push('/user/sign_in')
  }, [])

  return (
    <Paper>
      <Helmet title="Apply Promo Code" />
      <ApplyPromoCode />
    </Paper>
  )
})
