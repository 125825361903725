import React from 'react'

const popularExtras = [
  { name: 'Screenwash refill, oil & fluid check', price: '£10' },
  { name: 'Interior windows cleaned', price: '£12' },
  { name: 'Basic body wax', price: '£20' },
  { name: 'Bird droppings removal', price: '£30' },
  { name: 'Tree Sap Removal', price: '£49' },
  { name: 'Sanitisation', price: '£40' },
  { name: 'Alloy wheels sealant - 4 wheels', price: '£40' },
  { name: 'HD Carnauba wax coating', price: '£59' },
  { name: 'Soft roof deep clean & reproofing', price: '£189' },
  { name: 'Tyre Safety Check', price: '£25' },
  { name: 'Windscreen exterior deep clean and glass sealant', price: '£35' }
]

const specialistExtra = [
  { name: 'Deep clean 1x seat', price: '£20' },
  { name: 'Deep clean 2x seat', price: '£40' },
  { name: 'Deep Clean 1x Child Car Seat', price: '£35' },
  { name: 'Deep Clean 2x Child Car Seat', price: '£70' },
  { name: 'Single Push Chair Deep Cleaning', price: '£65' },
  { name: 'Double Push Chair Deep Cleaning', price: '£85' },
  { name: 'Pet hair removal', price: '£30' },
  { name: 'Boot & bonnet gutters cleaned', price: '£20' },
  { name: 'Engine bay cleaned', price: '£49' },
  { name: 'Protective paint sealant', price: '£59' },
  { name: 'Odour removal', price: '£79' },
  { name: 'High pressure interior steam clean', price: '£75' },
  { name: 'Alloy wheel and tyre deep clean', price: '£95' }
]

// eslint-disable-next-line prettier/prettier
const detailingServices = [
  { name: 'Clay bar & machine polish', price: '£225' },
  { name: 'Paint protection', price: '£399' }
]

const ExtraCard = ({ name, items, showButton }) => {
  return (
    <div className="services-extra-card">
      <h2 className="services-extra-card-title">{name}</h2>
      <ul className="services-extra-card-content">
        {items.map((item, i) => (
          <li key={i} className="services-extra-card-item">
            <div className="services-extra-card-item-name">{item.name}</div>
            <div className="services-extra-card-item-price">{item.price}</div>
          </li>
        ))}
      </ul>
      {showButton && (
        <a
          className="services-extra-request-link"
          href="#"
          onClick={e => {
            e.preventDefault()
            window?.Intercom('show')
          }}>
          Detailing request?
        </a>
      )}
    </div>
  )
}

export const ServicesExtra = () => (
  <section className="services-extra">
    <div className="services-extra-inner">
      <ExtraCard name="Popular extras" items={popularExtras} />
      <ExtraCard name="Specialist extras" items={specialistExtra} />
      <ExtraCard name="Detailing services" items={detailingServices} showButton />
    </div>
  </section>
)
