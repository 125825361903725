import { browserHistory } from '../services/history'

export class IntercomService {
  static _unsubscribe

  static start = () => {
    IntercomService._unsubscribe = browserHistory.listen(location => {
      const isFinishBookingRoute = /^\/booking\/finish/.test(location.pathname)
      const isBookingRoute = /^\/booking/.test(location.pathname)

      typeof Intercom !== 'undefined' && Intercom('update', { hide_default_launcher: isBookingRoute && !isFinishBookingRoute })
    })
  }

  static stop() {
    IntercomService._unsubscribe && IntercomService._unsubscribe()
  }
}
