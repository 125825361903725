import React, { memo } from 'react'
import { useEffect } from 'react'

import './styles.css'

const PLATFORM_URI = 'https://apps.elfsight.com/p/platform.js'
const widgetID = 'eebfc830-458c-4174-a402-5c576c40c1ef'

export const ElfsightReviews = memo(_ElfsightWidget)

function ElfsightWidgetRender() {
  useLoadPlatform()

  return <div className={`elfsight-app-${widgetID}`} style={{ width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}></div>
}

function _ElfsightWidget(props) {
  return props.disabled ? null : <ElfsightWidgetRender />
}

function useLoadPlatform() {
  useEffect(() => {
    if (!isPlatformLoaded()) {
      loadPlatform()
    }
  }, [])
}

function isPlatformLoaded() {
  return isPlatformInitialized() || hasPlatformScript()
}

function isPlatformInitialized() {
  return 'eapps' in window
}

function hasPlatformScript() {
  return !!document.querySelector(`script[src="${PLATFORM_URI}"]`)
}

function loadPlatform() {
  const scriptElement = document.createElement('script')
  scriptElement.src = PLATFORM_URI
  scriptElement.defer = true
  document.head.appendChild(scriptElement)
}
