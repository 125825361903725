import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'

import { styles } from './styles'

const IOSSwitchComponent = React.memo(({ classes, ...props }) => {
  const { focusVisible, root, switchBase, thumb, track, checked } = classes

  return <Switch focusVisibleClassName={focusVisible} disableRipple classes={{ root, switchBase, thumb, track, checked }} {...props} />
})

export const IOSSwitch = withStyles(styles)(IOSSwitchComponent)
