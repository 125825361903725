import { useCallback, useContext } from 'react'

import { apiGetConfigStart, apiGetConfigSuccess, apiGetConfigFailure } from '../actions/config'
import { context } from '../context'
import { settings } from '../constants'

export const useConfigApi = () => {
  const { dispatch } = useContext(context)

  const apiGetConfig = useCallback(
    code => {
      dispatch(apiGetConfigStart())
      fetch(`${settings.host}/api/v2/app_config`)
        .then(response => response.json())
        .then((json = {}) => {
          const { status } = json

          if (status === 'error') {
            dispatch(apiGetConfigFailure(json.message))

            return
          }

          if (status !== 'ok') throw new Error(JSON.stringify(json))

          dispatch(apiGetConfigSuccess(json.data))
        })
        .catch(error => {
          const message = `apiGetConfig error: ${error.message}`

          dispatch(apiGetConfigFailure(message))
        })
    },
    [dispatch]
  )

  return {
    apiGetConfig
  }
}
