import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Hidden } from '@material-ui/core'

import { browserHistory } from '../../services/history'
import { image } from '../../lib/image'

import { BookNow } from '../../components/book-now'
import { BookWash } from '../../components/book-wash'
import { StepAddress } from '../booking/components/step-address'

import { HowItWorks } from './how-it-works'
import { ElfsightReviews } from './elfsight-reviews'
import { HappyCarsSlider } from './happy-cars-slider'
import { Bossman } from './bossman'
import { PostCode } from './post-code'

import '../../assets/styles/home.css'

const MobileH1 = () => (
  <h1 className="description-h1">
    A car wash
    <br />
    Delivered to your door
  </h1>
)

const DesktopH1 = () => {
  if (browserHistory.location.pathname === '/') return <h1 className="description-h1">A car wash. Delivered to your door</h1>

  const meta = document.querySelector("meta[name='gwmc:header']")

  return meta ? <h1 className="description-h1">{meta.getAttribute('content')}</h1> : <h1 className="description-h1">A car wash. Delivered to your door</h1>
}

export const HomePage = React.memo(() => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="home">
      {browserHistory.location.pathname === '/' && <Helmet title="Book a mobile car wash anywhere in London with GoWashMyCar" />}
      <section className="description-hero">
        <div className="description-space"></div>
        <div className="description">
          <Hidden mdDown>
            <DesktopH1 />
          </Hidden>
          <Hidden lgUp>
            <MobileH1 />
          </Hidden>
          <Hidden mdDown>
            <h2 className="description-h2">Book a car wash with the UK&apos;s largest car wash specialist</h2>
          </Hidden>
          <Hidden lgUp>
            <h2 className="description-h2">The UK&apos;s largest car wash specialists</h2>
          </Hidden>
          <BookNow />
          <div className="description-facebook">
            <div className="description-facebook-stars">
              <div className="description-star-rate">
                <a href="https://www.facebook.com/gowashmycar?ref=hl" target="_blank" rel="noopener noreferrer">
                  4.6
                  <img className="description-star-img" src={image('./star.svg')} alt="star icon" />
                </a>
              </div>
            </div>
            <div className="description-facebook-link">Facebook reviews</div>
            <div className="description-facebook-text">4.6 out of 5 recommend us (50+ reviews)</div>
          </div>
        </div>
        <div className="description-insurance">insurance providers</div>
      </section>
      <HowItWorks />
      <section id="book-steps-section" className="book-steps-first-step">
        <div className="book-steps-content first-booking-step-content">
          <h1 className="book-steps-title">Book a mobile car wash or valet</h1>
          <div className="book-steps-inner book-steps-inner-first-step">
            <StepAddress />
          </div>
          <div className="book-steps-bottom-triangle-first-step">
            <svg preserveAspectRatio="none" viewBox="0 0 100 100">
              <polygon points="100,100 100,0 0,100" opacity="1"></polygon>
            </svg>
          </div>
        </div>
        <div className="book-steps-bottom-space"></div>
      </section>
      <ElfsightReviews />
      <HappyCarsSlider />
      <PostCode />
      <Bossman />
      <BookWash />
    </div>
  )
})
